import React, { ChangeEvent, FC, useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import SlideSelector from "./SlideSelector";
import ActionBar from "./ActionBar";
import FleetConfig from "./fleetConfig";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import PreferenceSelector from "./PreferenceSelector";
import { NavigateNext } from "@material-ui/icons";
import { createSaveConfigPayload, getDefaultSlideSelection, PPTConfig } from "./utils";
import Api, { PptConfigDeck, SlidePayload } from "../../services/Api";
import { Preference } from "./config";
import System from "../../common/System";
import { SnackbarSeverity } from "../../enums/SnackbarSeverity";

interface BcsPptConfigProps {
  downloadingPpt?: boolean;
  selectedPreference: string;
  preferences: Preference[];
  pptConfig: PPTConfig;
  setPptConfig: (pptConfig: PPTConfig) => void;
  onPreferenceChange: (event: ChangeEvent<{ value: unknown }>) => void;
  onCancel: () => void;
  onNext: (bcs: {[key: string]: boolean | SlidePayload}) => void;
  onExport: (operation: string, bcs: {[key: string]: boolean | SlidePayload}) => void;
}

const BcsPptConfig: FC<BcsPptConfigProps> = ({
  downloadingPpt,
  selectedPreference,
  preferences,
  pptConfig,
  setPptConfig,
  onPreferenceChange,
  onCancel,
  onNext,
  onExport,
}) => {
  const [decks, setDecks] = useState<PptConfigDeck[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const lastSavedSlides = useRef<string[]>([]);
  const [selectedSlides, setSelectedSlides] = useState<string[]>([]);
  const [selectionChanged, setSelectionChanged] = useState(false);
  const [fleetConfigModalOpen, setFleetConfigModalOpen] = useState(false);
  const [savingSelection, setSavingSelection] = useState(false);
  const {
    user: { type: userType },
  } = useSelector((state: StoreState) => state);

  useEffect(() => {
    setLoading(true);
    Api.pptConfig()
      .getBcsDecks()
      .then((decks) => {
        setDecks(decks);
        lastSavedSlides.current = getDefaultSlideSelection(decks);
        setSelectedSlides(lastSavedSlides.current);
      })
      .catch((err) => {
        console.error("Error occured while fetching BCS decks", err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleConfigureFleetClick = () => {
    setFleetConfigModalOpen(true);
  };

  const handleModalClose = () => {
    setFleetConfigModalOpen(false);
  };

  const handleReset = () => {
    setSelectedSlides(lastSavedSlides.current);
    setSelectionChanged(false);
  };

  const handleSaveAndExport = () => {
    const payload = createSaveConfigPayload(decks, selectedSlides);
    setSavingSelection(true);

    onExport("SAVE_AND_EXPORT", payload.pptConfig);
    setSavingSelection(false);
  };

  const handleSaveAndNext = () => {
    const payload = createSaveConfigPayload(decks, selectedSlides);
    Api.pptConfig()
      .postPptConfig(payload)
      .then(() => {
        System.openSnackbar("Configuration Saved Successfully", SnackbarSeverity.SUCCESS);
        lastSavedSlides.current = selectedSlides;
        setSelectionChanged(false);
      })
      .catch((err) => {
        console.error("Error Occured While Saving Configuration", err);
        System.openSnackbar(
          "Error Occured While Saving Configuration. You can still export the PPT with last saved selection.",
          SnackbarSeverity.ERROR
        );
      })
      .finally(() => {
        setSavingSelection(false);
        onNext(payload.pptConfig);
      });
  }

  const handleNext = () => {
    lastSavedSlides.current = selectedSlides;
    const payload = createSaveConfigPayload(decks, selectedSlides);
    onNext(payload.pptConfig);
  };

  const handleExport = () => {
    lastSavedSlides.current = selectedSlides;
    const payload = createSaveConfigPayload(decks, selectedSlides);
    onExport("EXPORT", payload.pptConfig);
  };

  const handleSlideSelect = (selectedSlides: string[]) => {
    setSelectedSlides(selectedSlides);
    setSelectionChanged(true);
  };

  const submitButtonProps: ButtonProps = {
    disabled: savingSelection,
    variant: selectionChanged ? "outlined" : "contained",
    color: "primary",
  };

  return (
    <>
      <Dialog open={fleetConfigModalOpen} onClose={handleModalClose} scroll="paper" maxWidth="md" fullWidth>
        <DialogContent>
          <FleetConfig onClose={handleModalClose} />
        </DialogContent>
      </Dialog>
      <Container maxWidth="xl" disableGutters>
        {loading ? (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        ) : error ? (
          <Typography variant="h5" color="error">
            Some error occured.
          </Typography>
        ) : (
          <SlideSelector decks={decks} selectedSlides={selectedSlides} onSlideSelect={handleSlideSelect} />
        )}
        <ActionBar>
          <PreferenceSelector preferences={preferences} value={selectedPreference} onChange={onPreferenceChange} />
          <Button variant="text" color="primary" onClick={handleConfigureFleetClick}>
            Configure Fleet
          </Button>
          <Box component="span" flexGrow={1} visibility="hidden" />
          <Button onClick={onCancel} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleReset} variant="outlined" color="primary">
            Reset
          </Button>
          {userType === "BCS" ? (<Button
            onClick={handleSaveAndExport}
            endIcon={savingSelection ? <CircularProgress size={20} /> : void 0}
            disabled={savingSelection}
            variant={selectionChanged ? "contained" : "outlined"}
            color="primary"
          >
            Save & Export
          </Button>) : (<Button
            onClick={handleSaveAndNext}
            endIcon={savingSelection ? <CircularProgress size={20} /> : void 0}
            disabled={savingSelection}
            variant={selectionChanged ? "contained" : "outlined"}
            color="primary"
          >
            Save & Next
          </Button>)}
          {userType === "ALL" ? (
            <Button onClick={handleNext} endIcon={<NavigateNext />} {...submitButtonProps}>
              Next
            </Button>
          ) : (
            <Button
              onClick={handleExport}
              endIcon={downloadingPpt ? <CircularProgress size={20} /> : void 0}
              {...submitButtonProps}
              disabled={downloadingPpt || submitButtonProps.disabled}
            >
              Export PPT
            </Button>
          )}
        </ActionBar>
      </Container>
    </>
  );
};

export default BcsPptConfig;
