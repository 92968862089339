import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { ChevronLeftRounded, ChevronRightRounded } from "@material-ui/icons";
import React, { useState, useRef, FC, useEffect } from "react";
import CarouselCard, { CarouselItem } from "./Card";

interface CarouselProps {
  title?: string;
  items: CarouselItem[];
  selectedItems?: string[];
  onSelect?: (selectedItems: string[]) => void;
}

const Carousel: FC<CarouselProps> = ({ title, items, selectedItems = [], onSelect }) => {
  const [ui, resetUI] = useState(false);
  const slideRefs = useRef<HTMLDivElement>(null);

  useEffect(() => {
    resetUI((ui) => !ui);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideRefs.current]);

  const showLeft = () => !!slideRefs.current?.scrollLeft;
  const showRight = () => {
    const { scrollLeft = 0, scrollWidth = 0, offsetWidth = 0 } = slideRefs.current || {};

    return Math.abs(scrollLeft - (scrollWidth - offsetWidth)) > 5;
  };

  return (
    <Box position="relative" mb={2} className="additional-slides">
      {title && (
        <Typography
          style={{
            marginLeft: "1rem",
          }}
          variant="h6"
          align="left"
        >
          {title}
        </Typography>
      )}
      <Divider
        style={{
          marginBottom: 8,
        }}
      />
      {showLeft() ? (
        <IconButton
          className="scroll-left"
          size="small"
          onClick={() => {
            if (!!slideRefs.current) {
              const left = slideRefs.current.scrollLeft;
              const width = slideRefs.current.offsetWidth;
              const offset = left - width;
              slideRefs.current.scrollTo({
                left: offset,
                behavior: "smooth",
              });
            }
          }}
        >
          <ChevronLeftRounded color="inherit" />
        </IconButton>
      ) : null}
      {showRight() ? (
        <IconButton
          className="scroll-right"
          size="small"
          onClick={() => {
            if (!!slideRefs.current) {
              const left = slideRefs.current.scrollLeft;
              const width = slideRefs.current.offsetWidth;
              const offset = left + width;
              slideRefs.current.scrollTo({
                left: offset,
                behavior: "smooth",
              });
            }
          }}
        >
          <ChevronRightRounded color="inherit" />
        </IconButton>
      ) : null}
      <Grid
        container
        wrap="nowrap"
        style={{
          overflowX: "hidden",
          paddingBlock: 8,
          gap: "1rem",
          paddingInline: "1rem",
        }}
        ref={slideRefs}
        id={`slide-id-${title}`}
        onScroll={() => resetUI(!ui)}
      >
        {items.map((item) => (
          <CarouselCard
            key={item.id}
            {...item}
            isSelected={selectedItems.includes(item.id)}
            onSelect={
              onSelect &&
              ((isSelected) =>
                isSelected
                  ? onSelect(selectedItems.filter((id) => id !== item.id))
                  : onSelect([...selectedItems, item.id]))
            }
          />
        ))}
        {!items.length ? (
          <Typography
            align="center"
            color="secondary"
            style={{
              padding: 4,
              marginLeft: 3,
            }}
          >
            No Slides for this category
          </Typography>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Carousel;
