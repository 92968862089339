import React from 'react'
import { Visualization } from '@shared/interfaces'
import { Alert } from '@material-ui/lab'

const AppBanner = ({
  visualization
}: {
  visualization: Visualization
}) => {
  const { representation } = visualization
  const { bannerDetails } = representation
  const message = bannerDetails?.message
  const variant = bannerDetails?.variant || "warning"
  if(message){
    return (
      <Alert severity={variant}>{message}</Alert>
    )
  }else{
    return (
      <div>No message provided</div>
    )
  }
  
}

export default AppBanner
