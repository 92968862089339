import React from "react";
import { Visualization } from "@shared/interfaces";
import { Box, Grid } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";

export default ({ visualization }: { visualization: Visualization }) => {

  const getLegend = () => {
    switch(visualization.name){
      case "FLEET DETAIL": 
        return <Box className="fleet-legend">
        <Grid container spacing={2}>
          <Grid item xs={"auto"}>
            <TrendingDownIcon />
            Decrease {"(<-10%)"}
          </Grid>
          <Grid item xs={"auto"}>
            <TrendingFlatIcon /> Flat {"(-10% to 10%)"}
          </Grid>
          <Grid item xs={"auto"}>
            <TrendingUpIcon /> Increase {"(>10%)"}
          </Grid>
          <Grid item xs={"auto"}>
            <FiberManualRecordIcon className="dot-icon green" />
            {"<70%"}
          </Grid>
          <Grid item xs={"auto"}>
            <FiberManualRecordIcon className="dot-icon red" />
            {">70%"}
          </Grid>
          <Grid item xs={"auto"} style={{ marginTop: "4px" }}>
            <span>{"Data protection assessment"}</span>
          </Grid>
          <Grid item xs={"auto"} className="hexagon-legend-icon">
            <span className="MuiSvgIcon-root" style={{ color: "#ff3300" }}>&#x2B22;</span>
          </Grid>          
          <Grid item xs={"auto"} style={{ marginTop: "4px" }}>
            <span>{"Caution"}</span>
          </Grid>
          <Grid item xs={"auto"} className="hexagon-legend-icon">
            <span className="MuiSvgIcon-root" style={{ color: "#ffc800" }}>&#x2B22;</span>
          </Grid>                    
          <Grid item xs={"auto"} style={{ marginTop: "4px" }}>
            {"Optimizable"}
          </Grid>
        </Grid>
      </Box>
      case "Array Upgrade Detail":
        return <Box className="fleet-legend">
          <Grid container spacing={2}>
          <Grid item xs={"auto"}>
            <FiberManualRecordIcon className="dot-icon info" />
            {"<=12"}
          </Grid>
          <Grid item xs={"auto"}>
            <FiberManualRecordIcon className="dot-icon error" />
            {"<=6"}
          </Grid>
        </Grid>
      </Box>
    }
  }

  return (
    <>
      {getLegend()}
    </>
  )
}
