import "./FormatValue.scss";

import { Box } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import { ColumnFormat } from "@shared/interfaces";
import { DATE_FORMAT } from "../../common/Constants";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import React from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import Config from "../../config/Config";
import { mapLevelToColor } from "../../util/util";

const EMPTY_VALUE = "-";

export default ({
  columnFormat,
  value,
  decimalScale = 2,
  row,
}: {
  columnFormat: ColumnFormat | undefined;
  value: any;
  decimalScale?: number;
  row?: any;
}) => {
  return (() => {
    switch (columnFormat) {
      case ColumnFormat.PERCENTAGE:
        return (
          <NumberFormat
            value={value !== null ? value : 0}
            displayType={"text"}
            decimalScale={decimalScale}
            fixedDecimalScale={true}
            suffix={"%"}
          />
        );
      case ColumnFormat.DATE:
        return value === null ? (
          EMPTY_VALUE
        ) : (
          <Moment parse="YYYY-MM-DD" format={DATE_FORMAT}>
            {value}
          </Moment>
        );
      case ColumnFormat.BOOLEAN:
        if (value === undefined) {
          return "-";
        } else if (typeof value === "string") {
          if (value.toLowerCase() === "true") {
            return (
              <Box className="column-icon">
                <CheckIcon className="green" />
              </Box>
            );
          } else {
            return (
              <Box className="column-icon">
                <RemoveIcon />
              </Box>
            );
          }
        } else if (typeof value === "number") {
          if (value === 0) {
            return (
              <Box className="column-icon">
                <RemoveIcon />
              </Box>
            );
          } else {
            return (
              <Box className="column-icon">
                <CheckIcon className="green" />
              </Box>
            );
          }
        } else {
          return "x";
        }
      case ColumnFormat.NUMBER:
        if (value === null) {
          return EMPTY_VALUE;
        } else {
          return (
            <NumberFormat
              value={parseFloat(value)}
              displayType={"text"}
              decimalScale={decimalScale}
              thousandSeparator
            />
          );
        }
      case ColumnFormat.CURRENCY:
        return (
          <NumberFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={decimalScale}
            prefix={"$"}
          />
        );
      case ColumnFormat.LINK:
        if (value) {
          return (
            <a
              className="value-link"
              href={value}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon />
            </a>
          );
        } else {
          return EMPTY_VALUE;
        }
      case ColumnFormat.TEXT:
        return <>{value}</>;
      case ColumnFormat.HONEYCOMB:
        return value != null ? <span className="honeycomb-column" style={{ color: mapLevelToColor(value as number) }}>&#x2B22;</span> : EMPTY_VALUE;
      case ColumnFormat.ARRAY_LINK:
        if (!row["array_id"]) {
          return "array_id column not present in query";
        } else {
          return (
            <a
              className="value-link"
              href={`${Config.app.arrayLink}/${row["array_id"]}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {value}
            </a>
          );
        }
      default:
        return value || EMPTY_VALUE;
    }
  })();
};
