import "./FeedbackPage.scss";

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";

import { AccountSelectorTarget } from "../enums/AccountSelectorTarget";
import ActionsTableCell from "../components/feedback/ActionsTableCell";
import Api from "../services/Api";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import GetAppIcon from "@material-ui/icons/GetApp";
import Link from "@material-ui/core/Link";
import Main from "../components/Main";
import MaterialTable from "material-table";
import Moment from "react-moment";
import SelectedAccountsPopover from "../components/AccountSelector/SelectedAccountsPopover";
import { StoreState } from "../types/StoreState";
import System from "../common/System";
import { useSelector } from "react-redux";
import Utils from "../common/Utils";

export default () => {
  const tableRef = useRef<any>(null);
  const {
    lastFeedbacksRefresh,
    accountSelectionForFilters,
    accountSelectionSelectedHierarchyForFilters,
  } = useSelector((state: StoreState) => state);
  const [downloading, setdownloading] = useState<string>("");
  const [loadingFeedback, setLoadingFeedback] = useState(false);

  useEffect(() => {
    if (lastFeedbacksRefresh && null !== tableRef.current) {
      tableRef.current.onQueryChange();
    }
  }, [lastFeedbacksRefresh]);

  const downloadFile = async (feedbackId: number, fileName: string) => {
    setdownloading(feedbackId.toString());
    const { url } = await Api.uploads().downloadUrl({
      feedbackId,
      filename: fileName,
    });

    const link = document.createElement("a");
    link.setAttribute("download", "");
    link.setAttribute("hidden", "");
    link.setAttribute("target", "_blank");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    link.remove();
    setdownloading("");
  };

  const detailPanel = [
    (rowData: any) => ({
      disabled: rowData.text.length <= 50,
      render: (rowData: any) => {
        return (
          <div className="detail-panel">
            <p>{rowData.text}</p>
          </div>
        );
      },
    }),
  ];

  const handleFilterAccountClick = () => {
    if (!loadingFeedback) {
      System.updateShowAccountSelector(true, AccountSelectorTarget.FEEDBACK);
    }
  };

  const handleFilterAllAccountsClick = () => {
    if (!loadingFeedback) {
      System.updateAccountSelectionForFilters([]);
      System.updateLastFeedbacksRefresh();
    }
  };

  const renderAccount = (rowData: any) => {
    if (rowData.accounts.length === 1) {
      return rowData.accounts[0].name;
    } else if (rowData.accounts.length > 1) {
      return (
        <SelectedAccountsPopover
          accounts={rowData.accounts.map((account: any) => account.name)}
          maxAccountsToShow={2}
          showCount
          text={rowData.parentName || rowData.accounts[0].name}
        />
      );
    }
  };

  return (
    <Main noLeftNav>
      <div className="feedback-page">
        <Card elevation={1}>
          <CardContent>
            <MaterialTable
              tableRef={tableRef}
              detailPanel={detailPanel}
              components={{
                Container: (props) => (
                  <>
                    <Grid container style={{ padding: "20px" }}>
                      <Grid item md={4}>
                        <Typography
                          variant={"h6"}
                          style={{
                            fontWeight: "normal",
                            textAlign: "left",
                          }}
                        >
                          PVR History
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        md={8}
                        style={{
                          textAlign: "right",
                        }}
                      >
                        {accountSelectionForFilters.length !== 0 && (
                          <Link
                            href="#"
                            className={"clear-all"}
                            onClick={handleFilterAllAccountsClick}
                            style={{ opacity: loadingFeedback ? 0.3 : 1 }}
                          >
                            Clear all
                          </Link>
                        )}
                        <span
                          className="filter"
                          onClick={handleFilterAccountClick}
                          style={{ opacity: loadingFeedback ? 0.3 : 1 }}
                        >
                          Filter:{" "}
                          {accountSelectionForFilters.length === 0 ? (
                            "All"
                          ) : (
                            <>
                              {`${
                                accountSelectionSelectedHierarchyForFilters &&
                                Utils.hierarchyName(
                                  accountSelectionSelectedHierarchyForFilters
                                )
                              } `}
                              {accountSelectionForFilters && (
                                <span className="filter-count">
                                  <SelectedAccountsPopover
                                    showCount
                                    accounts={accountSelectionForFilters.map(
                                      (account) => account.name
                                    )}
                                  />
                                </span>
                              )}
                            </>
                          )}
                          <ArrowDropDownIcon
                            style={{
                              verticalAlign: "middle",
                            }}
                          />
                        </span>
                      </Grid>
                    </Grid>
                    <div {...props}></div>
                  </>
                ),
              }}
              columns={[
                {
                  title: "Date Published",
                  field: "createdAt",
                  render: (rowData) => (
                    <Moment format="MM/DD/YYYY">
                      {Number(rowData.createdAt)}
                    </Moment>
                  ),
                },

                { title: "Fiscal Quarter", field: "quarter" },
                { title: "Fiscal Year", field: "year" },
                {
                  title: "Hierarchy",
                  field: "hierarchy",
                  render: (rowData) => Utils.hierarchyName(rowData.hierarchy),
                },
                {
                  title: "Accounts",
                  field: "accounts",
                  render: renderAccount,
                },
                {
                  title: "Created By",
                  field: "createdBy",
                },
                {
                  title: "PVR file",
                  field: "fileName",
                  render: (rowData) =>
                    rowData.fileName && (
                      <>
                        {downloading !== rowData.id ? (
                          <Button
                            variant="text"
                            className={"pvr-download-button"}
                            // style={{
                            //   textDecoration: "underline",
                            //   cursor: "pointer",
                            //   fontSize: "12px",
                            //   fontWeight: 400,
                            // }}
                            onClick={() =>
                              downloadFile(Number(rowData.id), rowData.fileName)
                            }
                          >
                            <GetAppIcon className="download-icon" />
                            download
                          </Button>
                        ) : (
                          <div className="circular-loading">
                            <CircularProgress size={20} />
                          </div>
                        )}
                      </>
                    ),
                },
                {
                  title: "Feedback",
                  field: "text",
                  render: (rowData) =>
                    rowData.text.length > 50
                      ? rowData.text.substring(0, 50) + "..."
                      : rowData.text,
                },
                {
                  title: "",
                  field: "id",
                  render: (rowData) => {
                    return <ActionsTableCell feedback={rowData} />;
                  },
                },
              ]}
              data={(query) => {
                return new Promise((resolve) => {
                  setLoadingFeedback(true);
                  return Api.feedback()
                    .getFeedbacks(
                      query.page,
                      query.pageSize,
                      (query.orderBy && query.orderBy.field) || "createdAt",
                      query.orderDirection,
                      accountSelectionForFilters.map((account) => account.id),
                      accountSelectionSelectedHierarchyForFilters
                    )
                    .then((response) => {
                      resolve({
                        data: response,
                        page: query.page,
                        totalCount: response.length && response[0].total,
                      });
                    })
                    .finally(() => {
                      setLoadingFeedback(false);
                    });
                });
              }}
              options={{
                showTitle: false,
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                draggable: false,
              }}
            />
          </CardContent>
        </Card>
      </div>
    </Main>
  );
};
