import React, { useState } from 'react'
import { makeStyles, Slider } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { ColumnFilterType, ColumnFormat } from '@shared/interfaces';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles(() => ({
  valueLabel: {
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
})
);

const CustomFilterComponent = (props: any) => {
  const classes = useStyles()
  const { columnDef, columnFormat, tableData, filters: propFilters, updateFilters, filterType, minColumnName, maxColumnName, defaultMax, defaultMin, defaultScale } = props

  const formatDate = (date: Date) => {
    return DateFnsUtils.prototype.format(date, 'yyyy-MM-dd')
  }

  const initialRangeValue = filterType === ColumnFilterType.RANGE ? (propFilters && propFilters[columnDef.tableData.id]?.length === 2) ? propFilters[columnDef.tableData.id] : (minColumnName && maxColumnName) ? [Number(tableData[0][minColumnName]), Number(tableData[0][maxColumnName]) ] : [defaultMin, defaultMax] : [0, 100]
  const isUndefinedRange = initialRangeValue.length === 2 && initialRangeValue.every((value: any) => value !== value)
  const [rangeValue, setRangeValue] = useState<[number, number]>(isUndefinedRange ? [0, 100] : initialRangeValue)
  
  const initialDateRangeValue = (filterType === ColumnFilterType.DATE && propFilters && propFilters[columnDef.tableData.id]?.length === 2 && propFilters[columnDef.tableData.id].every((date: any) => DateFnsUtils.prototype.isValid(new Date(date)))) ? propFilters[columnDef.tableData.id] : [formatDate(new Date()), formatDate(new Date())]
  const [selectedDate, setSelectedDate] = React.useState(initialDateRangeValue);

  const handleDateChange = (date: any, index: number) => {
    const newSelectedDate = [...selectedDate]
    newSelectedDate[index] = formatDate(date)
    setSelectedDate(newSelectedDate);
    updateFilters({
      ...propFilters,
      [props.columnDef.tableData.id]: newSelectedDate
    })
  };
  const { field } = columnDef
  const filters = tableData.map((item: any) => ({
    title: item[field],
    value: item[field]
  }))

  const onAutocompleteChange = (_event: object, value: any | any[], _reason: string) => {
    updateFilters({
      ...propFilters,
      [props.columnDef.tableData.id]: value
    })
  }

  const nonEmptyFilters = filters.filter((option: any) => !!option.value)
  const uniqueFilters = nonEmptyFilters.reduce((unique: any, o: any) => {
    if(!unique.some((obj: any) => obj.value === o.value && obj.title === o.title)) {
      unique.push(o);
    }
    return unique;
  }, [])

  return (
    <div>
      {
       filterType === ColumnFilterType.STRING_SEARCH && <Autocomplete
          onChange={onAutocompleteChange}
          multiple
          options={uniqueFilters.map((option: any) => option.title)}
          defaultValue={(propFilters !== undefined && propFilters[props?.columnDef?.tableData?.id] !== undefined) ? propFilters[props?.columnDef?.tableData?.id] : []}
          freeSolo
          placeholder={field}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} variant="filled" label="" placeholder={field} />
          )}
        />
      }
      {
       filterType === ColumnFilterType.RANGE && <div style={{ marginLeft: 20, marginRight: 20, marginTop: 50}}>
          <Slider
            classes={{
              valueLabel: classes.valueLabel
            }}
            track={false}
            valueLabelDisplay="on"
            min={columnFormat === ColumnFormat.PERCENTAGE ? 0 : (tableData[0] !== undefined && minColumnName) ? Number(tableData[0][minColumnName]) : defaultMin}
            max={columnFormat === ColumnFormat.PERCENTAGE ? 100 : (tableData[0] !== undefined && maxColumnName) ? Number(tableData[0][maxColumnName]) : defaultMax}
            step={columnFormat === ColumnFormat.PERCENTAGE ? 1 : tableData[0] === undefined ? defaultScale : 100}
            value={rangeValue}
            onChange={(_event, newValue) => {
              setRangeValue(newValue as [number, number])
              updateFilters({
                ...propFilters,
                [props.columnDef.tableData.id]: newValue
              })
            }}
            aria-labelledby="range-slider"
          />
        </div>
      }
      {
       filterType === ColumnFilterType.DATE && 
       <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <KeyboardDatePicker 
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="start-date"
            label="Start Date"
            value={selectedDate[0]}
            onChange={(date: any) => handleDateChange(date, 0)}
         />
         <KeyboardDatePicker 
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="end-date"
            label="End Date"
            value={selectedDate[1]}
            onChange={(date: any) => handleDateChange(date, 1)}
         />
       </MuiPickersUtilsProvider>
      }
    </div>
  )
}

export default CustomFilterComponent
