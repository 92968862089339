import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress, Container, Typography } from "@material-ui/core";
import SlideSelector from "./SlideSelector";
import ActionBar from "./ActionBar";
// import PreferenceSelector from "./PreferenceSelector";
import { NavigateBefore } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import { createSaveConfigPayload, getDefaultSlideSelection, PPTConfig } from "./utils";
import Api, { PptConfigDeck, SlidePayload } from "../../services/Api";
import { Preference } from "./config";

interface PvrPptConfigProps {
  downloadingPpt?: boolean;
  selectedPreference: string;
  preferences: Preference[];
  pptConfig: PPTConfig;
  setPptConfig: (pptConfig: PPTConfig) => void;
  onPreferenceChange: (event: ChangeEvent<{ value: unknown }>) => void;
  onCancel: () => void;
  onPrev: () => void;
  onExport: (operation: string, bcs: {[key: string]: boolean | SlidePayload}) => void;
}

const PvrPptConfig: FC<PvrPptConfigProps> = ({ downloadingPpt, pptConfig, setPptConfig, onCancel, onPrev, onExport }) => {
  const [decks, setDecks] = useState<PptConfigDeck[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const lastSavedSlides = useRef<string[]>([]);
  const [selectionChanged, setSelectionChanged] = useState(false);
  const [selectedSlides, setSelectedSlides] = useState<string[]>([]);
  const [savingSelection, setSavingSelection] = useState(false);
  const {
    user: { type: userType },
  } = useSelector((state: StoreState) => state);

  useEffect(() => {
    setLoading(true);
    Api.pptConfig()
      .getPvrDecks()
      .then((decks) => {
        setDecks(decks);
        lastSavedSlides.current = getDefaultSlideSelection(decks);
        setSelectedSlides(lastSavedSlides.current);
      })
      .catch((err) => {
        console.error("Error occured while fetching PVR decks", err);
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSave = () => {
    const payload = createSaveConfigPayload(decks, selectedSlides);
    setSavingSelection(true);
    onExport("SAVE_AND_EXPORT", payload.pptConfig);
    setSavingSelection(false);
  };

  const handleReset = () => {
    setSelectedSlides(lastSavedSlides.current);
    setSelectionChanged(false);
  };

  const handleExport = () => {
    lastSavedSlides.current = selectedSlides;
    const payload = createSaveConfigPayload(decks, selectedSlides);
    onExport("EXPORT", payload.pptConfig);
  };

  const handleSlideSelect = (selectedSlides: string[]) => {
    setSelectedSlides(selectedSlides);
    setSelectionChanged(true);
  };

  return (
    <>
      {/* <Dialog open={fleetConfigModalOpen} onClose={handleModalClose} scroll="paper" maxWidth="md" fullWidth>
        <DialogContent>
          <FleetConfig onClose={handleModalClose} />
        </DialogContent>
      </Dialog> */}
      <Container maxWidth="xl" disableGutters>
        {loading ? (
          <CircularProgress
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          />
        ) : error ? (
          <Typography variant="h5" color="error">
            Some error occured.
          </Typography>
        ) : (
          <SlideSelector decks={decks} selectedSlides={selectedSlides} onSlideSelect={handleSlideSelect} />
        )}
        <ActionBar>
          {/* Todo: Fetch PVR preferences and show "Configure Fleet" button */}
          {/* <PreferenceSelector preferences={preferences} value={selectedPreference} onChange={onPreferenceChange} /> */}
          {/* <Button variant="text" color="primary" onClick={handleConfigureFleetClick}>
            Configure Fleet
          </Button> */}
          <Box component="span" flexGrow={1} visibility="hidden" />
          {userType === "ALL" ? (
            <Button onClick={onPrev} startIcon={<NavigateBefore />} variant="outlined" color="primary">
              Back
            </Button>
          ) : (
            <Button onClick={onCancel} variant="outlined" color="primary">
              Cancel
            </Button>
          )}
          <Button onClick={handleReset} variant="outlined" color="primary">
            Reset
          </Button>
          <Button
            onClick={handleSave}
            endIcon={savingSelection ? <CircularProgress size={20} /> : void 0}
            disabled={savingSelection}
            variant={selectionChanged ? "contained" : "outlined"}
            color="primary"
          >
            Save & Export PPT
          </Button>
          <Button
            onClick={handleExport}
            endIcon={downloadingPpt ? <CircularProgress size={20} /> : void 0}
            disabled={savingSelection || downloadingPpt}
            variant={selectionChanged ? "outlined" : "contained"}
            color="primary"
          >
            Export PPT
          </Button>
        </ActionBar>
      </Container>
    </>
  );
};

export default PvrPptConfig;
