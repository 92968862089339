import React, { FC, useState } from "react";
import { Button, CircularProgress, ListItemText, ListItemIcon, Tooltip, Typography, Divider } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import { StyledMenu, StyledMenuItem } from "../MainMenu";
import { useHistory } from "react-router-dom";
import { Paths } from "../../common/Paths";
import { Settings } from "@material-ui/icons";
import { exportPptRequest } from "./utils";
import { isNull } from "lodash";

const ExportPptMenu: FC = () => {
  const {
    accountSelection,
    selectedArraysEnvironment,
    rtcoParameters,
    // bcs: { preferences: bcsPreferences, loadingPreferences },
    // user: { type: userType },
  } = useSelector((state: StoreState) => state);
  const history = useHistory();
  const [downloadingPPT, setDownloadingPPT] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleExportPptButtonClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * Called when the "Configure PPT" button is clicked, it goes to PPT Configurator.
   */
  const handleConfigurePptButtonClick = () => {
    setAnchorEl(null);
    history.push(Paths.pptConfigurator);
  };

  /**
   * Called when the export menu item is closed after being clicked.
   */
  const handleExportPptMenuItemClose = () => {
    setAnchorEl(null);
  };

  /**
   * Called when a export ppt menu item is clicked.
   */
  const handleExportPPTMenuItemClick = (event: any, includeAll?: boolean, customPreferenceName?: string) => {
    setAnchorEl(null);
    setDownloadingPPT(true);

    const config = {
      "bcs": null,
      "pvr": null
    };
    const operation = "EXPORT";
    exportPptRequest({
      accountSelection,
      rtcoParameters,
      selectedArraysEnvironment,
      customPreferenceName,
      includeAll,
      config,
      operation,
      onComplete: () => {
        setDownloadingPPT(false);
      },
    });
  };

  return (
    <>
      <Button
        color="primary"
        aria-controls="export-ppt-menu"
        aria-haspopup="true"
        onClick={handleExportPptButtonClick}
        variant="outlined"
        className="ppt-button"
        disableElevation
        disableRipple
        disabled={downloadingPPT}
      >
        Export PPT
        {downloadingPPT ? (
          <CircularProgress className="button-icon" size={16} />
        ) : (
          <GetAppIcon className="button-icon" />
        )}
      </Button>
      <StyledMenu
        id="export-ppt-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleExportPptMenuItemClose}
      >
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit">
                All arrays of the selected customers will be included in the Fleet Details slide. Slides will be
                included as per the selection made in PPT Configurator.
              </Typography>
            </React.Fragment>
          }
          arrow={true}
        >
          <StyledMenuItem onClick={(e) => handleExportPPTMenuItemClick(e, true)}>
            <ListItemIcon>
              <GetAppIcon className="button-icon" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="All Arrays (Max: 500)" />
          </StyledMenuItem>
        </Tooltip>
        <Tooltip
          title={
            <React.Fragment>
              <Typography color="inherit">
                Only 5 arrays will be displayed in the Fleet Details slide. Slides will be included as per the selection
                made in PPT Configurator.
              </Typography>
            </React.Fragment>
          }
          arrow={true}
        >
          <StyledMenuItem onClick={handleExportPPTMenuItemClick}>
            <ListItemIcon>
              <GetAppIcon className="button-icon" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Default" />
          </StyledMenuItem>
        </Tooltip>
        <Divider
          style={{
            marginBottom: 0,
          }}
        />
        <Tooltip
          title={<Typography color="inherit">Configure the Fleets and Slides to include in the PPT</Typography>}
          arrow
        >
          <StyledMenuItem onClick={handleConfigurePptButtonClick}>
            <ListItemIcon>
              <Settings className="button-icon" fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Configure PPT" />
          </StyledMenuItem>
        </Tooltip>
        {/* {(userType === "ALL" || userType === "BCS") &&
          !!bcsPreferences.length && [
            <DividerWithText key="custom-preference-divider">Custom Preferences</DividerWithText>,
            bcsPreferences.map(({ name, selection }) => (
              <Tooltip
                key={`bcs-preference-${name}`}
                title={
                  <React.Fragment>
                    <Typography color="inherit">{`Contains ${selection.length} arrays`}</Typography>
                  </React.Fragment>
                }
                arrow={true}
              >
                <StyledMenuItem
                  onClick={(event) => {
                    handleExportPPTMenuItemClick(event, false, name);
                  }}
                >
                  <ListItemIcon>
                    <GetAppIcon className="button-icon" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={name} />
                </StyledMenuItem>
              </Tooltip>
            )),
          ]} */}
      </StyledMenu>
    </>
  );
};

export default ExportPptMenu;
