import React from 'react'
import { Visualization } from '@shared/interfaces';
import { ListItem } from '@material-ui/core';

const Assumptions = ({ visualization }: {visualization: Visualization}) => {
  const assumptions = visualization.data
  return (
    <>
      {
        assumptions && assumptions.map((assumption: any, index: number) => (
          <ListItem>{`${index+1}. ${assumption}`}</ListItem>
        ))
      }
    </>
  )
}

export default Assumptions;
