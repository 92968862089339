import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";
import System from "../common/System";

export default () => {
  const { alertDialog } = useSelector((state: StoreState) => state);

  const handleClose = () => {
    System.closeAlertDialog();
  };

  return (
    <Dialog open={alertDialog.open} onClose={handleClose} disableBackdropClick>
      <DialogTitle>{alertDialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{alertDialog.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
