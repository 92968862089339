import {
  closeSnackbar,
  openSnackbar,
  Store,
  updateAccountSelection,
  updateAccountSelectionForFilters,
  updateAccountSelectionHierarchyForFilters,
  updateAccountSelectorTarget,
  updateAlertDialog,
  updateAuthLoaded,
  updateCurrentDashboardName,
  updateExecutiveAccountSelection,
  updateHeaderDashboard,
  updateLastFeedbacksRefresh,
  updateLoadingDashboard,
  updateLoadingHierarchy,
  updateLoadingSearchResults,
  updateSelectedArraysEnvironment,
  updateShowAccountSelector,
  updateShowFeedbackForm,
  updateUser,
  updateAccountSelectionContext,
  updateRtcoParameters,
  updateVolumeSearchTerms,
  updateExecutiveAccounts,
  updateExecutiveAccountFullDataLoading,
} from "../common/Store";
import { AccountSelection } from "../types/AccountSelection";
import { SnackbarSeverity } from "../enums/SnackbarSeverity";
import AppStorage from "./AppStorage";
import { Account, AccountHierarchyType, ArrayType, Dashboard, Feedback, GenericAccount } from "@shared/interfaces";
import { AccountSelectorTarget } from "../enums/AccountSelectorTarget";

export default abstract class System {
  static openSnackbar(message: string, severity: SnackbarSeverity) {
    Store.dispatch(
      openSnackbar({
        message: message,
        severity: severity,
      })
    );
  }

  static closeSnackbar() {
    Store.dispatch(closeSnackbar());
  }

  static updateAccountSelectionContext(context: string) {
    Store.dispatch(updateAccountSelectionContext(context));
  }

  static updateAuthLoaded(value: boolean) {
    Store.dispatch(updateAuthLoaded(value));
  }

  static updateUser(user: any) {
    Store.dispatch(updateUser(user));
  }

  static updateLoadingSearchResults(value: boolean) {
    Store.dispatch(updateLoadingSearchResults(value));
  }

  static updateShowAccountSelector(value: boolean, target?: AccountSelectorTarget) {
    if (target) {
      Store.dispatch(updateAccountSelectorTarget(target));
    } else {
      Store.dispatch(updateAccountSelectorTarget(AccountSelectorTarget.APP));
    }
    Store.dispatch(updateShowAccountSelector(value));
  }

  static updateAccountSelectionForFilters(accounts: GenericAccount[], hierarchy?: AccountHierarchyType) {
    Store.dispatch(updateAccountSelectionForFilters({ accounts, hierarchy }));
  }

  static updateAccountSelectionHierarchyForFilters(accounts: GenericAccount[]) {
    Store.dispatch(updateAccountSelectionHierarchyForFilters(accounts));
  }

  static updateCurrentDashboardName(dashboardName: string) {
    Store.dispatch(updateCurrentDashboardName(dashboardName));
  }

  static updateExecutiveAccountSelection(theaters: any) {
    Store.dispatch(updateExecutiveAccountSelection(theaters));
  }

  static updateRtcoParameters(rtcoParams: any) {
    Store.dispatch(updateRtcoParameters(rtcoParams));
  }

  static updateLoadingHierarchy(value: boolean) {
    Store.dispatch(updateLoadingHierarchy(value));
  }

  static updateLoadingDashboard(value: boolean) {
    Store.dispatch(updateLoadingDashboard(value));
  }

  static updateAccountSelection(accountSelection: AccountSelection) {
    Store.dispatch(updateAccountSelection(accountSelection));
    AppStorage.saveAccountSelection(accountSelection);
  }

  static openFeedbackForm(feedback?: Feedback) {
    Store.dispatch(updateShowFeedbackForm({ open: true, feedback }));
  }

  static closeFeedbackForm() {
    Store.dispatch(updateShowFeedbackForm({ open: false, feedback: null }));
  }

  static showAlertDialog(title: string, message: string) {
    Store.dispatch(updateAlertDialog({ title, message, open: true }));
  }

  static closeAlertDialog() {
    Store.dispatch(updateAlertDialog({ open: false }));
  }

  static updateSelectedArraysEnvironment(value: ArrayType) {
    Store.dispatch(updateSelectedArraysEnvironment(value));
  }

  static updateHeaderDashboard(dashboard?: Dashboard) {
    Store.dispatch(updateHeaderDashboard(dashboard));
  }

  static updateLastFeedbacksRefresh() {
    Store.dispatch(updateLastFeedbacksRefresh(Date.now()));
  }

  static updateVolumeSearchTerms(searchTerms?: string[]) {
    Store.dispatch(updateVolumeSearchTerms(searchTerms));
  }

  static updateExecutiveAccounts(accounts?: Account[]) {
    Store.dispatch(updateExecutiveAccounts(accounts));
  }

  static updateExecutiveAccountFullDataLoading(loading: boolean) {
    Store.dispatch(updateExecutiveAccountFullDataLoading(loading));
  }
}
