import './Table.scss'

import { CircularProgress } from '@material-ui/core'
import {
  Column,
  DataLoadingStrategy,
  Orientation,
  Visualization,
} from '@shared/interfaces'
import React, { useEffect, useState } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

import Api from '../../services/Api'
import CustomFormatter from './CustomFormatter'
import FormatValue from './FormatValue'
import NoData from '../NoData'
import { StoreState } from '../../types/StoreState'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Utils from '../../common/Utils'
import { useSelector } from 'react-redux'
import FleetDetailLabel from './FleetDetailLabel'
import Config from '../../config/Config'
import VisibilitySensor from 'react-visibility-sensor'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
)

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

type Order = 'asc' | 'desc'

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key,
): ((
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: Order
  orderBy: string
  rowCount: number
  columns: Column[]
  showName: boolean
  showColumnTitles: boolean
  cssClass?: string
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    columns,
    showColumnTitles,
    showName,
    cssClass,
  } = props
  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>,
  ) => {
    onRequestSort(event, property)
  }
  const enableSort = cssClass === 'table-03' || cssClass === 'table-04'

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, i) =>
          enableSort ? (
            <TableCell
              key={column.name}
              sortDirection={orderBy === column.name ? order : false}
              className={!showName && showColumnTitles ? 'border' : ''}
            >
              <TableSortLabel
                active={orderBy === column.name}
                direction={orderBy === column.name ? order : 'asc'}
                onClick={createSortHandler(column.name)}
              >
                {column.label}
                {orderBy === column.name ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ) : (
            <TableCell
              className={!showName && showColumnTitles ? 'border' : ''}
              key={i}
            >
              {column.label || column.name}
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  )
}

export default ({
  visualization,
  dashboardId,
}: {
  visualization: Visualization
  dashboardId: string
}) => {
  const isSearchStatsVisualization = visualization.name === 'Search Stats'
  const { volumeSearchTerms } = useSelector((state: StoreState) => state)
  const hideTable = isSearchStatsVisualization && volumeSearchTerms.length === 0
  const classes = useStyles()
  const [loading, setLoading] = useState(true)
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<string>('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const showName = Utils.showName(visualization)
  const columns = visualization.representation.columns || []
  const showColumnTitles = Utils.showColumnTitles(visualization)
  const [data, setData] = useState<any[]>([])
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
    executiveAccountSelection,
  } = useSelector((state: StoreState) => state)
  const [retries, setRetries] = useState(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (volumeSearchTerms.length !== 0) {
      let mounted = true
      if (retries < Config.app.maxVisualizationRetries) {
        setLoading(true)
        if (executiveAccountSelection) {
          Api.executiveDashboards()
            .loadMultipleVisualizations(
              dashboardId,
              visualization.id,
              volumeSearchTerms.map((term) => ({
                searchTerm: term,
                ...executiveAccountSelection,
              })),
            )
            .then((response) => {
              if (mounted && response) {
                if (!Utils.showPagination(visualization)) {
                  setRowsPerPage((response && response.length) || 0)
                }

                const newResponse =
                  response.length &&
                  response
                    .map((array: any) => array[0])
                    .filter((row: any) => !!row)
                setData(newResponse || [])
              }
            })
            .catch((error) => {
              if (mounted && error.response.status !== 404) {
                setTimeout(() => {
                  setRetries(retries + 1)
                }, Config.app.maxVisualizationRetriesInterval)
              }
            })
            .finally(() => {
              if (mounted) {
                setLoading(false)
              }
            })
        }
      } else {
        setData(visualization.data || [])
        setLoading(false)
      }
    }
  }, [volumeSearchTerms])

  useEffect(() => {
    let mounted = true
    setLoading(true)
    if (
      visualization.loadingStrategy === DataLoadingStrategy.LAZY &&
      (accountSelection.parentAccountId ||
        accountSelection.accounts.length > 0 ||
        executiveAccountSelection) &&
      visible
    ) {
      if (retries < Config.app.maxVisualizationRetries) {
        setLoading(true)
        if (executiveAccountSelection) {
          Api.executiveDashboards()
            .loadVisualization(
              dashboardId,
              visualization.id,
              executiveAccountSelection,
            )
            .catch((error) => {
              if (mounted && error.response.status !== 404) {
                setTimeout(() => {
                  setRetries(retries + 1)
                }, Config.app.maxVisualizationRetriesInterval)
              }
            })
            .then((response) => {
              if (mounted && response) {
                if (!Utils.showPagination(visualization)) {
                  setRowsPerPage((response && response.length) || 0)
                }
                setData(response)
              }
            })
            .finally(() => {
              if (mounted) {
                setLoading(false)
              }
            })
        } else {
          Api.visualizations()
            .byId(
              dashboardId,
              visualization.id,
              {
                parentAccountId: accountSelection.parentAccountId,
                selectedAccountIds: accountSelection.accounts.map(
                  (account) => account.id,
                ),
                hierarchyType: accountSelection.hierarchyType,
                arrayType: selectedArraysEnvironment,
              },
              {
                dashboardName: currentDashboardName,
                visualizationName: visualization.name,
              },
            )
            .catch((error) => {
              if (mounted && error.response.status !== 404) {
                setTimeout(() => {
                  setRetries(retries + 1)
                }, Config.app.maxVisualizationRetriesInterval)
              }
            })
            .then((response) => {
              if (mounted && response) {
                if (!Utils.showPagination(visualization)) {
                  setRowsPerPage((response && response.length) || 0)
                }
                setData(response)
              }
            })
            .finally(() => {
              if (mounted) {
                setLoading(false)
              }
            })
        }
      }
    } else {
      setData(visualization.data || [])
      setLoading(false)
    }
    return () => {
      mounted = false
    }
  }, [
    accountSelection,
    executiveAccountSelection,
    visualization,
    dashboardId,
    selectedArraysEnvironment,
    currentDashboardName,
    retries,
    visible,
  ])

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyData = () => !loading && (!data || data.length === 0)

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(isVisible)
  }

  return !hideTable ? (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      active={!isSearchStatsVisualization}
    >
      <div className={`paged-table ${emptyData() ? 'no-data' : ''}`}>
        {loading && <CircularProgress size={20} />}
        {!loading && data && data.length > 0 && (
          <>
            <FleetDetailLabel visualization={visualization} />
            <TableContainer
              className={visualization.representation.formatting?.cssClass}
            >
              <Table stickyHeader>
                {showColumnTitles && (
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={data.length}
                    columns={columns}
                    showColumnTitles={showColumnTitles}
                    showName={showName}
                    cssClass={visualization.representation.formatting?.cssClass}
                  />
                )}
                <TableBody>
                  {visualization.representation.orientation === Orientation.ROW
                    ? stableSort(data, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row: any) =>
                          columns.map((column) => (
                            <TableRow key={column.name}>
                              <TableCell>
                                {column.label || column.name}
                              </TableCell>
                              <TableCell>
                                <CustomFormatter
                                  visualization={visualization}
                                  row={row}
                                  column={column.name}
                                >
                                  <FormatValue
                                    columnFormat={column.format}
                                    decimalScale={column.decimalScale}
                                    value={row[column.name]}
                                    row={row}
                                  />
                                </CustomFormatter>
                              </TableCell>
                            </TableRow>
                          )),
                        )
                    : stableSort(data, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row: any, index: number) => (
                          <TableRow key={index}>
                            {columns.map((column, j) => (
                              <TableCell key={j}>
                                <CustomFormatter
                                  visualization={visualization}
                                  row={row}
                                  column={column.name}
                                >
                                  <FormatValue
                                    columnFormat={column.format}
                                    decimalScale={column.decimalScale}
                                    value={row[column.name]}
                                    row={row}
                                  />
                                </CustomFormatter>
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                </TableBody>
              </Table>
            </TableContainer>
            {Utils.showPagination(visualization) && (
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[5, 25, 50]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </>
        )}
        {emptyData() && <NoData />}
      </div>
    </VisibilitySensor>
  ) : null
}
