import React from "react";
import { Input, MenuItem, Select } from "@material-ui/core";
import { ArrayType } from "@shared/interfaces";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";
import System from "../common/System";
import "./ArrayEnvironmentSelect.scss";

export default () => {
  const { selectedArraysEnvironment } = useSelector(
    (state: StoreState) => state
  );

  const handleChange = (event: any) => {
    System.updateSelectedArraysEnvironment(event.target.value);
  };

  return (
    <div className="array-environment-select">
      <span>Stage:</span>
      <Select
        MenuProps={{ variant: "menu" }}
        value={selectedArraysEnvironment}
        onChange={handleChange}
        variant="outlined"
        placeholder="Environment"
        input={<Input placeholder={"hostname"} className={"select-input"} />}
      >
        {Object.values(ArrayType).map((environment) => (
          <MenuItem key={environment} value={environment}>
            {environment}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
