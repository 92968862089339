import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import { Fleet } from "../config";
import { ColumnFilter } from ".";

interface FilterComponentProps {
  onClose: () => void;
  onSubmit: (filters?: ColumnFilter) => void;
  data: Fleet[];
  activeFilter: keyof Fleet;
  filters?: ColumnFilter;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
  onClose,
  onSubmit,
  data,
  filters: initialFilters,
  activeFilter: field,
}) => {
  const [filters, setFilters] = useState(initialFilters);

  const onAutocompleteChange = (_event: object, value: any | any[], _reason: string) => {
    setFilters({
      ...filters,
      [field]: value,
    });
  };

  const _filters = data.map((item: any) => ({
    title: item[field],
    value: item[field],
  }));
  const nonEmptyFilters = _filters.filter((option: any) => !!option.value);
  const uniqueFilters = nonEmptyFilters.reduce((unique: any, o: any) => {
    if (!unique.some((obj: any) => obj.value === o.value && obj.title === o.title)) {
      unique.push(o);
    }
    return unique;
  }, []);

  return (
    <Dialog PaperProps={{ style: { width: "65%" } }} open={true} onClose={onClose} aria-labelledby="column-filters">
      <DialogTitle id={`${field}-column-filters`}>Column Filters</DialogTitle>
      <DialogContent>
        <DialogContentText>The filters below change the table data by {field}.</DialogContentText>
        <Autocomplete
          onChange={onAutocompleteChange}
          multiple
          options={uniqueFilters.map((option: any) => option.title)}
          defaultValue={(field && filters && filters[field]) || []}
          freeSolo
          placeholder={field}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params) => <TextField {...params} variant="filled" label="" placeholder={field} />}
        />
      </DialogContent>
      <DialogContent>
        <DialogContentText>Hit enter key after entering the search term and then click apply.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(filters)} color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterComponent;
