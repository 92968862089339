import { createTheme, makeStyles } from "@material-ui/core";

const drawerWidth = 210;

export const customTheme = createTheme({
  palette: {
    primary: {
      main: "#3E075B",
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  divider: {
    marginBottom: "20px",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1100,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
  },
  contentFull: {
    flexGrow: 1,
    margin: "10px auto",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    float: "left",
    margin: 0,
  },
  loadingButtonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
