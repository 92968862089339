const pako = require("pako");

type TableData = {
  id?: string;
  checked?: boolean;
};
interface Account {
  AccountOwner: string;
  accountId: string;
  accountName: string;
  partnerAccountName: string | null;
  partnerAccountId: string | null;
  division: string;
  subDivision: string;
  theater: string;
  tableData?: TableData;
}

interface FilteredAccount {
  accountId: string;
  accountName: string;
  tableData?: TableData;
}

interface Theater {
  theater: string;
  tableData?: TableData;
}

interface Division {
  division: string;
  tableData?: TableData;
}

interface SubDivision {
  subDivision: string;
  tableData?: TableData;
}

interface Partner {
  partnerAccountName: string | null;
  partnerAccountId: string | null;
  tableData?: TableData;
}

export enum PreferencesType {
  THEATERS_ONLY = "THEATERS_ONLY",
  THEATERS_AND_DIVISIONS = "THEATERS_AND_DIVISIONS",
  THEATERS_DIVISIONS_AND_SUBDIVISIONS = "THEATERS_DIVISIONS_AND_SUBDIVISIONS",
  THEATERS_DIVISIONS_SUBDIVISIONS_AND_ACCOUNTS = "THEATERS_DIVISIONS_SUBDIVISIONS_AND_ACCOUNTS",
  THEATERS_DIVISIONS_SUBDIVISIONS_ACCOUNTS_AND_OWNERS = "THEATERS_DIVISIONS_SUBDIVISIONS_ACCOUNTS_AND_OWNERS",
  ACCOUNTS_ONLY = "ACCOUNTS_ONLY",
  ACCOUNTS_WITH_OWNERS_OR_PARTNERS = "ACCOUNTS_WITH_OWNERS_OR_PARTNERS",
  OWNERS_ONLY = "OWNERS_ONLY",
  PARTNERS_ONLY = "PARTNERS_ONLY",
  ALL = "ALL",
  EMPTY = "EMPTY",
}

export const getPreferenceType = (preferences: any): PreferencesType => {
  if (
    "theaters" in preferences &&
    "divisions" in preferences &&
    "subDivisions" in preferences &&
    "accounts" in preferences &&
    "accountOwners" in preferences &&
    "partners" in preferences
  ) {
    return PreferencesType.ALL;
  }
  if (
    "theaters" in preferences &&
    "divisions" in preferences &&
    "subDivisions" in preferences &&
    "accounts" in preferences &&
    "accountOwners" in preferences
  ) {
    return PreferencesType.THEATERS_DIVISIONS_SUBDIVISIONS_ACCOUNTS_AND_OWNERS;
  }
  if (
    "theaters" in preferences &&
    "divisions" in preferences &&
    "subDivisions" in preferences &&
    "accounts" in preferences
  ) {
    return PreferencesType.THEATERS_DIVISIONS_SUBDIVISIONS_AND_ACCOUNTS;
  }
  if ("accounts" in preferences && "accountOwners" in preferences && "partners" in preferences) {
    return PreferencesType.ACCOUNTS_WITH_OWNERS_OR_PARTNERS;
  }
  if ("theaters" in preferences && "divisions" in preferences && "subDivisions" in preferences) {
    return PreferencesType.THEATERS_DIVISIONS_AND_SUBDIVISIONS;
  }
  if ("theaters" in preferences && "divisions" in preferences) {
    return PreferencesType.THEATERS_AND_DIVISIONS;
  }
  if ("theaters" in preferences) {
    return PreferencesType.THEATERS_ONLY;
  }
  if ("accounts" in preferences) {
    return PreferencesType.ACCOUNTS_ONLY;
  }
  if ("accountOwners" in preferences) {
    return PreferencesType.OWNERS_ONLY;
  }
  if ("partners" in preferences) {
    return PreferencesType.PARTNERS_ONLY;
  }
  return PreferencesType.EMPTY;
};

export interface DataMaps {
  theaterMap: { [key: string]: any };
  divisionMap: { [key: string]: any };
  subDivisionMap: { [key: string]: any };
  accountMap: { [key: string]: any };
  partnerMap: { [key: string]: any };
  ownerMap: { [key: string]: any };
}

export const convertBase64ToJson = (base64Data: string) => {
  const asciiData = atob(base64Data);
  const charData = asciiData.split("").map((x) => x.charCodeAt(0));
  const binData = new Uint8Array(charData);
  const data = pako.inflate(binData, { to: "string" });
  const decodedData = JSON.parse(data);
  try {
    if (decodedData && decodedData.length > 0) {
      // const jsonParsedData = JSON.parse(decodedData[0].jsonData);
      // return jsonParsedData;
      return decodedData;
    }
  } catch (err) {
    console.error(err);
    throw new Error("Some Error Occured!");
  }
  return null;
};

export const normalizeExecutiveData = (data: Account[]) => {
  console.time("normalize");
  let theaterMap: any = {};
  let divisionMap: any = {};
  let subDivisionMap: any = {};
  let partnerMap: any = {};
  let ownerMap: any = {};
  let accountMap: any = {};
  data.forEach((account) => {
    const {
      theater,
      division,
      subDivision,
      accountId,
      accountName,
      partnerAccountId,
      partnerAccountName,
      AccountOwner,
    } = account;
    if (theater) {
      if (!(theater in theaterMap)) {
        theaterMap[theater] = {
          name: theater,
          id: theater,
          divisions: [division],
          accounts: [account],
        };
      } else {
        if (division) {
          theaterMap[theater] = {
            ...theaterMap[theater],
            divisions: [...new Set([...theaterMap[theater].divisions, division])],
          };
          if (accountId) {
            theaterMap[theater].accounts.push(account);
          }
        }
      }
    }

    if (division) {
      if (!(division in divisionMap)) {
        divisionMap[division] = {
          name: division,
          id: division,
          subDivisions: [subDivision],
          accounts: [account],
          theater,
        };
      } else {
        if (subDivision) {
          divisionMap[division] = {
            ...divisionMap[division],
            subDivisions: [...new Set([...divisionMap[division].subDivisions, subDivision])],
          };
          if (accountId) {
            divisionMap[division].accounts.push(account);
          }
        }
      }
    }

    if (subDivision) {
      if (!(subDivision in subDivisionMap)) {
        subDivisionMap[subDivision] = {
          name: subDivision,
          id: subDivision,
          accounts: [account],
          division,
        };
      } else {
        subDivisionMap[subDivision] = {
          ...subDivisionMap[subDivision],
          accounts: [...new Set([...subDivisionMap[subDivision].accounts, account])],
        };
      }
    }

    if (accountName) {
      if (!(accountName in accountMap)) {
        accountMap[accountName] = {
          name: accountName,
          id: accountId,
          subDivisions: [subDivision],
          account: account,
        };
      } else {
        accountMap[accountName] = {
          ...accountMap[accountName],
          subDivisions: [...accountMap[accountName].subDivisions, subDivision],
          account: account,
        };
      }
    }

    if (AccountOwner) {
      if (!(AccountOwner in ownerMap)) {
        ownerMap[AccountOwner] = {
          name: AccountOwner,
          accounts: [account],
        };
      } else {
        ownerMap[AccountOwner] = {
          ...ownerMap[AccountOwner],
          accounts: [...ownerMap[AccountOwner].accounts, account],
        };
      }
    }

    if (partnerAccountName) {
      if (!(partnerAccountName in partnerMap)) {
        partnerMap[partnerAccountName] = {
          name: partnerAccountName,
          id: partnerAccountId,
          accounts: [account],
        };
      } else {
        partnerMap[partnerAccountName] = {
          ...partnerMap[partnerAccountName],
          accounts: [...partnerMap[partnerAccountName].accounts, account],
        };
      }
    }
  });

  // adding partners to accounts
  data.forEach((account) => {
    const { partnerAccountId, partnerAccountName, accountName, accountId } = account;
    if (partnerAccountId && partnerAccountName) {
      if (accountMap[accountName]?.partners?.length > 0) {
        accountMap[accountName].partners = [...accountMap[accountName].partners, partnerAccountName];
      } else {
        accountMap[accountName].partners = [partnerAccountName];
      }
    }
  });
  console.timeEnd("normalize");
  const dataMaps = {
    theaterMap,
    divisionMap,
    subDivisionMap,
    accountMap,
    partnerMap,
    ownerMap,
  };
  return dataMaps;
};

export const makeTheatersOnlyRequestObject = (selectedTheaters: any, dataMaps: any) => {
  const { theaterMap, divisionMap } = dataMaps;
  const request = selectedTheaters.map((theater: Theater) => {
    const divisionsOfTheater = theaterMap[theater.theater].divisions;
    return {
      name: theater.theater,
      divisions: divisionsOfTheater.map((division: string) => {
        const subDivisionsOfDivision = divisionMap[division].subDivisions;
        return {
          name: division,
          subDivisions: subDivisionsOfDivision.map((subDivision: string) => {
            return {
              name: subDivision,
              accounts: [],
            };
          }),
        };
      }),
    };
  });
  return request;
};

export const makeDivisionsOnlyRequestObject = (selectedDivisions: any, dataMaps: any) => {
  const { theaterMap, divisionMap } = dataMaps;
  const selectedTheaters: string[] = [];
  selectedDivisions.forEach((division: Division) => {
    selectedTheaters.push(divisionMap[division.division].theater);
  });
  const request = [...new Set(selectedTheaters)].map((theater) => {
    const selectedDivisionsOfTheater = selectedDivisions
      .filter((division: Division) => theaterMap[theater].divisions.includes(division.division))
      .map((division: Division) => division.division);
    return {
      name: theater,
      divisions: selectedDivisionsOfTheater.map((division: string) => {
        const subDivisionsOfDivision = divisionMap[division].subDivisions;
        return {
          name: division,
          subDivisions: subDivisionsOfDivision.map((subDivision: string) => {
            return {
              name: subDivision,
              accounts: [],
            };
          }),
        };
      }),
    };
  });
  return request;
};

export const makeSubdivisionsOnlyRequestObject = (selectedSubDivisions: any, dataMaps: any) => {
  const { theaterMap, divisionMap, subDivisionMap } = dataMaps;
  const selectedDivisions: string[] = [];
  selectedSubDivisions.forEach((subDivision: SubDivision) => {
    if (subDivision.subDivision) {
      selectedDivisions.push(subDivisionMap[subDivision.subDivision].division);
    }
  });
  const selectedTheaters: string[] = [];
  const uniqueSelectedDivisions = [...new Set(selectedDivisions)];
  uniqueSelectedDivisions.forEach((division: string) => {
    selectedTheaters.push(divisionMap[division].theater);
  });

  const request = [...new Set(selectedTheaters)].map((theater) => {
    const selectedDivisionsOfTheater = uniqueSelectedDivisions.filter((division: string) =>
      theaterMap[theater].divisions.includes(division)
    );
    return {
      name: theater,
      divisions: selectedDivisionsOfTheater.map((division: string) => {
        const subDivisionsOfDivision = divisionMap[division].subDivisions;
        const selectedSubDivisionNames = selectedSubDivisions.map(
          (subDivision: SubDivision) => subDivision.subDivision
        );
        const selectedSubDivisionsOfDivision = subDivisionsOfDivision.filter((subDivision: string) =>
          selectedSubDivisionNames.includes(subDivision)
        );
        return {
          name: division,
          subDivisions: selectedSubDivisionsOfDivision.map((subDivision: string) => {
            return {
              name: subDivision,
              accounts: [],
            };
          }),
        };
      }),
    };
  });
  return request;
};

export const makeAccountOwnersOnlyRequestObject = (
  selectedSubDivisions: any,
  selectedAccounts: any,
  selectedAccountOwners: any,
  dataMaps: DataMaps
) => {
  const relevantAccounts: string[] = [];
  selectedAccountOwners.forEach((owner: Account) => {
    const selectedRelevantAccounts = selectedAccounts.filter(
      (account: Account) => account.AccountOwner === owner.AccountOwner
    );
    relevantAccounts.push(...selectedRelevantAccounts);
  });
  const request = makeAccountsOnlyRequestObject(selectedSubDivisions, relevantAccounts, dataMaps);
  return request;
};

export const makeAccountsOnlyRequestObject = (selectedSubDivisions: any, selectedAccounts: any, dataMaps: DataMaps) => {
  const { theaterMap, divisionMap, subDivisionMap, accountMap } = dataMaps;
  // each account can be part of multiple subdivisions
  // but only one or few or all of subdivisions can be selected
  // account selection can make subdivision selection irrelevant
  const relevantSubDivisions: string[] = [];
  selectedAccounts.forEach((account: Account) => {
    relevantSubDivisions.push(...accountMap[account.accountName].subDivisions);
  });
  // this will provide the subdivisions of only the relevant accounts
  const uniqueRelevantSubDivisions = [...new Set(relevantSubDivisions)];
  const selectedSubDivisionNames = selectedSubDivisions.map((subDivision: SubDivision) => subDivision.subDivision);
  const selectedAndRelevantSubDivisions = uniqueRelevantSubDivisions.filter((subDivision: string) =>
    selectedSubDivisionNames.includes(subDivision)
  );

  const selectedDivisions: string[] = [];
  selectedAndRelevantSubDivisions.forEach((subDivision: string) => {
    selectedDivisions.push(subDivisionMap[subDivision].division);
  });
  const uniqueSelectedDivisions = [...new Set(selectedDivisions)];

  const selectedTheaters: string[] = [];
  uniqueSelectedDivisions.forEach((division: string) => {
    selectedTheaters.push(divisionMap[division].theater);
  });

  const request = [...new Set(selectedTheaters)].map((theater) => {
    const selectedDivisionsOfTheater = uniqueSelectedDivisions.filter((division: string) =>
      theaterMap[theater].divisions.includes(division)
    );
    return {
      name: theater,
      divisions: selectedDivisionsOfTheater.map((division: string) => {
        // const subDivisionsOfDivision = divisionMap[division].subDivisions
        const selectedSubDivisionsOfDivision = selectedAndRelevantSubDivisions.filter((subDivision: string) =>
          selectedSubDivisionNames.includes(subDivision)
        );
        return {
          name: division,
          subDivisions: selectedSubDivisionsOfDivision.map((subDivision: string) => {
            return {
              name: subDivision,
              accounts: selectedAccounts
                .filter((account: Account) => account.subDivision === subDivision)
                .map((account: Account) => ({ id: account.accountId })),
            };
          }),
        };
      }),
    };
  });
  return request;
};

export const makeStandAloneAccountOwnersObject = (selectedAccountOwners: any, dataMaps: DataMaps) => {
  const { ownerMap } = dataMaps;
  const relevantAccounts: Account[] = [];
  if ("accountOwner" in selectedAccountOwners[0]) {
    selectedAccountOwners.forEach((owner: Account) => {
      relevantAccounts.push(...ownerMap[owner.AccountOwner]?.accounts);
    });
    const request = makeStandAloneAccountsObject(relevantAccounts, [], dataMaps);
    return request;
  }
};

export const makeStandAloneAccountsObject = (selectedAccounts: any, selectedPartners: any, dataMaps: DataMaps) => {
  const { accountMap, subDivisionMap, divisionMap, partnerMap, theaterMap } = dataMaps;
  if (selectedPartners.length) {
    const relevantAccounts: string[] = [];
    selectedPartners.forEach((partner: Account) => {
      if (partner.partnerAccountName) {
        const partnerAccounts = partnerMap[partner.partnerAccountName]?.accounts?.map(
          (account: Account) => account.accountName
        );
        const selectedAccountNames = selectedAccounts.map((account: Account) => account.accountName);
        relevantAccounts.push(...selectedAccountNames.filter((account: Account) => partnerAccounts.includes(account)));
      }
    });

    const relevantTheaters: string[] = [];
    const relevantDivisions: string[] = [];
    const relevantSubDivisions: string[] = [];
    relevantAccounts.forEach((account: string) => {
      relevantSubDivisions.push(accountMap[account].subDivisions[0]);
    });
    relevantSubDivisions.forEach((subDivision: string) => {
      relevantDivisions.push(subDivisionMap[subDivision].division);
    });
    relevantDivisions.forEach((division: string) => {
      relevantTheaters.push(divisionMap[division].theater);
    });
    const request = [...new Set(relevantTheaters)].map((theater) => {
      const selectedDivisionsOfTheater = [...new Set(relevantDivisions)].filter((division: string) =>
        theaterMap[theater].divisions.includes(division)
      );
      return {
        name: theater,
        divisions: selectedDivisionsOfTheater.map((division: string) => {
          const selectedSubDivisionsOfDivision = [...new Set(relevantSubDivisions)].filter((subDivision: string) =>
            divisionMap[division].subDivisions.includes(subDivision)
          );
          return {
            name: division,
            subDivisions: [...new Set(selectedSubDivisionsOfDivision)].map((subDivision: string) => {
              return {
                name: subDivision,
                accounts: selectedAccounts
                  .filter((account: Account) => account.subDivision === subDivision)
                  .map((account: Account) => ({ id: account.accountId })),
              };
            }),
          };
        }),
      };
    });
    return request;
    //some partners are selected
  } else {
    // no partners are selected, it is just only accounts
    const relevantTheaters: string[] = [];
    const relevantDivisions: string[] = [];
    const relevantSubDivisions: string[] = [];
    selectedAccounts.forEach((account: Account) => {
      relevantSubDivisions.push(accountMap[account.accountName].subDivisions[0]);
    });
    relevantSubDivisions
      .filter((subDivision) => !!subDivision)
      .forEach((subDivision: string) => {
        relevantDivisions.push(subDivisionMap[subDivision].division);
      });
    relevantDivisions.forEach((division: string) => {
      relevantTheaters.push(divisionMap[division].theater);
    });
    const request = [...new Set(relevantTheaters)].map((theater) => {
      const selectedDivisionsOfTheater = [...new Set(relevantDivisions)].filter((division: string) =>
        theaterMap[theater].divisions.includes(division)
      );
      return {
        name: theater,
        divisions: selectedDivisionsOfTheater.map((division: string) => {
          const selectedSubDivisionsOfDivision = [...new Set(relevantSubDivisions)].filter((subDivision: string) =>
            divisionMap[division].subDivisions.includes(subDivision)
          );
          return {
            name: division,
            subDivisions: selectedSubDivisionsOfDivision.map((subDivision: string) => {
              return {
                name: subDivision,
                accounts: selectedAccounts
                  .filter((account: Account) => account.subDivision === subDivision)
                  .map((account: Account) => ({ id: account.accountId })),
              };
            }),
          };
        }),
      };
    });
    return request;
  }
};

export const makeStandAlonePartnersObject = (selectedPartners: any, dataMaps: DataMaps) => {
  const { accountMap, divisionMap, subDivisionMap, partnerMap, theaterMap } = dataMaps;
  const relevantAccounts: string[] = [];
  selectedPartners.forEach((partner: Account) => {
    if (partner.partnerAccountName) {
      const partnerAccounts = partnerMap[partner.partnerAccountName]?.accounts?.map(
        (account: Account) => account.accountName
      );
      relevantAccounts.push(...partnerAccounts);
    }
  });

  const relevantTheaters: string[] = [];
  const relevantDivisions: string[] = [];
  const relevantSubDivisions: string[] = [];
  relevantAccounts.forEach((account: string) => {
    if (accountMap[account].subDivisions[0]) {
      relevantSubDivisions.push(accountMap[account].subDivisions[0]);
    }
  });
  relevantSubDivisions.forEach((subDivision: string) => {
    relevantDivisions.push(subDivisionMap[subDivision].division);
  });
  relevantDivisions.forEach((division: string) => {
    relevantTheaters.push(divisionMap[division].theater);
  });
  const request = [...new Set(relevantTheaters)].map((theater) => {
    const selectedDivisionsOfTheater = [...new Set(relevantDivisions)].filter((division: string) =>
      theaterMap[theater].divisions.includes(division)
    );
    return {
      name: theater,
      divisions: selectedDivisionsOfTheater.map((division: string) => {
        const selectedSubDivisionsOfDivision = [...new Set(relevantSubDivisions)].filter((subDivision: string) =>
          divisionMap[division].subDivisions.includes(subDivision)
        );
        return {
          name: division,
          subDivisions: selectedSubDivisionsOfDivision.map((subDivision: string) => {
            const relevantFullAccounts = relevantAccounts.map((account: string) => accountMap[account]);
            const relevantFullAccountsBySubdivision = relevantFullAccounts.filter(
              (account: any) => account.subDivisions[0] === subDivision
            );
            return {
              name: subDivision,
              accounts: relevantFullAccountsBySubdivision.map((account: any) => ({ id: account.id })),
            };
          }),
        };
      }),
    };
  });
  return request;
};

export const mapLevelToColor = (level: number): string => {
  switch (level) {
    case -2: return "#8c8c91";
    case -1: return "#000000";
    case 0: return "#ff3300";
    case 1: return "#ffc800";
    case 2: return "#00FF7F";
    case 3: return "#0e9652";
    default: return "#eae6e5";
  }
}

export const mapAssessmentToColor = (assessment: string): string => {
  switch (assessment) {
    case "excluded": return "#8c8c91";
    case "not phoning home": return "#000000";
    case "caution": return "#ff3300";
    case "optimizable": return "#ffc800";
    case "good": return "#00FF7F";
    case "advanced": return "#0e9652";
    default: return "#eae6e5";
  }
}
