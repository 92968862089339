import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  TextField,
} from "@material-ui/core";
import System from "../../common/System";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import { SnackbarSeverity } from "../../enums/SnackbarSeverity";
import Api from "../../services/Api";
import { AxiosInstance } from "axios";
import EventIcon from "@material-ui/icons/Event";
import DeleteIcon from "@material-ui/icons/Delete";
import ConfirmDialog from "../ConfirmDialog";
import "./Feedback.scss";
import Cache from "../../common/Cache";
import Utils from "../../common/Utils";
import {
  AccountHierarchyType,
  PureAccount,
  SfdcAccount,
} from "@shared/interfaces";
import { GenericAccount } from "@shared/interfaces/dist";

const getDocumentProperties = require("office-document-properties");

const axios: AxiosInstance = require("axios").create({
  timeout: 60000,
});

interface File {
  type: string;
  name: string;
  data: any;
}

export default () => {
  const { showFeedbackForm, showFeedbackData } = useSelector(
    (state: StoreState) => state
  );
  const [selectedQuarter, setSelectedQuarter] = useState("");
  const [feedbackText, setFeedbackText] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const { accountSelection } = useSelector((state: StoreState) => state);
  const [quarters, setQuarters] = useState<{ value: string; label: string }[]>(
    []
  );
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [feedbackAccounts, setFeedbackAccounts] = useState("");
  const [dialogContentText, setDialogContentText] = useState("");
  const [dialogTitle, setDialogTitle] = useState("");
  const [okText, setOkText] = useState("");
  const [showConfirmationCancel, setShowConfirmationCancel] = useState(true);
  const [loadingFileDetails, setLoadingFileDetails] = useState(false);

  const handleDelete = () => {
    setDialogContentText("Do you really want to delete the feedback?");
    setDialogTitle("Delete feedback");
    setOkText("Delete");
    setShowConfirmationCancel(true);
    setConfirmDialogOpen(true);
  };

  useEffect(() => {
    if (showFeedbackForm) {
      Api.feedback()
        .getQuarters()
        .then((response) => {
          setQuarters(
            response.map((quarter) => ({
              label: `${quarter.name} - ${quarter.year}`,
              value: `${quarter.name} - ${quarter.year}`,
            }))
          );
        });
    }
  }, [showFeedbackForm]);

  useEffect(() => {
    if (showFeedbackData) {
      setSelectedQuarter(
        showFeedbackData?.quarter + " - " + showFeedbackData?.year
      );
      setFeedbackText(showFeedbackData?.text);
    } else {
      setSelectedQuarter("");
      setFeedbackText("");
      setFile(null);
    }
  }, [showFeedbackData]);

  const handleClose = () => {
    System.closeFeedbackForm();
  };

  const handleConfirm = async () => {
    try {
      setSaving(true);
      const fileName = (file?.type && (await uploadFile())) || undefined;
      if (fileName || showFeedbackData) {
        let accountIds;
        let hierarchy;
        let parentAccountId;
        if (showFeedbackData) {
          accountIds = showFeedbackData.accounts.map((a) => a.id);
          hierarchy = showFeedbackData.hierarchy;
          parentAccountId = showFeedbackData.parentAccountId;
        } else {
          accountIds =
            accountSelection.accounts.length > 0
              ? accountSelection.accounts.map(({ id }) => id)
              : accountSelection.parentAccountId
              ? [accountSelection.parentAccountId]
              : [];
          hierarchy = accountSelection.hierarchyType;
          parentAccountId = accountSelection.parentAccountId;
        }
        const [quarter, year] = selectedQuarter.split(" - ");

        if (showFeedbackData) {
          await Api.feedback().updateFeedback(
            showFeedbackData.id,
            quarter,
            Number(year),
            feedbackText,
            hierarchy,
            accountIds,
            fileName
          );
        } else {
          await Api.feedback().sendFeedback(
            quarter,
            Number(year),
            feedbackText,
            hierarchy,
            accountIds,
            fileName,
            parentAccountId
          );
        }

        System.openSnackbar(
          "Feedback saved successfully",
          SnackbarSeverity.SUCCESS
        );
        System.closeFeedbackForm();

        System.updateLastFeedbacksRefresh();

        setSelectedQuarter("");
        setFeedbackText("");
        setFile(null);
      }
    } catch (e) {
      System.openSnackbar(
        "There was an error while sending the Feedback",
        SnackbarSeverity.ERROR
      );
    } finally {
      setSaving(false);
    }
  };

  const deleteFeedback = async () => {
    try {
      if (!showFeedbackData || !showFeedbackData.id) return;

      setDeleting(true);

      await Api.feedback().deleteFeedback(showFeedbackData.id);

      System.openSnackbar(
        "Feedback deleted successfully",
        SnackbarSeverity.SUCCESS
      );
      System.closeFeedbackForm();

      System.updateLastFeedbacksRefresh();

      setSelectedQuarter("");
      setFeedbackText("");
      setFile(null);
    } catch (e) {
      System.openSnackbar(
        "There was an error while deleting the Feedback",
        SnackbarSeverity.ERROR
      );
    } finally {
      setDeleting(false);
    }
  };

  const uploadFile = async () => {
    if (!file) return;
    try {
      const signedUrlReponse = await Api.uploads().uploadUrl({
        contentType: file.type,
        extension: file.name.split(".").pop() || "ppt",
      });

      await axios.put(signedUrlReponse.url, file.data, {
        headers: {
          "Content-Type": file.type,
        },
      });

      return signedUrlReponse?.filename;
    } catch (error) {
      System.openSnackbar(
        "There was an error while retrieving the upload url",
        SnackbarSeverity.ERROR
      );
    }
    return null;
  };

  const handleFileChange = (event: any) => {
    if (event.target.files && event.target.files.length) {
      const { type, name } = event.target.files[0];
      const reader = new FileReader();

      reader.onload = function (fileReadEvent) {
        let buff = Buffer.from(reader.result as string);
        getDocumentProperties.fromBuffer(buff, function (
          err: any,
          data: { subject: string }
        ) {
          if (err) {
            console.error(err);
          } else if (data.subject) {
            let accountsIds: any[] = [];
            if (
              accountSelection.hierarchyType === AccountHierarchyType.SFDC &&
              data.subject.includes("Accounts:")
            ) {
              accountsIds = data.subject.replace("Accounts:", "").split(",");
            } else if (
              accountSelection.hierarchyType === AccountHierarchyType.PURE &&
              data.subject.includes("Orgs:")
            ) {
              accountsIds = data.subject.replace("Orgs:", "").split(",");
            }
            if (accountsIds.length > 0) {
              const notFound: string[] = [];
              accountSelection.accounts.forEach((account) => {
                if (
                  !accountsIds.find((accountId) => account.id === accountId)
                ) {
                  notFound.push(account.id);
                }
              });
              if (notFound.length > 0) {
                setLoadingFileDetails(true);

                const showError = (
                  notFoundAccountDetails: GenericAccount[]
                ) => {
                  setDialogContentText(`The presentation file you selected was generated for different accounts/organizations but it can still be used in this feedback submission. The following accounts are missing in the presentation: <br/><br/>
                    ${notFoundAccountDetails.map((a) => a.name).join(", ")}
                    `);
                  setDialogTitle("Accounts/Organizations Mismatch");
                  setOkText("Ok");
                  setShowConfirmationCancel(false);
                  setConfirmDialogOpen(true);
                };

                if (Utils.isSFDC(accountSelection.hierarchyType)) {
                  Api.accounts()
                    .sfdcNames(notFound)
                    .then((response: SfdcAccount[]) => {
                      const flat = Utils.flattenHierarchy(response);
                      const notFoundAccountDetails: SfdcAccount[] = [];
                      notFound.forEach((accountId) => {
                        const account = flat.find((a) => a.id === accountId);
                        if (account) {
                          notFoundAccountDetails.push(account);
                        }
                      });
                      showError(notFoundAccountDetails);
                    })
                    .finally(() => {
                      setLoadingFileDetails(false);
                    });
                } else {
                  Api.accounts()
                    .byOrgIds(notFound)
                    .then((response: PureAccount[]) => {
                      const notFoundAccountDetails: PureAccount[] = [];
                      notFound.forEach((accountId) => {
                        const account = response.find(
                          (a) => a.id === accountId
                        );
                        if (account) {
                          notFoundAccountDetails.push(account);
                        }
                      });
                      showError(notFoundAccountDetails);
                    })
                    .finally(() => {
                      setLoadingFileDetails(false);
                    });
                }
              }
            }
          }
        });
      };
      reader.readAsArrayBuffer(event.target.files[0]);
      setFile({ type, name, data: event.target.files[0] });
    }
  };

  const handleQuarterChange = (event: any) => {
    setSelectedQuarter(event.target.value);
  };

  const handleFeedbackTextChange = (event: any) => {
    setFeedbackText(event.target.value);
  };

  useEffect(() => {
    let accounts = "";
    if (showFeedbackData) {
      accounts = showFeedbackData.accounts
        .map((account: any) => account.name)
        .join(", ");
    } else {
      if (accountSelection.accounts.length > 0) {
        accounts = accountSelection.accounts
          .map((account) => account.name)
          .join(" | ");
      } else {
        const hierarchy = Utils.flattenHierarchy(
          Cache.getHierarchy(),
          accountSelection
        );
        const parentAccount = hierarchy.find(
          (acc) => acc.id === accountSelection.parentAccountId
        );
        if (parentAccount) {
          accounts = parentAccount.name;
        }
      }
    }
    setFeedbackAccounts(accounts);
  }, [accountSelection, showFeedbackData]);

  return (
    <>
      <Dialog
        open={showFeedbackForm}
        onClose={handleClose}
        scroll="paper"
        maxWidth="md"
        fullWidth
        disableBackdropClick
        className="feedback-form"
      >
        <DialogTitle id="feedback-form">
          {showFeedbackData ? "Edit Feedback" : "New Feedback"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid item md={5}>
              <InputLabel>Fiscal Quarter</InputLabel>
              <TextField
                disabled={saving}
                className="select-quarter"
                select={!!quarters.length}
                variant="outlined"
                value={selectedQuarter}
                onChange={handleQuarterChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EventIcon style={{ color: "#8ca5c0" }} />
                    </InputAdornment>
                  ),
                  endAdornment: !quarters.length && (
                    <div className="select-loading">
                      <CircularProgress size="16px" />
                    </div>
                  ),
                }}
              >
                {quarters.map((quarter) => (
                  <MenuItem key={quarter.value} value={quarter.value}>
                    {quarter.label}
                  </MenuItem>
                ))}
              </TextField>
              <InputLabel>PVR File</InputLabel>
              <input
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
                accept=".ppt, .pptx"
                onChange={handleFileChange}
              />
              <label htmlFor="raised-button-file" className="select-file-label">
                <TextField
                  disabled
                  size="small"
                  value={file?.name}
                  variant="outlined"
                  className="select-file"
                />
                <Button disabled={saving} className="select-file-button">
                  {loadingFileDetails ? (
                    <CircularProgress size={14} />
                  ) : (
                    "Select file"
                  )}
                </Button>
              </label>
              <Box mt={2}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: showFeedbackData
                      ? `Updating <strong>${Utils.hierarchyName(
                          accountSelection.hierarchyType
                        )}</strong> feedback for: `
                      : `Submitting <strong>${Utils.hierarchyName(
                          accountSelection.hierarchyType
                        )}</strong> feedback to: `,
                  }}
                ></div>
                <div className="accountSelection">{feedbackAccounts}</div>
              </Box>
            </Grid>
            <Grid item md={7}>
              <Box
                style={{ borderLeft: "2px solid #F5F7FF", paddingLeft: "20px" }}
              >
                <InputLabel>Feedback</InputLabel>
                <TextField
                  disabled={saving}
                  multiline
                  fullWidth
                  variant="outlined"
                  value={feedbackText}
                  onChange={handleFeedbackTextChange}
                  size="medium"
                  rows={10}
                ></TextField>
              </Box>
            </Grid>
          </Grid>
          <DialogActions>
            <Grid item md={8} className="dialog-actions">
              {showFeedbackData && (
                <div className="buttons-container">
                  <Button
                    onClick={handleDelete}
                    disabled={saving}
                    className="delete-button"
                  >
                    <DeleteIcon fontSize="small" className="delete-icon" />
                    Delete feedback?
                  </Button>
                </div>
              )}
              <Button
                disabled={saving}
                variant="outlined"
                onClick={handleClose}
                color="primary"
                className="cancel-button"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  saving ||
                  !feedbackText ||
                  !selectedQuarter ||
                  (!file && !showFeedbackData)
                }
                onClick={handleConfirm}
                color="primary"
                variant="outlined"
              >
                {saving && (
                  <Box mr={1} className="button-progress-container">
                    <CircularProgress size={14} />
                  </Box>
                )}
                Save
              </Button>
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        loading={deleting}
        confirmDialogOpen={confirmDialogOpen}
        handleOk={deleteFeedback}
        handleClose={() => setConfirmDialogOpen(false)}
        okText={okText}
        dialogContentText={dialogContentText}
        dialogTitle={dialogTitle}
        showCancel={showConfirmationCancel}
      />
    </>
  );
};
