import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import System from "../../common/System";
import Api from "../../services/Api";
import { SnackbarSeverity } from "../../enums/SnackbarSeverity";
import ConfirmDialog from "../ConfirmDialog";

import { Feedback } from "@shared/interfaces";
import { StoreState } from "../../types/StoreState";
import ReadOnlyTooltip from "../ReadOnlyTooltip";

const menuItemStyle = {
  color: "#333333",
  fontSize: "14px",
  padding: "12px 20px",
  minWidth: "175px",
};

const iconStyle = { color: "#cecece", marginRight: "10px" };

export default ({ feedback }: { feedback: Feedback }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    headerDashboard
  } = useSelector((state: StoreState) => state);
  const isReadOnly = headerDashboard?.applicationInfo?.isReadOnly || false

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const editFeedback = () => {
    System.openFeedbackForm(feedback);
    handleClose();
  };

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deletting, setDeletting] = useState(false);

  const handleDelete = () => {
    setConfirmDialogOpen(true);
    handleClose();
  };

  const deleteFeedback = async () => {
    try {
      setDeletting(true);

      await Api.feedback().deleteFeedback(feedback.id);

      System.openSnackbar(
        "Feedback deleted successfully",
        SnackbarSeverity.SUCCESS
      );
      System.closeFeedbackForm();

      System.updateLastFeedbacksRefresh();
    } catch (e) {
      System.openSnackbar(
        "There was an error while deletting the Feedback",
        SnackbarSeverity.ERROR
      );
    } finally {
      setDeletting(false);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <ReadOnlyTooltip activate={isReadOnly}>
          <MenuItem style={menuItemStyle} onClick={isReadOnly ? void 0 : editFeedback}>
              <EditIcon fontSize="small" style={iconStyle} />
              Edit
          </MenuItem>
        </ReadOnlyTooltip>
        <ReadOnlyTooltip activate={isReadOnly}>
          <MenuItem style={menuItemStyle} onClick={isReadOnly ? void 0 :handleDelete}>
              <DeleteIcon fontSize="small" style={iconStyle} />
              Delete
          </MenuItem>
        </ReadOnlyTooltip>
      </Menu>
      <ConfirmDialog
        loading={deletting}
        confirmDialogOpen={confirmDialogOpen}
        handleOk={deleteFeedback}
        handleClose={() => setConfirmDialogOpen(false)}
        okText="Delete"
        dialogContentText="Do you really want to delete the feedback?"
        dialogTitle="Delete feedback"
      />
    </>
  );
};
