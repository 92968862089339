import { AccountSelection } from "../types/AccountSelection";

export default abstract class AppStorage {
  static ACCOUNT_SELECTION = "account-selection";
  static TRACK_LOGIN = "track-login";

  static saveAccountSelection(accountSelection: AccountSelection) {
    let copy = { ...accountSelection };
    // For some weird reason, tableData is read only and material table complains about it. So we make a copy.
    copy.accounts = copy.accounts.map((account: any) => {
      let { tableData, ...accountCopy } = account;
      return accountCopy;
    });
    sessionStorage.setItem(AppStorage.ACCOUNT_SELECTION, JSON.stringify(copy));
  }

  static getAccountSelection() {
    const selection = sessionStorage.getItem(AppStorage.ACCOUNT_SELECTION);
    return selection ? JSON.parse(selection) : undefined;
  }

  static trackLoginEvent() {
    sessionStorage.setItem(AppStorage.TRACK_LOGIN, JSON.stringify(true));
  }

  static getTrackLoginEvent() {
    const trackLogin = sessionStorage.getItem(AppStorage.TRACK_LOGIN);
    if (trackLogin) {
      return JSON.parse(trackLogin);
    } else {
      return undefined;
    }
  }

  static removeTrackLoginEvent() {
    sessionStorage.removeItem(AppStorage.TRACK_LOGIN);
  }
}
