import "./LineChart.scss";

import {
  ColumnChartComponent,
  DataLoadingStrategy,
  Visualization,
} from "@shared/interfaces";
import {
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";
import Api from "../../services/Api";
import { CircularProgress } from "@material-ui/core";
import NoData from "../NoData";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";

const colors = ["#FE5000", "#2CD9C5", "#59a14f", "#76b7b2"];

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

export default ({
  visualization,
  dashboardId,
  rtcoData,
  dashboardHasDataForAll,
}: {
  visualization: Visualization;
  dashboardId: string;
  rtcoData?: any[];
  dashboardHasDataForAll: boolean,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
  } = useSelector((state: StoreState) => state);
  const [visible, setVisible] = useState(false);
  const height: number = visualization.representation.formatting?.height || 300;
  const XAxisColumn = visualization.representation.columns?.find(
    (column) => column.chartComponent === ColumnChartComponent.XAXIS
  );

  if (!XAxisColumn) {
    return (
      <div className="visualization-error">
        No column found with {ColumnChartComponent.XAXIS} 'chartComponent'
        property configured.
      </div>
    );
  }
  const lines = visualization.representation.columns?.filter(
    (column) => column.chartComponent === ColumnChartComponent.LINE
  );
  if (!lines || lines.length === 0) {
    return (
      <div className="visualization-error">
        At least one column must be configured with the{" "}
        {ColumnChartComponent.LINE} 'chartComponent' property.
      </div>
    );
  }

  useEffect(() => {
    if(rtcoData?.length){
      setData(rtcoData)
      setLoading(false)
    }
  }, [rtcoData])

  useEffect(() => {
    if(!dashboardHasDataForAll){
      let mounted = true;
      if (visualization.loadingStrategy === DataLoadingStrategy.LAZY && visible) {
        setLoading(true);
        Api.visualizations()
          .byId(
            dashboardId,
            visualization.id,
            {
              parentAccountId: accountSelection.parentAccountId,
              selectedAccountIds: accountSelection.accounts.map(
                (account) => account.id
              ),
              hierarchyType: accountSelection.hierarchyType,
              arrayType: selectedArraysEnvironment,
            },
            {
              dashboardName: currentDashboardName,
              visualizationName: visualization.name,
            }
          )
          .then((response) => {
            if (mounted) {
              setData(Utils.formatData(visualization, response));
            }
          })
          .finally(() => {
            if (mounted) {
              setLoading(false);
            }
          });
      } else {
        setData(Utils.formatData(visualization, visualization.data as any[]));
        setLoading(false);
      }
      return () => {
        mounted = false;
      };
    }
  }, [
    accountSelection,
    visualization,
    dashboardId,
    dashboardHasDataForAll,
    selectedArraysEnvironment,
    currentDashboardName,
    visible,
  ]);

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility active={!visualization.groupBy}>
      <div className="line-chart">
        {loading && <CircularProgress size={20} />}
        {!loading && (
          <>
            {data && data.length > 0 ? (
              <ResponsiveContainer width="98%" height={height}>
                <LineChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 15,
                    left: 10,
                    bottom: visualization.representation.formatting?.XAxisAngle
                      ? 110
                      : 20,
                  }}
                >
                  {/* <CartesianGrid /> */}
                  <XAxis
                    dataKey={XAxisColumn.name}
                    angle={visualization.representation.formatting?.XAxisAngle}
                    textAnchor={
                      visualization.representation.formatting?.XAxisAngle
                        ? "end"
                        : "middle"
                    }
                    interval={0}
                    type={"category"}
                    tick={
                      !visualization.representation.formatting?.XAxisAngle ? (
                        <CustomizedAxisTick />
                      ) : (
                        true
                      )
                    }
                  />
                  <YAxis minTickGap={40}>
                    {visualization.representation.formatting?.YAxisLabel && (
                      <Label
                        angle={-90}
                        value={
                          visualization.representation.formatting?.YAxisLabel
                        }
                        position="insideLeft"
                        style={{ textAnchor: "middle" }}
                      />
                    )}
                  </YAxis>
                  <Tooltip />
                  <Legend verticalAlign={"top"} iconType="circle" />
                  {lines.map((line, i) => (
                    <Line
                      key={line.name}
                      type="monotone"
                      name={line.label}
                      dataKey={line.name}
                      stroke={colors[i % colors.length]}
                      activeDot={{ r: 8 }}
                      strokeWidth={3}
                      dot={false}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </VisibilitySensor>
  );
};
