import React from "react";

export default () => (
  <div
    className="visualization-error"
    style={{ padding: "10px", paddingBottom: "30px" }}
  >
    No data available.
  </div>
);
