import React from 'react'
import { Tooltip, Typography } from '@material-ui/core'

const defaultReadOnlyMessage = 'This feature is currently disabled because the application is in Read-Only mode'

const ReadOnlyTooltip = ({
  activate,
  message,
  ...props
}: {
  activate: boolean
  message?: string
  children: any
}) => {
  return (
    <>
      <Tooltip 
        title={
          activate ?
          <React.Fragment>
            <Typography color="inherit">
              {
                message || defaultReadOnlyMessage
              }
            </Typography>
          </React.Fragment> : ""
        }
        arrow={true}
      >
          {props.children}
      </Tooltip>
    </>
  )

}

export default ReadOnlyTooltip