import "./AccountSelector.scss";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import Api from "../../services/Api";
import debounce from "lodash.debounce";
import _isEmpty from "lodash.isempty";
import { getPreferenceType, PreferencesType, DataMaps, makeAccountsOnlyRequestObject, makeSubdivisionsOnlyRequestObject, makeDivisionsOnlyRequestObject, makeTheatersOnlyRequestObject, normalizeExecutiveData, makeStandAloneAccountsObject, makeStandAlonePartnersObject, makeAccountOwnersOnlyRequestObject, makeStandAloneAccountOwnersObject } from "../../util/util";
import System from "../../common/System";
import { StoreState } from "../../types/StoreState";
import { useSelector } from "react-redux";

const DEFAULT_NUMBER_OF_RECORDS = 50

type TableData = {
  id?: string
  checked?: boolean
}

interface Account {
  accountId: string
  accountName: string
  partnerAccountName: string | null
  partnerAccountId: string | null
  division: string
  subDivision: string
  theater: string
  tableData?: TableData
  AccountOwner: string
}
interface FilteredAccount {
  accountId: string
  accountName: string
  tableData?: TableData
}

interface Theater {
  theater: string
  tableData?: TableData
}

interface Division {
  division: string
  tableData?: TableData
}

interface SubDivision {
  subDivision: string
  tableData?: TableData
}

interface Partner {
  partnerAccountName: string | null
  partnerAccountId: string | null
  tableData?: TableData
}

export default (props: any) => {
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [relevantAccounts, setRelevantAccounts] = useState<Account[]>([]);
  const [relevantAccountOwners, setRelevantAccountOwners] = useState<Account[]>([]);
  const [relevantPartners, setRelevantPartners] = useState<Account[]>([]);
  const [filteredExecAccounts, setFilteredExecAccounts] = useState<Account[]>([]);
  const [accountOwners, setAccountOwners] = useState<Account[]>([]);
  const [theaters, setTheaters] = useState<Theater[]>([]);
  const [divisions, setDivisions] = useState<Division[]>([]);
  const [subDivisions, setSubDivisions] = useState<SubDivision[]>([]);
  const [partners, setPartners] = useState<Account[]>([]);
  const [accountSearchText, setAccountSearchText] = useState<string>('');
  const [accountOwnerSearchText, setAccountOwnerSearchText] = useState<string>('');
  const [partnerSearchText, setPartnerSearchText] = useState<string>('');
  const [allTheatersSelected, setAllTheatersSelected] = useState<boolean>(false);
  const [allDivisionsSelected, setAllDivisionsSelected] = useState<boolean>(false);
  const [allSubDivisionsSelected, setAllSubDivisionsSelected] = useState<boolean>(false);
  const [allAccountsSelected, setAllAccountsSelected] = useState<boolean>(false);
  const [allPartnersSelected, setAllPartnersSelected] = useState<boolean>(false);
  const [featureError, setFeatureError] = useState<string>('');
  const [dataMaps, setDataMaps] = useState<DataMaps>({
    theaterMap: {},
    divisionMap: {},
    subDivisionMap: {},
    partnerMap: {},
    accountMap: {},
    ownerMap: {},
  })

  const allAccountsAndPartnersSelected = allAccountsSelected && allPartnersSelected

  const getSelectedItems = (items: any) => items.filter((item: any) => item.tableData?.checked)

  const isStandAloneAccount = getSelectedItems(theaters).length === 0 && getSelectedItems(divisions).length === 0 && getSelectedItems(subDivisions).length === 0 && !!getSelectedItems(filteredExecAccounts).length
  const isStandAloneAccountOwner = getSelectedItems(theaters).length === 0 && getSelectedItems(divisions).length === 0 && getSelectedItems(subDivisions).length === 0 && getSelectedItems(filteredExecAccounts).length === 0 && !!getSelectedItems(accountOwners).length
  const isStandAlonePartner = getSelectedItems(theaters).length === 0 && getSelectedItems(divisions).length === 0 && getSelectedItems(subDivisions).length === 0 && getSelectedItems(filteredExecAccounts).length === 0 && getSelectedItems(accountOwners).length === 0 && !!getSelectedItems(partners).length
  const emptyUntilSubDivisions = getSelectedItems(theaters).length === 0 && getSelectedItems(divisions).length === 0 && getSelectedItems(subDivisions).length === 0
  const emptyUntilAccounts = emptyUntilSubDivisions && getSelectedItems(accountOwners).length === 0

  const { executiveAccounts, executiveAccountFullDatasetLoading } = useSelector((state: StoreState) => state);
  const user = useSelector((state: StoreState) => state.user);

  const setInitialValues = (defaultPreferences: any) => {

    if(defaultPreferences !== undefined){
      const preferenceType = getPreferenceType(defaultPreferences)
      if(preferenceType === PreferencesType.ACCOUNTS_WITH_OWNERS_OR_PARTNERS){

      }
      if(preferenceType === PreferencesType.ACCOUNTS_ONLY){
          const { accountMap } = dataMaps
          const defaultPreferencesAccounts: Account[] = defaultPreferences.accounts.map((account: string) => ({...accountMap[account]?.account, tableData: {...accountMap[account]?.account?.tableData, checked: true}}))
          setFilteredExecAccounts(defaultPreferencesAccounts)
          handleChangeAccountSelection(defaultPreferencesAccounts)
          return
      }
      if(preferenceType === PreferencesType.OWNERS_ONLY){
        const { ownerMap } = dataMaps
        const defaultPreferencesOwners: Account[] = defaultPreferences.accountOwners.map((accountOwner: string) => (
          { 
            ...ownerMap[accountOwner]?.accounts[0],
            tableData: {
              ...ownerMap[accountOwner]?.accounts[0]?.tableData,
              checked: true
            }
          
          }
        ))
        setAccountOwners(defaultPreferencesOwners)
        return 
      }
      if(preferenceType === PreferencesType.PARTNERS_ONLY){
        const { partnerMap } = dataMaps
        const defaultPreferencesPartners: Account[] = defaultPreferences.partners.map((partner: string) => {
          const relevantPartner = partnerMap[partner]?.accounts[0]
          return {
            ...relevantPartner,
            tableData: {
              ...relevantPartner?.tableData,
              checked: true,
            }
          }
        })
        setPartners([...defaultPreferencesPartners])
        return 
      }

      const { theaterMap } = dataMaps
      if (!_isEmpty(theaterMap)) {
        const theaters = Object.keys(theaterMap).map((value: any) => {
          if(preferenceType === PreferencesType.EMPTY){
            return { theater: value,}
          }
          if(defaultPreferences.theaters.includes(value)){
            return { theater: value, tableData: {...value.tableData, checked: true} };
          }else{
            return { theater: value,}
          }
        })
        setTheaters(theaters);

        if(preferenceType === PreferencesType.EMPTY) return

        const selectedTheaters = theaters.filter(theater => theater?.tableData?.checked)
        const { newDivisions, relevantAccounts: relevantAccountsAfterTheaterChange } = handleChangeTheaterSelection(selectedTheaters)

        if(preferenceType === PreferencesType.THEATERS_ONLY) return

        const newnewDivisions: any = newDivisions.map((value: any) => {
          return { 
              ...value, 
              division: value.division, 
              tableData: { 
                ...value.tableData,
                checked: defaultPreferences.divisions.includes(value.division)
              }
          }})
        setDivisions(newnewDivisions)

        const selectedDivisions = newnewDivisions.filter((division: any) => division?.tableData?.checked)
        const { newSubDivisions, relevantAccounts: relevantAccountsAfterDivisionChange } = handleChangeDivisionSelection(selectedDivisions, relevantAccountsAfterTheaterChange)

        if(preferenceType === PreferencesType.THEATERS_AND_DIVISIONS) return

        const newnewSubdivisions: any = newSubDivisions.map((value: any) => {
          return {
            ...value,
            subDivision: value.subDivision,
            tableData: {
              ...value.tableData,
              checked: defaultPreferences.subDivisions.includes(value.subDivision)
            }
          }
        })

        setSubDivisions(newnewSubdivisions)

        const selectedSubDivisions = newnewSubdivisions.filter((subDivision: any) => subDivision?.tableData?.checked)
        const { relevantAccounts: relevantAccountsAfterSubDivisionChange, filteredExecAccounts }: any = handleChangeSubDivisionSelection(selectedSubDivisions, relevantAccountsAfterDivisionChange)

        if(preferenceType === PreferencesType.THEATERS_DIVISIONS_AND_SUBDIVISIONS) return

        setRelevantAccounts(relevantAccountsAfterSubDivisionChange as Account[])
        const newFilteredExecAccounts: any = filteredExecAccounts.map((value: any) => {
          return {
            ...value,
            tableData: {
              ...value.tableData,
              checked: defaultPreferences.accounts.includes(value.accountName)
            }
          }
        })
        const selectedAccounts = newFilteredExecAccounts.filter((account: any) => account?.tableData?.checked)
        const searchedAccounts: Account[] = []
        const leftoutPreferencesAccounts = defaultPreferences.accounts.filter((accountName: string) => !selectedAccounts.some((account: Account) => account.accountName === accountName))
        leftoutPreferencesAccounts.forEach((accountName: string) => {
          searchedAccounts.push(...relevantAccountsAfterSubDivisionChange.filter((account:Account) => account.accountName === accountName))
        })
        const fullAccounts = [...searchedAccounts, ...newFilteredExecAccounts]

        const newnewFilteredExecAccounts: any = fullAccounts.map((value: any) => {
          return {
            ...value,
            tableData: {
              ...value.tableData,
              checked: defaultPreferences.accounts.includes(value.accountName)
            }
          }
        })

        setFilteredExecAccounts([...newnewFilteredExecAccounts])

        const newSelectedAccounts = newnewFilteredExecAccounts.filter((account: any) => account?.tableData?.checked)

        const { statelessAccountOwners, statelessPartners } = handleChangeAccountSelection(newSelectedAccounts, newnewFilteredExecAccounts)

        if(preferenceType === PreferencesType.THEATERS_DIVISIONS_SUBDIVISIONS_AND_ACCOUNTS) return 

        const newStatelessAccountOwners = statelessAccountOwners.map((value: any) => {
          return {
            ...value,
            tableData: {
              ...value.tableData,
              checked: defaultPreferences.accountOwners.includes(value.AccountOwner)
            }
          }
        })
        setAccountOwners(newStatelessAccountOwners)

        if(preferenceType === PreferencesType.THEATERS_DIVISIONS_SUBDIVISIONS_ACCOUNTS_AND_OWNERS) return
        
        const newStatelessPartners = statelessPartners.map((value: any) => {
          return {
            ...value,
            tableData: {
              ...value.tableData,
              checked: defaultPreferences.partners.includes(value.partnerAccountName)
            }
          }
        })
        setPartners(newStatelessPartners)
      }
    }
  }

  useEffect(() => {
    if (props.isExecutiveReset) {
      setInitialValues({})
      setSubDivisions([])
      setDivisions([])
      setFilteredExecAccounts([])
      setPartners([])
      setAccountOwners([])
      setRelevantAccountOwners(allAccounts)
      setRelevantAccounts(allAccounts)
      setRelevantPartners(allAccounts)
      props.setIsExecutiveReset(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isExecutiveReset]);

  useEffect(() => {
    if(props.currentPreference){
      setInitialValues({})
      setTheaters([])
      setSubDivisions([])
      setDivisions([])
      setFilteredExecAccounts([])
      setPartners([])
      setAccountOwners([])
      setRelevantAccountOwners(allAccounts)
      setRelevantAccounts(allAccounts)
      setRelevantPartners(allAccounts)
      setInitialValues(props.currentPreference.val)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.currentPreference]);

  useEffect(() => {
    if (isStandAloneAccount) {
      props.setExecutiveRollupRequest(makeStandAloneAccountsObject(getSelectedItems(filteredExecAccounts), getSelectedItems(partners), dataMaps))
      props.setExecutiveRollupPreferences({ 
        accounts: [...getSelectedItems(filteredExecAccounts).map((account: Account) => account.accountName)],
      })
    } else if (isStandAloneAccountOwner) {
      props.setExecutiveRollupRequest(makeStandAloneAccountOwnersObject(getSelectedItems(accountOwners), dataMaps))
      props.setExecutiveRollupPreferences({ 
        accountOwners: [...getSelectedItems(accountOwners).map((account: Account) => account.AccountOwner)],
      })
    }
    else if (isStandAlonePartner) {
      props.setExecutiveRollupRequest(makeStandAlonePartnersObject(getSelectedItems(partners), dataMaps))
      props.setExecutiveRollupPreferences({ 
        partners: [...getSelectedItems(partners).map((account: Account) => account.partnerAccountName)]
      })
    } else { }
    const selectedTheaters = getSelectedItems(theaters)
    if (selectedTheaters.length) {
      const selectedDivisions = getSelectedItems(divisions)
      if (selectedDivisions.length) {
        const selectedSubDivisions = getSelectedItems(subDivisions)
        if (selectedSubDivisions.length) {
          const selectedAccounts = getSelectedItems(filteredExecAccounts)
          if (selectedAccounts.length) {
            const selectedAccountOwners = getSelectedItems(accountOwners)
            if (selectedAccountOwners.length) {
              const selectedPartners = getSelectedItems(partners)
              if (selectedPartners.length) {
                let finalObject = selectedTheaters.map((theater: Theater) => {
                  const relevantDivisions = selectedDivisions.filter((division: Division) => {
                    const accountWithDivision = allAccounts.find(acc => acc.division === division.division)
                    return accountWithDivision?.theater === theater.theater
                  })
                  return {
                    name: theater.theater,
                    divisions: relevantDivisions.map((division: Division) => {
                      const relevantSubDivisions = selectedSubDivisions.filter((subDivision: SubDivision) => {
                        const divisionWithSubDivision = allAccounts.find(acc => acc.subDivision === subDivision.subDivision)
                        return divisionWithSubDivision?.division === division.division
                      })
                      return {
                        name: division.division,
                        subDivisions: relevantSubDivisions.reduce((accumulator: Array<any>, subDivision: SubDivision) => {
                          const selectedPartners = getSelectedItems(partners)
                          const selectedAccountIds = !allAccountsSelected ? getSelectedItems(filteredExecAccounts).map((acc: Account) => acc.accountId)
                            : relevantAccounts.map(acc => acc.accountId)
                          const allAccountsOfPartners: any[] = []
                          selectedPartners.forEach((partner: Account) => {
                            const partnerAccountName = partner.partnerAccountName
                            if (partnerAccountName) {
                              allAccountsOfPartners.push(...dataMaps.partnerMap[partnerAccountName].accounts)
                            }
                          })
                          const allRelevantAccounts = allAccountsOfPartners.filter(account => {
                            return (account.theater === theater.theater) && (account.division === division.division) && (account.subDivision === subDivision.subDivision) && selectedAccountIds.includes(account.accountId)
                          })
                          const relevantAccountIds: any[] = allRelevantAccounts.map(acc => ({ id: acc.accountId }))
                          if (relevantAccountIds.length || allAccountsAndPartnersSelected) {
                            accumulator.push({
                              name: subDivision.subDivision,
                              accounts: allAccountsAndPartnersSelected ? [] : relevantAccountIds
                            })
                          }
                          return accumulator
                        }, [])
                      }
                    })
                  }
                })
                finalObject = finalObject.filter((config: any) => config.divisions.length !== 0)
                props.setExecutiveRollupRequest(finalObject)
                props.setExecutiveRollupPreferences({ 
                  theaters: [...getSelectedItems(selectedTheaters).map((theater: Theater) => theater?.theater)],
                  divisions: [...getSelectedItems(selectedDivisions).map((division: Division) => division?.division)],
                  subDivisions: [...getSelectedItems(selectedSubDivisions).map((subDivision: SubDivision) => subDivision?.subDivision)],
                  accounts: [...getSelectedItems(selectedAccounts).map((account: Account) => account.accountName)],
                  accountOwners: [...getSelectedItems(selectedAccountOwners).map((account: Account) => account.AccountOwner)],
                  partners: [...getSelectedItems(selectedPartners).map((account: Account) => account.partnerAccountName)]
                })
              } else {
                props.setExecutiveRollupRequest(makeAccountOwnersOnlyRequestObject(selectedSubDivisions, selectedAccounts, selectedAccountOwners, dataMaps))
                props.setExecutiveRollupPreferences({ 
                  theaters: [...getSelectedItems(selectedTheaters).map((theater: Theater) => theater?.theater)],
                  divisions: [...getSelectedItems(selectedDivisions).map((division: Division) => division?.division)],
                  subDivisions: [...getSelectedItems(selectedSubDivisions).map((subDivision: SubDivision) => subDivision?.subDivision)],
                  accounts: [...getSelectedItems(selectedAccounts).map((account: Account) => account.accountName)],
                  accountOwners: [...getSelectedItems(selectedAccountOwners).map((account: Account) => account.AccountOwner)]
                })
              }
            } else {
              props.setExecutiveRollupRequest(makeAccountsOnlyRequestObject(selectedSubDivisions, selectedAccounts, dataMaps))
              props.setExecutiveRollupPreferences({ 
                theaters: [...getSelectedItems(selectedTheaters).map((theater: Theater) => theater?.theater)],
                divisions: [...getSelectedItems(selectedDivisions).map((division: Division) => division?.division)],
                subDivisions: [...getSelectedItems(selectedSubDivisions).map((subDivision: SubDivision) => subDivision?.subDivision)],
                accounts: [...getSelectedItems(selectedAccounts).map((account: Account) => account.accountName)]
              })
            }
          } else {
            props.setExecutiveRollupRequest(makeSubdivisionsOnlyRequestObject(selectedSubDivisions, dataMaps))
            props.setExecutiveRollupPreferences({ 
              theaters: [...getSelectedItems(selectedTheaters).map((theater: Theater) => theater?.theater)],
              divisions: [...getSelectedItems(selectedDivisions).map((division: Division) => division?.division)],
              subDivisions: [...getSelectedItems(selectedSubDivisions).map((subDivision: SubDivision) => subDivision?.subDivision)]
            })
          }
        }
        else {
          props.setExecutiveRollupRequest(makeDivisionsOnlyRequestObject(selectedDivisions, dataMaps))
          props.setExecutiveRollupPreferences({ 
            theaters: [...getSelectedItems(selectedTheaters).map((theater: Theater) => theater?.theater)],
            divisions: [...getSelectedItems(selectedDivisions).map((division: Division) => division?.division)]
          })
        }
      } else {
        props.setExecutiveRollupRequest(makeTheatersOnlyRequestObject(selectedTheaters, dataMaps))
        props.setExecutiveRollupPreferences({ theaters: [...getSelectedItems(selectedTheaters).map((theater: Theater) => theater?.theater)]})
      }
    }else{
      props.setExecutiveRollupPreferences(void 0)
    }
  }, [theaters, divisions, subDivisions, filteredExecAccounts, accountOwners, partners, allAccountsSelected, allPartnersSelected])

  useEffect(() => {
    if(dataMaps && dataMaps.theaterMap !== undefined){
      if(props.executiveRollupPreferences){
        setInitialValues(props.executiveRollupPreferences)
      }else if(props.currentPreference){
        setInitialValues(props.currentPreference.val)
      }else{
        setInitialValues({})
      }
    }
  }, [dataMaps?.theaterMap, allAccounts])

  useEffect(() => {
    const fetchData = async () => {
      console.time("fetchingAccounts");
      if (executiveAccounts === undefined) {
        const resultPromise = Api.executiveAccounts().getAccounts(user);
        const preferencesPromise = Api.executiveAccounts().getPreferences();
        const result = await resultPromise;

        if (Array.isArray(result)) {
          const preferences = await preferencesPromise;
          props.setExecutivePreferences(
            preferences
              .map((preference) => ({ ...preference, val: JSON.parse(preference.val) }))
              .sort((a, b) => b.updatedAt - a.updatedAt)
          );
          const dataMaps = normalizeExecutiveData(result);
          setDataMaps(dataMaps);
          setAllAccounts(result);
          setRelevantAccounts(result);
          setRelevantAccountOwners(result);
          setRelevantPartners(result);
          System.updateExecutiveAccounts(result);
        } else {
          if (result.error) {
            setFeatureError(result.message);
          }
        }
      }else{
        const dataMaps = normalizeExecutiveData(executiveAccounts as unknown as Account[])
        setDataMaps(dataMaps)
        setAllAccounts(executiveAccounts as unknown as Account[])
        setRelevantAccounts(executiveAccounts as unknown as Account[])
        setRelevantAccountOwners(executiveAccounts as unknown as Account[])
        setRelevantPartners(executiveAccounts as unknown as Account[])
      }
      console.timeEnd('fetchingAccounts')
    };
    props.setExecutiveScreenLoading(true);
    fetchData().finally(() => props.setExecutiveScreenLoading(false));
    return function cleanup() {
      setTheaters([])
      setDivisions([])
      setSubDivisions([])
      setFilteredExecAccounts([])
      setPartners([])
    }
  }, [executiveAccounts]);

  const handleChangeTheaterSelection = (rows: Theater[]) => {
    let relevantAccounts: Account[] = []
    if (rows.length === theaters.length) {
      setAllTheatersSelected(true)
      setRelevantAccounts(allAccounts)
    }
    else {
      relevantAccounts = [
        ...new Set(
          allAccounts
            ?.filter((value) =>
              rows.map((row) => row.theater).includes(value.theater)
            )
            ?.filter((acc) => !!acc.accountId)
        )
      ]      
      setRelevantAccounts(relevantAccounts)
    }
    const { theaterMap } = dataMaps
    const divisions: string[] = []
    rows.forEach((row) => {
      divisions.push(...theaterMap[row.theater].divisions)
    })
    const newDivisions = divisions.map((value) => {
      return { division: value };
    })
    setDivisions(newDivisions);
    setSubDivisions([]);
    setPartners([]);
    setFilteredExecAccounts([]);
    return {newDivisions, relevantAccounts}
  };

  const handleChangeDivisionSelection = (rows: Division[], accountsAfterTheaterChange?: Account[]) => {
    const relevantAccountsAfterTheaterChange = accountsAfterTheaterChange !== undefined ? accountsAfterTheaterChange : relevantAccounts
    if (allTheatersSelected && rows.length === divisions.length) {
      setAllDivisionsSelected(true)
    }
    const { divisionMap } = dataMaps
    const subDivisions: string[] = []
    rows.forEach((row) => {
      subDivisions.push(...divisionMap[row.division].subDivisions)
    })
    const newRelevantAccounts = [
      ...new Set(
        relevantAccountsAfterTheaterChange.filter((value) => value.subDivision !== null && rows.map((row) => row.division).includes(value.division))
      )
    ]
    setRelevantAccounts(newRelevantAccounts)

    const newSubDivisions = subDivisions.map((value) => {
      return { subDivision: value };
    })
    setSubDivisions(subDivisions.map((value) => {
      return { subDivision: value };
    }));
    setPartners([]);
    setFilteredExecAccounts([]);
    return { newSubDivisions, relevantAccounts: newRelevantAccounts}
  };

  const handleChangeSubDivisionSelection = (rows: SubDivision[], accountsAfterDivisionChange?: Account[]) => {
    console.time('handleChangeSubDivisionSelection')
    const relevantAccountsAfterDivisionChange = accountsAfterDivisionChange !== undefined ? accountsAfterDivisionChange : relevantAccounts
    if (allTheatersSelected && allDivisionsSelected && rows.length === subDivisions.length) {
      setAllSubDivisionsSelected(true)
      setFilteredExecAccounts(allAccounts.slice(0, DEFAULT_NUMBER_OF_RECORDS).map((value) => {
        return {
          ...value, accountName: value.accountName, accountId: value.accountId
        }
      }).sort(function (a, b) {
        if (a.accountName < b.accountName) { return -1; }
        if (a.accountName > b.accountName) { return 1; }
        return 0;
      }))
      setRelevantAccounts(allAccounts)
      setPartners([])
    } else {
      const newFilteredExecAccounts = [
        ...new Set(
          relevantAccountsAfterDivisionChange
            ?.filter((value) =>
              rows.map((row) => row.subDivision).includes(value.subDivision)
            )
            ?.map((item) => ({ ...item, accountName: item.accountName, accountId: item.accountId }))
        ),
      ].map((value) => {
        return { ...value, accountName: value.accountName, accountId: value.accountId };
      }).sort(function (a, b) {
        if (a.accountName < b.accountName) { return -1; }
        if (a.accountName > b.accountName) { return 1; }
        return 0;
      }).filter((v, i, a) => a.findIndex(t => (t.accountId === v.accountId)) === i)
      const newRelevantAccounts = [
        ...new Set(
          allAccounts
            ?.filter((value) =>
              rows.map((row) => row.subDivision).includes(value.subDivision)
            )
        ),
      ]
      const finalRelevantAccounts = [
        ...new Set(
          newRelevantAccounts
            ?.filter((value) =>
              rows.map((row) => row.subDivision).includes(value.subDivision)
            )
        ),
      ]
      setRelevantAccounts([
        ...new Set(
          newRelevantAccounts
            ?.filter((value) =>
              rows.map((row) => row.subDivision).includes(value.subDivision)
            )
        ),
      ])
      setFilteredExecAccounts(newFilteredExecAccounts.slice(0, DEFAULT_NUMBER_OF_RECORDS));
      setPartners([]);
      return {relevantAccounts: finalRelevantAccounts, filteredExecAccounts: newFilteredExecAccounts.slice(0, DEFAULT_NUMBER_OF_RECORDS)}
    }
    console.timeEnd('handleChangeSubDivisionSelection')
  };
  const handleChangeAccountSelection = (rows: FilteredAccount[], filteredAccounts?: Account[]) => {
    console.time('handleChangeAccountSelection')
    const relevantfilteredExecAccounts = filteredAccounts !== undefined ? filteredAccounts : filteredExecAccounts
    const isEverythingSelected = rows.every(row => row?.tableData?.checked)
    const allAccountsSelected = !accountSearchText && isEverythingSelected && rows.length === relevantfilteredExecAccounts.length
    setAllAccountsSelected(allAccountsSelected)
    const checkedAccounts: Account[] = []
    let remainingAccounts: Account[] = [...relevantfilteredExecAccounts]
    rows.forEach(row => {
      if (row?.tableData?.checked) {
        const defaultAccount = relevantfilteredExecAccounts.find(acc => acc.accountName === row.accountName)
        if (defaultAccount) {
          checkedAccounts.push({ ...defaultAccount, tableData: { ...defaultAccount.tableData, checked: row.tableData.checked } })
          remainingAccounts = remainingAccounts.filter(acc => acc.accountName !== row.accountName)
        } else {
          const newAccount = allAccounts.find(acc => acc.accountName === row.accountName)
          if (newAccount) {
            checkedAccounts.push({ ...newAccount, tableData: { ...newAccount.tableData, checked: row.tableData.checked } })
          }
        }
      }
    })
    setFilteredExecAccounts([...checkedAccounts, ...remainingAccounts])
    const accountOwners = [
      ...new Set(
        allAccounts
          ?.filter((value) =>
            rows
              .map((row) => row.accountId)
              .includes(value.accountId)
          )
          ?.filter((v, i, a) => a.findIndex(t => (t.AccountOwner === v.AccountOwner)) === i)
          ?.filter((item) => !!item.AccountOwner)
      )
    ]
    const statelessAccountOwners = accountOwners.map((owner) => ({ ...owner, tableData: { ...owner.tableData, checked: false } }))
    setAccountOwners(statelessAccountOwners)
    setRelevantAccountOwners(statelessAccountOwners)
    const partners = [
      ...new Set(
        allAccounts
          ?.filter((value) =>
            rows
              .map((row) => row.accountId)
              .includes(value.accountId)
          )
          ?.filter((v, i, a) => a.findIndex(t => (t.partnerAccountId === v.partnerAccountId)) === i)
          ?.filter((item) => !!item.partnerAccountId)
      ),
    ]
    const statelessPartners = partners.map((partner) => ({ ...partner, tableData: { ...partner.tableData, checked: false } }))
    setPartners(statelessPartners);
    setRelevantPartners(statelessPartners)
    if (allAccountsSelected && allTheatersSelected && allDivisionsSelected && allSubDivisionsSelected) {
      setRelevantPartners(allAccounts);
      setRelevantAccountOwners(allAccounts);
    }
    console.timeEnd('handleChangeAccountSelection')
    return { statelessAccountOwners, statelessPartners }
  };

  const handleChangePartnerSelection = (rows: Partner[]) => {
    console.time('handleChangePartnerSelection')
    const isEverythingSelected = rows.every(row => row?.tableData?.checked)
    setAllPartnersSelected(!partnerSearchText && isEverythingSelected && rows.length === partners.length)
    setPartners([...partners])

    const checkedPartners: Account[] = []
    let remainingPartners: Account[] = [...partners]
    rows.forEach(row => {
      if (row?.tableData?.checked) {
        const defaultPartner = partners.find(acc => acc.partnerAccountName === row.partnerAccountName)
        if (defaultPartner) {
          checkedPartners.push({ ...defaultPartner, tableData: { ...defaultPartner.tableData, checked: row.tableData.checked } })
          remainingPartners = remainingPartners.filter(acc => acc.partnerAccountName !== row.partnerAccountName)
        } else {
          const newPartner = relevantPartners.find(acc => acc.partnerAccountName === row.partnerAccountName)
          if (newPartner) {
            checkedPartners.push({ ...newPartner, tableData: { ...newPartner.tableData, checked: row.tableData.checked } })
          }
        }
      }
    })
    setPartners([...checkedPartners, ...remainingPartners])
    props.setSelectedCount(rows.length);
    console.timeEnd('handleChangePartnerSelection')
  };

  const handleChangeAccountOwnerSelection = (rows: FilteredAccount[]) => {
    console.time('handleChangeAccountOwnerSelection')
    setAccountOwners([...accountOwners])
    setRelevantAccountOwners([...accountOwners])
    console.timeEnd('handleChangeAccountOwnerSelection')
  };

  const onPartnersSearch = (searchText: string) => {
    
    if (searchText.length >= 3) {
      if (emptyUntilSubDivisions && getSelectedItems(filteredExecAccounts).length === 0) {
        setTheaters([])
      }
      setAccountOwnerSearchText(searchText)
      const allPartnersWithSearchText: Account[] = relevantPartners.filter(acc => acc.partnerAccountId && acc.partnerAccountName?.toLowerCase().startsWith(searchText.toLowerCase()))
      const newPartners = [...allPartnersWithSearchText, ...partners]
      const unique = [...new Map(newPartners.map(item => [item["partnerAccountName"], item])).values()]
      if (searchText) {
        setPartners(JSON.parse(JSON.stringify([...unique])))
      } else {
        setPartners(newPartners)
      }
    }
  }

  const onFilteredExecAccountsSearch = (searchText: string) => {
    if (searchText.length >= 3) {
      if (emptyUntilSubDivisions && searchText) {
        setTheaters([])
      }
      setAccountSearchText(searchText)
      const allAccountsWithSearchText: Account[] = relevantAccounts.filter(acc => acc.accountName && acc.accountName.toLowerCase().includes(searchText.toLowerCase()))
      const newFilteredExecAccounts = [...allAccountsWithSearchText, ...filteredExecAccounts]
      const unique = [...new Map(newFilteredExecAccounts.map(item => [item["accountName"], item])).values()]
      if (searchText) {
        setFilteredExecAccounts(JSON.parse(JSON.stringify(unique)))
      } else {
        setFilteredExecAccounts(filteredExecAccounts)
      }
    }
  }

  const onAccountOwnersSearch = (searchText: string) => {
    console.time('onAccountOwnersSearch')
    if (searchText.length >= 3) {
      if (emptyUntilAccounts) {
        setTheaters([])
      }
      setPartnerSearchText(searchText)
      if (allAccountsSelected && allTheatersSelected && allDivisionsSelected && allSubDivisionsSelected) {
        const allAccountOwnerNames = Object.keys(dataMaps?.ownerMap)
        const allAccountOwners: Account[] = []
        allAccountOwnerNames.forEach((ownerName: string) => {
          const ownerAccounts = dataMaps?.ownerMap[ownerName].accounts
          allAccountOwners.push(ownerAccounts[0])
        })
        setAccountOwners(allAccountOwners)
      } else {
        const allAccountOwnersWithSearchText: Account[] = relevantAccountOwners.filter(acc => acc.AccountOwner && acc.AccountOwner?.toLowerCase().startsWith(searchText.toLowerCase()))
        const newOwners = [...allAccountOwnersWithSearchText, ...accountOwners]
        const unique = [...new Map(newOwners.map(item => [item["AccountOwner"], item])).values()]
        if (searchText) {
          setAccountOwners(JSON.parse(JSON.stringify(unique)))
        } else {
          setAccountOwners(newOwners)
        }
      }
    }
    console.timeEnd('onAccountOwnersSearch')
  }

  return ( props.executiveScreenLoading || featureError ) ? (
    <Grid container direction="column" alignItems="center" style={{ height: featureError ? "100%" : ""}} justifyContent={featureError ? "center" : ""}>
      {props.executiveScreenLoading && <CircularProgress />}
      {props.executiveScreenLoading ? <div>'Processing data. This may take a while...'</div> : <Typography>{`This feature is currently unavailable. Please try again later.`}</Typography>}
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid container item md={2}>
        <Grid item md={12} className="table-wrp">
          <MaterialTable
            components={{
              Container: (props) => <div {...props} />,
            }}
            data={theaters}
            columns={[{ title: "Theaters", field: "theater" }]}
            options={{
              selection: true,
              defaultExpanded: true,
              showSelectAllCheckbox: true,
              showTitle: false,
              search: true,
              showTextRowsSelected: false,
              toolbar: true,
              paging: false,
              padding: "dense",
              maxBodyHeight: "350px",
            }}
            onSelectionChange={(rows) => handleChangeTheaterSelection(rows)}
          />
        </Grid>
      </Grid>
      <Grid container item md={2}>
        <Grid item md={12} className="table-wrp">
          <MaterialTable
            components={{
              Container: (props) => <div {...props} />,
            }}
            data={divisions}
            columns={[{ title: "Divisions", field: "division" }]}
            options={{
              selection: true,
              defaultExpanded: true,
              showSelectAllCheckbox: true,
              showTitle: false,
              search: true,
              showTextRowsSelected: false,
              toolbar: true,
              paging: false,
              padding: "dense",
              maxBodyHeight: "350px",
            }}
            onSelectionChange={(rows) => handleChangeDivisionSelection(rows)}
          />
        </Grid>
      </Grid>
      <Grid container item md={2}>
        <Grid item md={12} className="table-wrp">
          <MaterialTable
            components={{
              Container: (props) => <div {...props} />,
            }}
            data={subDivisions}
            columns={[{ title: "Subdivisions", field: "subDivision" }]}
            options={{
              selection: true,
              defaultExpanded: true,
              showSelectAllCheckbox: true,
              showTitle: false,
              search: true,
              showTextRowsSelected: false,
              toolbar: true,
              paging: false,
              padding: "dense",
              maxBodyHeight: "350px",
            }}
            onSelectionChange={(rows) => handleChangeSubDivisionSelection(rows)}
          />
        </Grid>
      </Grid>
      <Grid container item md={2}>
        <Grid item md={12} className="table-wrp">
          <MaterialTable
            components={{
              Container: (props) => <div {...props} />,
            }}
            onSearchChange={debounce(onFilteredExecAccountsSearch, 1000)}
            data={filteredExecAccounts}
            columns={[{ title: "Accounts", field: "accountName", render: (rowData) => !rowData.partnerAccountId ? <div className="disabled">{rowData.accountName}</div> : <div>{rowData.accountName}</div> }]}
            options={{
              selection: true,
              defaultExpanded: true,
              showSelectAllCheckbox: true,
              showTitle: false,
              search: true,
              showTextRowsSelected: false,
              toolbar: true,
              paging: false,
              padding: "dense",
              maxBodyHeight: "350px",
            }}
            onSelectionChange={(rows) => handleChangeAccountSelection(rows)}
            isLoading={executiveAccountFullDatasetLoading}
          />
          {
            !!filteredExecAccounts.length && !accountSearchText &&
            <>
              <div style={{ paddingTop: 16 }}>{`Showing ${filteredExecAccounts.length} out of ${relevantAccounts.length} records`}</div>
              <Grid container className="mt-10">
                <div className="no-partner-legend mr-5"></div>
                <div className="disabled">Account with no partners</div>
              </Grid>
              <Grid container className="mt-10">
                <div className="partner-legend mr-5"></div>
                <div>Account with partners</div>
              </Grid>
            </>
          }
        </Grid>
      </Grid>
      <Grid container item md={2}>
        <Grid item md={12} className="table-wrp">
          <MaterialTable
            components={{
              Container: (props) => <div {...props} />,
            }}
            onSearchChange={debounce(onAccountOwnersSearch, 300)}
            data={accountOwners}
            columns={[{ title: "Owners", field: "AccountOwner", render: (rowData) => !rowData.AccountOwner ? <div className="disabled">{rowData.AccountOwner}</div> : <div>{rowData.AccountOwner}</div> }]}
            options={{
              selection: true,
              defaultExpanded: true,
              showSelectAllCheckbox: true,
              showTitle: false,
              search: true,
              showTextRowsSelected: false,
              toolbar: true,
              paging: false,
              padding: "dense",
              maxBodyHeight: "350px",
            }}
            onSelectionChange={(rows) => handleChangeAccountOwnerSelection(rows)}
            isLoading={executiveAccountFullDatasetLoading}
          />
        </Grid>
      </Grid>
      <Grid container item md={2}>
        <Grid item md={12} className="table-wrp">
          <MaterialTable
            components={{
              Container: (props) => <div {...props} />,
            }}
            data={partners}
            columns={[
              {
                title: "Partners",
                field: "partnerAccountName",
              },
            ]}
            options={{
              selection: true,
              defaultExpanded: true,
              showSelectAllCheckbox: true,
              showTitle: false,
              search: true,
              showTextRowsSelected: false,
              toolbar: true,
              paging: false,
              padding: "dense",
              maxBodyHeight: "350px",
            }}
            onSelectionChange={(rows) => handleChangePartnerSelection(rows)}
            onSearchChange={debounce(onPartnersSearch, 1000)}
            isLoading={executiveAccountFullDatasetLoading}
          />
          {/* {
            !!partners.length && !partnerSearchText && 
              <div style={{ paddingTop: 16 }}>{`Showing ${partners.length} out of ${relevantPartners.length} records`}</div>
          } */}
        </Grid>
      </Grid>
    </Grid>
  );
};
