import { CSVLink } from "react-csv";
import Api from "../services/Api";
import React, { useRef, useState } from "react";
import { Button, CircularProgress, Menu, MenuItem } from "@material-ui/core";
import { DataLoadingStrategy, DownloadMenuItem, Visualization } from "@shared/interfaces";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";
import "./DownloadButton.scss";
import moment from "moment";
import Config from "../config/Config";
import System from "./System";
import { SnackbarSeverity } from "../enums/SnackbarSeverity";

export default ({
  visualization,
  enabled = true,
  recordsCount,
  dashboardId,
  requestFilters,
}: {
  visualization: Visualization;
  enabled?: boolean;
  recordsCount: number;
  dashboardId: string;
  requestFilters: Array<any>;
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const downloadLink = useRef<any>(null);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [csvHeaders, setCsvHeaders] = useState<any[]>([])
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
    executiveAccountSelection,
  } = useSelector((state: StoreState) => state);
  const headers = visualization.representation.columns?.map((column) => ({
    label: column.label,
    key: column.name,
  }));
  const filename = `${moment(new Date()).format(
    "YYYY-MM-DD"
  )}-${visualization.name.toLowerCase().replace(" ", "-")}.csv`;

  const handleDownloadMenuItemClick = (menuItem: DownloadMenuItem) => {
    if(menuItem.csvHeaders){
      setCsvHeaders(menuItem.csvHeaders)
    }
    setLoading(true);
    const sendDownloadLink =
      recordsCount >= Config.app.downloadByMailQuantity || visualization.forceEmailCsvExport;
    if (visualization.loadingStrategy === DataLoadingStrategy.LAZY) {
      if(executiveAccountSelection){
        Api
          .executiveDashboards()
          .loadVisualization(dashboardId, visualization.id, { ...executiveAccountSelection, downloadType: menuItem.type, filters: requestFilters, pageInfo: { pageNum: 1, pageSize: recordsCount }}, sendDownloadLink)
          .then((response) => {
            if (sendDownloadLink) {
              System.openSnackbar(
                "You will receive an email with the download link shortly",
                SnackbarSeverity.INFO
              );
            } else {
              setCsvData(response);
              downloadLink.current.link.click();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }else{
        Api.visualizations()
          .byId(
            dashboardId,
            visualization.id,
            {
              parentAccountId: accountSelection.parentAccountId,
              selectedAccountIds: accountSelection.accounts.map(
                (account) => account.id
              ),
              hierarchyType: accountSelection.hierarchyType,
              arrayType: selectedArraysEnvironment,
              pageInfo: { pageNum: 1, pageSize: recordsCount },
            },
            {
              dashboardName: currentDashboardName,
              visualizationName: visualization.name,
            },
            true,
            sendDownloadLink
          )
          .then((response) => {
            if (sendDownloadLink) {
              System.openSnackbar(
                "You will receive an email with the download link shortly",
                SnackbarSeverity.INFO
              );
            } else {
              setCsvData(response);
              downloadLink.current.link.click();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else {
      setCsvData(visualization.data || []);
      downloadLink.current.link.click();
    }
    setAnchorEl(null)
  }

  return (
    <div className="download-component">
      <CSVLink
        ref={downloadLink}
        filename={filename}
        data={csvData}
        target="_blank"
        className="CSVLink"
        headers={csvHeaders.length ? csvHeaders : headers}
      ></CSVLink>
      <Button
        size="small"
        disableElevation
        color="primary"
        variant={"contained"}
        disabled={
          !enabled ||
          loading ||
          (visualization.loadingStrategy !== DataLoadingStrategy.LAZY &&
            (!visualization.data || visualization.data.length === 0)) ||
          recordsCount === 0
        }
        className="download-button"
        onClick={(event: React.BaseSyntheticEvent<MouseEvent>) => {
          setAnchorEl(event.currentTarget)
        }}
      >
        {loading && <CircularProgress size={18} />}
        {!loading && "Download"}
      </Button>
      <Menu
        id="download-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {visualization.downloadMenuItems?.map((item: any) => (
            <MenuItem onClick={() => handleDownloadMenuItemClick(item)}>{item.name}</MenuItem>
        ))}
      </Menu>
    </div>
  );
};
