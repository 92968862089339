import { AxiosInstance, AxiosRequestConfig } from "axios";
import Cache from "../common/Cache";
import Config from "../config/Config";
import {
  AccountHierarchyType,
  AccountSearchRequest,
  ArrayType,
  Dashboard,
  DashboardViewRequest,
  DeckType,
  DefaultLoadRequest,
  DownloadTriggerSource,
  DownloadUrlRequest,
  DownloadUrlResponse,
  Feedback,
  GetSfdcAccountsRequest,
  LoadRequest,
  LogVisualizationTimeout,
  PromoData,
  PureAccount,
  Quarter,
  SfdcAccount,
  SignedUrlRequest,
  SignedUrlResponse,
} from "@shared/interfaces";
import { SnackbarSeverity } from "../enums/SnackbarSeverity";
import System from "../common/System";
import { convertBase64ToJson } from "../util/util";
import { PPTConfig } from "../components/ppt-configurator/utils";
import IndexedDB, { ExecutiveAccount } from "../common/IndexedDB";

export interface SlidePayload {
  [key: string]: boolean;
}

export interface DecksPayload {
  pptConfig: {
    [key: string]: boolean | SlidePayload;
  };
}

// Todo: Use interface from shared/interfaces
export interface PptConfigSlide {
  name: string;
  identifier: string;
  checked: boolean;
  thumbnail: string;
  description: string;
}

export interface PptConfigDeck {
  name: string;
  identifier: string;
  checked?: boolean;
  type: DeckType;
  slides?: PptConfigSlide[];
  thumbnail?: string;
  description?: string;
}

const API_URL = Config.app.apiUrl;
const axios: AxiosInstance = require("axios").create({
  timeout: 120000,
});

axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
  return config;
});

axios.interceptors.response.use(
  (response: any) => response,
  (err: any) => {
    console.error(err);
    const message = `${err.response?.data?.message || err.message}`;
    if (new RegExp(/.*\/dashboards\/.*\/visualization\/.*\w+/g).test(err.config.url)) {
      console.warn("Visualization failed to load", err.config.url);
    } else if (!err.config.headers["no-dialog"]) {
      System.openSnackbar(message, SnackbarSeverity.ERROR);
    }
    return Promise.reject(err);
  }
);

export default abstract class Api {
  private static Dashboards: Dashboard[] = [];

  static executiveDashboards() {
    return {
      getDashboards: (): Promise<any> => {
        return axios.get("/api/1.0/pvr/v1/dashboards/executive").then((response) => {
          return response.data;
        });
      },
      loadVisualization: (
        dashboardId: string,
        visualizationId: number,
        request: any,
        email: boolean = false
      ): Promise<any> => {
        const headers = email ? { "X-Amz-Invocation-Type": "Event" } : {};
        return axios
          .post(
            `/api/1.0/pvr/v1/dashboards/${dashboardId}/visualization/${visualizationId}/executive${email ? "/email" : ""
            }`,
            request,
            { headers }
          )
          .then((response) => {
            return response.data;
          });
      },
      loadMultipleVisualizations: (dashboardId: string, visualizationId: number, request: any): Promise<any> => {
        return axios
          .post(`/api/1.0/pvr/v1/dashboards/${dashboardId}/visualization/${visualizationId}/executive/multi`, request)
          .then((response) => {
            return response.data;
          });
      },
    };
  }

  static dashboards() {
    return {
      all: (): Promise<Dashboard[]> => {
        if (Api.Dashboards.length > 0) {
          return Promise.resolve(Api.Dashboards);
        } else {
          return axios.get<Dashboard[]>(`${API_URL}/dashboards`).then((response) => {
            Api.Dashboards = response.data;
            return Api.Dashboards;
          });
        }
      },
      byIds: (id: string, request: LoadRequest): Promise<Dashboard> => {
        const cachedDashboard = Api.Dashboards.find((dashboard) => dashboard.id === id);
        if (cachedDashboard) {
          return Promise.resolve(cachedDashboard);
        } else {
          return axios.post(`${API_URL}/dashboards/${id}`, request).then((res) => res.data);
        }
      },
      getDashboardData: (request: any, dashboardId: string): Promise<any> => {
        return axios.post(`${API_URL}/dashboards/${dashboardId}`, request).then((res) => res.data);
      },
    };
  }

  static accounts() {
    return {
      bySfdcId: (accountId: string): Promise<SfdcAccount[]> => {
        const cachedAccounts = Cache.getAccounts([accountId]);
        if (cachedAccounts) {
          return Promise.resolve(cachedAccounts);
        } else {
          return axios.get(`${API_URL}/accounts/sfdc/${accountId}`).then((res) => {
            Cache.setAccounts([accountId], res.data);
            return res.data;
          });
        }
      },
      bySfdcIds: (
        request: GetSfdcAccountsRequest,
        noDialog = false
      ): Promise<{ status: string; message?: string; data: SfdcAccount[] }> => {
        const cachedAccounts = Cache.getAccounts(request.accountIds);
        if (cachedAccounts) {
          return Promise.resolve({ status: "success", data: cachedAccounts });
        } else {
          return axios
            .post(`${API_URL}/accounts/sfdc/get-by-ids`, request, {
              headers: noDialog ? { "no-dialog": true } : {},
            })
            .then((res) => {
              if (res.data.status === "success") {
                Cache.setAccounts(request.accountIds, res.data.data);
              }
              return res.data;
            });
        }
      },
      sfdcSearch: (request: AccountSearchRequest): Promise<SfdcAccount[]> => {
        return axios.post(`${API_URL}/accounts/sfdc/search`, request).then((res) => res.data);
      },
      pureSearch: (request: AccountSearchRequest): Promise<PureAccount[]> => {
        return axios.post(`${API_URL}/accounts/pure/search`, request).then((res) => res.data);
      },
      sfdcNames: (accountIds: string[]): Promise<SfdcAccount[]> => {
        return axios.post(`${API_URL}/accounts/sfdc/names`, { accountIds }).then((res) => res.data);
      },
      byOrgIds: (orgIds: string[]): Promise<PureAccount[]> => {
        return axios.post(`${API_URL}/accounts/pure/by-ids`, { orgIds }).then((res) => res.data);
      },
      arrayConfig: (accountIds: string[]): Promise<any[]> => {
        return axios
          .post(`${API_URL}/export-pptx/sfdc/bcs/arrayconfig`, {
            accountIds,
          })
          .then((res) => res?.data?.data?.result);
      },
    };
  }

  static visualizations() {
    return {
      byId: (
        dashboardId: string,
        visualizationId: number,
        loadRequest: DefaultLoadRequest,
        names: {
          dashboardName: string;
          visualizationName: string;
        },
        skipCache: boolean = false,
        email: boolean = false
      ): Promise<any[]> => {
        loadRequest.selectedAccountIds =
          loadRequest.selectedAccountIds.length === 0 && loadRequest.parentAccountId
            ? [loadRequest.parentAccountId]
            : loadRequest.selectedAccountIds;
        const cachedVisualization =
          Config.app.visualizationsCacheEnabled && Cache.getVisualization(dashboardId, visualizationId, loadRequest);
        if (cachedVisualization && !skipCache) {
          return Promise.resolve(cachedVisualization);
        } else {
          const headers = email ? { "X-Amz-Invocation-Type": "Event" } : {};
          return axios
            .post(
              `${API_URL}/dashboards/${dashboardId}/visualization/${visualizationId}` + (email ? "/email" : ""),
              loadRequest,
              { headers }
            )
            .then((res) => {
              Cache.setVisualization(dashboardId, visualizationId, loadRequest, res.data);
              return res.data;
            })
            .catch((error) => {
              if (error.response.status === 504) {
                let request: LogVisualizationTimeout = {
                  ...loadRequest,
                  visualizationName: names.visualizationName,
                  dashboardName: names.dashboardName,
                  apigatewayRequestId: error.response.headers["x-amzn-requestid"],
                };
                Api.audit()
                  .visualizationTimeout(request)
                  .then((res) => { });
              } else {
                throw error;
              }
            });
        }
      },
    };
  }

  static feedback() {
    return {
      getQuarters: (): Promise<Quarter[]> => {
        const cachedQuarters = Cache.getQuarters();
        if (cachedQuarters !== null) {
          return Promise.resolve(cachedQuarters);
        } else {
          return axios.get(`${API_URL}/feedback/quarters`).then((res) => {
            Cache.setQuarters(res.data);
            return res.data;
          });
        }
      },
      sendFeedback: (
        quarter: string,
        year: number,
        text: string,
        hierarchyType: AccountHierarchyType,
        accountIds: string[],
        fileName: string | null | undefined,
        parentAccountId?: string
      ): Promise<string> => {
        return axios
          .post(`${API_URL}/feedback`, {
            quarter,
            year,
            text,
            hierarchyType,
            selectedAccountIds: accountIds,
            fileName,
            parentAccountId,
          })
          .then((res) => res.data);
      },
      updateFeedback: (
        feedbackId: number,
        quarter: string,
        year: number,
        text: string,
        hierarchyType: AccountHierarchyType,
        accountIds: string[],
        fileName: string | null | undefined
      ): Promise<string> => {
        return axios
          .put(`${API_URL}/feedback/${feedbackId}`, {
            quarter,
            year,
            text,
            hierarchyType,
            selectedAccountIds: accountIds,
            fileName,
          })
          .then((res) => res.data);
      },
      deleteFeedback: (feedbackId: number): Promise<number> => {
        return axios.delete(`${API_URL}/feedback/${feedbackId}`).then((res) => res.data);
      },
      getFeedbacks: (
        page: number,
        pageSize: number,
        sortBy: any,
        sortDir: string,
        selectedAccountIds: string[],
        hierarchyType?: AccountHierarchyType
      ): Promise<Feedback & { total: any }[]> => {
        return axios
          .post(`${API_URL}/feedback/search`, {
            page,
            pageSize,
            sortBy,
            sortDir,
            selectedAccountIds,
            hierarchyType,
          })
          .then((res) => res.data);
      },
    };
  }

  static audit() {
    return {
      login: (): Promise<any> => {
        return axios.post(`${API_URL}/audit/new-login-event`).then((res) => res.data);
      },
      visualizationTimeout: (request: LogVisualizationTimeout): Promise<any> => {
        return axios.post(`${API_URL}/audit/visualization-timeout`, request).then((res) => res.data);
      },
      dashboardView: (request: DashboardViewRequest): Promise<any> => {
        return axios.post(`${API_URL}/audit/dashboard-view`, request).then((res) => res.data);
      },
      notificationSeen: (
        notificationIds: number[] | undefined,
        selectedAccountIds: string[],
        hierarchyType?: AccountHierarchyType
      ): Promise<any> => {
        return axios
          .post(`${API_URL}/audit/notifications/mark-as-read`, {
            notificationIds,
            selectedAccountIds,
            hierarchyType,
          })
          .then((res) => res.data);
      },
    };
  }

  static uploads() {
    return {
      uploadUrl: (signedUrlRequest: SignedUrlRequest): Promise<SignedUrlResponse> => {
        return axios.post(`${API_URL}/uploads/upload-url`, signedUrlRequest).then((res) => res.data);
      },
      downloadUrl: (downloadUrlRequest: DownloadUrlRequest): Promise<DownloadUrlResponse> => {
        return axios.post(`${API_URL}/uploads/download-url`, downloadUrlRequest).then((res) => res.data);
      },
    };
  }

  static users() {
    return {
      me: (noDialog = false): Promise<any> => {
        return axios
          .get(`${API_URL}/users/me`, {
            headers: noDialog ? { "no-dialog": true } : {},
          })
          .then((res) => res.data);
      },
    };
  }

  static export() {
    return {
      ppt: (
        parentAccountId: string | undefined,
        selectedAccountIds: string[],
        hierarchyType: AccountHierarchyType,
        arrayType: ArrayType,
        includeAllArrays: boolean = false,
        rtcoParameters: any,
        pptConfig: PPTConfig,
        operation: string,
        customPreferenceName?: string,
      ): Promise<string> => {
        const customPreference = customPreferenceName ? { customPreferenceName } : {};
        const headers = Config.app.downloadPPTByMail ? { "X-Amz-Invocation-Type": "Event" } : {};

        return axios
          .post(
            `${API_URL}/export-pptx${Config.app.downloadPPTByMail ? "/email" : ""}`,
            {
              parentAccountId,
              selectedAccountIds,
              hierarchyType,
              arrayType,
              includeAllArrays,
              customPreferenceName,
              ...customPreference,
              ...rtcoParameters,
              pptConfig,
              operation
            },
            { headers }
          )
          .then((res) => res.data);
      },
    };
  }

  static notifications() {
    return {
      getNotifications: (parentAccountId: string | undefined, selectedAccountIds: string[], hierarchyType?: AccountHierarchyType): Promise<any[]> => {
        return axios
          .post(`${API_URL}/notifications/unread`, {
            parentAccountId,
            selectedAccountIds,
            hierarchyType,
          })
          .then((res) => {
            return res.data;
          });
      },
    };
  }

  static promotionsApi() {
    return {
      getPromoData: async (
        parentAccountId: string | undefined
      ): Promise<PromoData[]> => {
        return await axios
          .post(`${API_URL}/promotions/data`
            , {
              parentAccountId
            })
          .then((res) => {
            return res.data;
          });
      },
      getPromoPptx: async (
        parentAccountId: string | undefined,
        selectedAccountIds: string[],
        hierarchyType: AccountHierarchyType,
        folderName: String,
        eventSource: DownloadTriggerSource
      ): Promise<any> => {
        return await axios
          .post(`${API_URL}/promotions/promo-pptx`, {
            parentAccountId,
            selectedAccountIds,
            hierarchyType,
            folderName,
            eventSource,
          })
          .then((res) => {
            return res.data;
          });
      },
    };
  }

  static pptConfig() {
    return {
      getPptConfig: (): Promise<PptConfigDeck[]> => {
        return axios
          .get(`/api/1.0/pvr/v1/export-pptx/getconfig`)
          .then((response) => {
            console.log({ response });
            return response.data;
          })
          .catch((err) => {
            return [];
          });
      },
      postPptConfig: (request: DecksPayload): Promise<any> =>
        axios.post(`/api/1.0/pvr/v1/export-pptx/postconfig`, { ...request }),
      postPvrPptConfig: (request: DecksPayload): Promise<any> =>
        axios.post(`/api/1.0/pvr/v1/export-pptx/pvr/postconfig`, { ...request }),
      arrayConfig: (accountIds: string[]): Promise<any[]> => {
        return axios
          .post(`${API_URL}/export-pptx/sfdc/bcs/arrayconfig`, {
            accountIds,
          })
          .then((res) => res?.data?.data?.result);
      },
      getBcsConfigPreferences: (accountIds: string[]): Promise<any[]> => {
        return axios
          .post(`${API_URL}/export-pptx/sfdc/bcs/arrayconfig/preference/list`, {
            accountIds,
          })
          .then((res) => res?.data?.data?.result);
      },
      createBcsConfigPreference: (tag: string, arrayIds: string[]): Promise<any> => {
        return axios
          .post(`${API_URL}/export-pptx/sfdc/bcs/arrayconfig/preference`, {
            tag,
            arrayIds,
          })
          .then((res) => res?.data);
      },
      updateBcsConfigPreference: (tag: string, arrayIds: string[]): Promise<any> => {
        return axios
          .put(`${API_URL}/export-pptx/sfdc/bcs/arrayconfig/preference/${tag}`, {
            arrayIds,
          })
          .then((res) => res?.data);
      },
      deleteBcsConfigPreference: (tag: string): Promise<any[]> => {
        return axios.delete(`${API_URL}/export-pptx/sfdc/bcs/arrayconfig/preference/${tag}`).then((res) => res?.data);
      },
      getPvrDecks: (): Promise<PptConfigDeck[]> =>
        axios.get(`/api/1.0/pvr/v1/export-pptx/pvr/getconfig`).then((response) => response.data),
      getBcsDecks: (): Promise<PptConfigDeck[]> =>
        axios.get(`/api/1.0/pvr/v1/export-pptx/getconfig`).then((response) => response.data),
    };
  }

  static executiveAccounts() {
    return {
      /**
       * This function first checks the accounts in the IndexedDB. <br/>
       * If not found, it fetches the full dataset from the API in the background
       * and returns the partial data in the mean time.
       *
       * Even if accounts are returned from the IndexedDB, it synchronizes the data with the API in the background.
       * @returns ExecutiveAccount[]
       */
      getAccounts: async (user: { email: string }): Promise<any[] | { error: boolean; message: string }> => {
        let accounts: ExecutiveAccount[] = [];
        if (user && user.email) accounts = await IndexedDB.getExecutiveAccounts(user.email);

        if (accounts && accounts.length > 0) {
          Api.executiveAccounts()
            .getAccountsAPI()
            .then((res) => {
              console.log("getAccounts data fetched from API");
              System.updateExecutiveAccounts(res);
            });
          console.log("getAccounts data from IndexedDB");
          return accounts;
        } else {
          try {
            console.log("getAccounts data from API");
            // Fetch the partial data.
            const partialData = Api.executiveAccounts().getAccountsPartialData();

            // Fetch full dataset in the background.
            System.updateExecutiveAccountFullDataLoading(true);
            Api.executiveAccounts()
              .getAccountsAPI()
              .then((res) => {
                console.log("getAccounts full dataset fetched from API");
                System.updateExecutiveAccounts(res);
              })
              .finally(() => {
                System.updateExecutiveAccountFullDataLoading(false);
              });

            return partialData;
          } catch (_error) {
            return {
              error: true,
              message: _error as string,
            };
          }
        }
      },

      getAccountsFromIndexedDB: (user: { email: string }): Promise<ExecutiveAccount[]> => {
        if (user && user.email) return IndexedDB.getExecutiveAccounts(user.email);

        return Promise.reject("Can't search executive accounts without email address");
      },

      getAccountsPartialData: (): Promise<ExecutiveAccount[]> =>
        axios.get("/api/1.0/pvr/v1/accounts/sfdc/executive/filters").then((resp) => resp.data?.data),

      getAccountsAPI: async (): Promise<any[]> => {
        const response = await axios.get(`/api/1.0/pvr/v1/accounts/sfdc/executive`);
        const decodedData = convertBase64ToJson(response.data.data);
        if (decodedData !== null) {
          IndexedDB.setExecutiveAccounts(decodedData).then(() => {
            console.log("IndexedDB updated with latest data");
          });
          return decodedData;
        }
        return [];
      },

      getPreferences: (): Promise<any[]> => {
        return axios
          .get(`/api/1.0/pvr/v1/users/preference/executive`)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return [];
          });
      },
      createPreference: (request: any): Promise<any> => {
        return axios
          .post(`/api/1.0/pvr/v1/users/preference/executive`, { ...request })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return [];
          });
      },
      updatePreference: (request: any, id: string): Promise<any> => {
        return axios
          .put(`/api/1.0/pvr/v1/users/preference/executive/${id}`, {
            ...request,
          })
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return [];
          });
      },
      deletePreference: (id: number): Promise<any> => {
        return axios
          .delete(`/api/1.0/pvr/v1/users/preference/executive/${id}`)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return [];
          });
      },
    };
  }
}
