import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";
import Api from "../services/Api";
import { DownloadTriggerSource, PromoData } from "@shared/interfaces";
import Link from "@material-ui/core/Link";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    root: {
      width: "100%",
      maxWidth: "36ch",
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
  })
);

export default () => {
  const classes = useStyles();
  const [filteredNotifications, setFilteredNotifications] = useState<any[]>();
  const [notifications, setNotifications] = useState<any[]>();
  const [notificationNumber, setNotificationNumber] = useState<number>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { accountSelection, headerDashboard } = useSelector((state: StoreState) => state);

  const isReadOnly = headerDashboard?.applicationInfo?.isReadOnly

  useEffect(() => {
    const accountIds =
      accountSelection.accounts.length > 0
        ? accountSelection.accounts.map(({ id }) => id)
        : accountSelection.parentAccountId
        ? [accountSelection.parentAccountId]
        : [];
    Api.notifications()
      .getNotifications(accountSelection.parentAccountId, accountIds, accountSelection.hierarchyType)
      .then((value) => {
        const arrayCount = value.reduce((count, item) => {
          return item.type === "Upgrade Recommendation"
            ? count + item.array_count
            : count;
        }, 0);
        setNotifications(value);
        const filteredNotifications = value.reduce((unique, item) => {
          return unique.map((i: any) => i.Message).includes(item.Message)
            ? unique
            : [...unique, item];
        }, []);
        filteredNotifications
          .filter((item: any) => item.type === "Upgrade Recommendation")
          .forEach(
            (item: any) =>
              (item.Message = item.Message.replace("%s", arrayCount))
          );
        setFilteredNotifications(filteredNotifications);
        setNotificationNumber(
          filteredNotifications.filter((item: any) => item.read === 0).length
        );
      });
  }, [accountSelection]);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (notifications && notifications.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClick = async (folderName: String) => {
    const accountIds =
      accountSelection.accounts.length > 0
        ? accountSelection.accounts.map(({ id }) => id)
        : accountSelection.parentAccountId
        ? [accountSelection.parentAccountId]
        : [];
    document.body.style.cursor = "wait";
    Api.promotionsApi()
      .getPromoPptx(
        accountSelection.parentAccountId,
        accountIds,
        accountSelection.hierarchyType,
        folderName + "/",
        DownloadTriggerSource.NOTIFICATIONS,
      )
      .then((value) => {
        const link = document.createElement("a");
        link.setAttribute("download", "");
        link.setAttribute("hidden", "");
        link.setAttribute("target", "_blank");
        link.href = value.pptxDownloadUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .finally(() => (document.body.style.cursor = "default"));
    handleClose();
  };
  const handleClose = async () => {
    setAnchorEl(null);
    const accountIds =
      accountSelection.accounts.length > 0
        ? accountSelection.accounts.map(({ id }) => id)
        : accountSelection.parentAccountId
        ? [accountSelection.parentAccountId]
        : [];
    setNotificationNumber(0);
    const unreadNotifications = notifications?.filter(
      (item) => item.read === 0
    );
    if (unreadNotifications && unreadNotifications.length > 0 && !isReadOnly) {
      Api.audit().notificationSeen(
        unreadNotifications.map((item) => {
          item.read = 1;
          return item.id as number;
        }),
        accountIds,
        accountSelection.hierarchyType
      );
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton onClick={handleOpen} color="inherit">
        <Badge badgeContent={notificationNumber} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List className={classes.root}>
          {filteredNotifications?.map((value, index) => (
            //TODO(lkurup): As a permanent soltution we need to use HTML tags inside messages and replace specific placeholders with the promo links.
            <React.Fragment>
              {value.read === 1 ? (
                <ListItem alignItems="flex-start">
                  <ListItemText
                    style={{ marginTop: 0, marginBottom: 0 }}
                    primary={
                      value.promo_folder ? (
                        <Link
                          href="#"
                          onClick={() => handleClick(value.promo_folder)}
                        >
                          {value.type}
                        </Link>
                      ) : (
                        value.type
                      )
                    }
                    secondary={value.Message}
                  />
                </ListItem>
              ) : (
                <ListItem
                  alignItems="flex-start"
                  style={{ backgroundColor: "#e2f0fe" }}
                >
                  <ListItemText
                    style={{
                      marginTop: 0,
                      marginBottom: 0,
                    }}
                    primary={
                      value.promo_folder ? (
                        <Link
                          href="#"
                          onClick={() => handleClick(value.promo_folder)}
                        >
                          {value.type}
                        </Link>
                      ) : (
                        value.type
                      )
                    }
                    secondary={value.Message}
                  />
                </ListItem>
              )}
              {index !== filteredNotifications.length - 1 ? (
                <Divider
                  variant="fullWidth"
                  component="li"
                  style={{ marginBottom: 0 }}
                />
              ) : null}
            </React.Fragment>
          ))}
        </List>
      </Popover>
    </div>
  );
};
