export default {
  app: {
    apiUrl: process.env.REACT_APP_API_URL,
    defaultDashboard: "customer-facing",
    visualizationsCacheEnabled: JSON.parse(
      process.env.REACT_APP_VISUALIZATIONS_CACHE_ENABLED || "false"
    ),
    arrayLink: process.env.REACT_APP_SKYLINE_URL + "/app/dashboard/flashArrays",
    downloadByMailQuantity: 1000,
    maxVisualizationRetries: 3,
    maxVisualizationRetriesInterval: 1000,
    downloadPPTByMail: true,
  },
};
