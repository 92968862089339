import {
  CircularProgress,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import GetApp from "@material-ui/icons/GetApp";
import React, { ChangeEvent, ComponentType, FC, forwardRef } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import { Preference } from "./config";
import DividerWithText from "../DividerWithText";
import { DefaultPreferences } from "./utils";

interface SelectOptionProps {
  tooltip: string;
  value: string;
  title: string;
  Icon?: ComponentType<{}>;
}

const SelectOption = forwardRef<HTMLLIElement, SelectOptionProps>(
  ({ title, tooltip, value, Icon = GetApp, ...props }, ref) => (
    <Tooltip enterDelay={300} title={<Typography color="inherit">{tooltip}</Typography>} arrow>
      <MenuItem ref={ref} value={value} {...props}>
        <ListItemIcon>
          <Icon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={title} />
      </MenuItem>
    </Tooltip>
  )
);

interface PreferenceSelectorProps {
  preferences: Preference[];
  value: string;
  onChange: (event: ChangeEvent<{ value: unknown }>) => void;
}

const PreferenceSelector: FC<PreferenceSelectorProps> = ({ value, preferences, onChange }) => {
  const {
    bcs: { loadingPreferences },
    user: { type: userType },
  } = useSelector((state: StoreState) => state);

  return loadingPreferences ? (
    <CircularProgress size={25} />
  ) : (
    <FormControl
      style={{
        minWidth: 150,
        maxWidth: 300,
      }}
      variant="outlined"
      size="small"
    >
      <InputLabel id="preference-label">Arrays</InputLabel>
      <Select
        labelId="preference-label"
        value={value}
        renderValue={(val) => (
          <Typography align="left" color="inherit">
            {val as string}
          </Typography>
        )}
        onChange={onChange}
        label="Arrays"
      >
        <SelectOption
          value={DefaultPreferences.ALL}
          title="All Arrays (Max: 500)"
          tooltip="All arrays of the selected customers will be included in the Fleet Details slide."
        />
        <SelectOption
          value={DefaultPreferences.DEFAULT}
          title="Default"
          tooltip="Only 5 arrays will be displayed in the Fleet Details slide."
        />
        {/* Todo: Hide this conditional check when it's ready for PVR user */}
        {(userType === "ALL" || userType === "BCS") &&
          !!preferences.length && [
            <DividerWithText key="custom-preference-divider">Custom Preferences</DividerWithText>,
            preferences.map(({ name, selection }) => (
              <SelectOption
                key={`preference-${name}`}
                value={name}
                title={name}
                tooltip={`Contains ${selection.length} arrays`}
              />
            )),
          ]}
      </Select>
    </FormControl>
  );
};

export default PreferenceSelector;
