import React, { useEffect, useState } from 'react'
import { Visualization } from '@shared/interfaces';
import Api from '../../services/Api';
import { useSelector } from 'react-redux';
import { StoreState } from '../../types/StoreState';
import Config from '../../config/Config';
import VisibilitySensor from "react-visibility-sensor";
import CircularProgress from '@material-ui/core/CircularProgress';
import HoneycombIcon from './HoneycombIcon';

export default ({
  visualization,
  dashboardId,
}: {
  visualization: Visualization
  dashboardId: string
}) => {
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [retries, setRetries] = useState(0);
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
    executiveAccountSelection,
  } = useSelector((state: StoreState) => state)

  useEffect(() => {
    let mounted = true;
    Api.visualizations()
    .byId(
      dashboardId,
      visualization.id,
      {
        parentAccountId: accountSelection.parentAccountId,
        selectedAccountIds: accountSelection.accounts.map(
          (account) => account.id,
        ),
        hierarchyType: accountSelection.hierarchyType,
        arrayType: selectedArraysEnvironment,
      },
      {
        dashboardName: currentDashboardName,
        visualizationName: visualization.name,
      },
    )
    .catch((error) => {
      if (mounted && error.response.status !== 404) {
        setTimeout(() => {
          setRetries(retries + 1)
        }, Config.app.maxVisualizationRetriesInterval)
      }
    })
    .then((response) => {
      if (mounted && response) {
        setData(response)
      }
    })
    .finally(() => {
      if (mounted) {
        setLoading(false)
      }
    })
    return () => {
      mounted = false
    }
  }, [
    accountSelection,
    executiveAccountSelection,
    visualization,
    dashboardId,
    selectedArraysEnvironment,
    currentDashboardName,
    retries,
    visible,
  ])

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  const sumPerLevel = (data: any[]) => {
    const result: any = {};
    data.forEach(d => {
      let assessmentSum = result[d.assessment as string];
      if (!assessmentSum) {
        result[d.assessment] = 1;
      } else {
        result[d.assessment] = assessmentSum + 1;
      }
    });

    return Object.entries(result);
  }

  const mapIntoHoneycombRows = (data: any[]) => {
    const cellsPerRow = 50;
    const rows = Math.ceil(data.length / cellsPerRow);
    const length = data.length;
    let result: any[] = [];


    let nextPageStartIndex = 0;
    let nextPageEndIndex: number | undefined = length - cellsPerRow >= 0 ? cellsPerRow : undefined;

    for (let i = 0; i < rows; i++) {
      result.push(
        <div className={`honeycomb-row-${i % 2 === 0 ? 'even' : 'odd'}`}>
          {
            data
            .slice(nextPageStartIndex, nextPageEndIndex)
            .map(d => 
              <HoneycombIcon assessment={d.assessment} level={d.level} arrayName={d.arrayName} />
            )
          }
        </div>
      )
      nextPageStartIndex += 50;
      nextPageEndIndex = (length - nextPageStartIndex - 50 >= 0 ? nextPageStartIndex + 50 : undefined);
    }
    
    return result;
  }

  return (
    <>
      {loading && <CircularProgress size={20} />}
      {!loading && 
          <div>
            <div className='honeycomb-legend'>Appliances ({data.length})</div>
            <div className='honeycomb-wrapper'>
              { mapIntoHoneycombRows(data
                  .sort((a, b) => (a.level as number - (b.level as number))))
                  .map(d => d) }
            </div>
            <div className='honeycomb-legend'>
              { sumPerLevel(data).map(d => 
                <span>
                  <HoneycombIcon assessment={d[0]} />
                  <span style={{ textTransform: 'capitalize', paddingRight: '10px' }}>{` ${d[0]} ${d[1]}`} </span>
                </span>
              )}
            </div>
          </div>
      }
    </>
    // <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>
    //   {loading && <CircularProgress size={20} />}
    //   {!loading && 
    //     <span style={{ color: "red", fontSize: "30px"}}>&#x2B22;</span>
    //     // <div>
    //     //   {data.map(d => <span style={{ color: mapLevelToColor(d.level), fontSize: "30px"}}>&#x2B22;</span>)}
    //     // </div>
    //   }
    // </VisibilitySensor>
  )
}
