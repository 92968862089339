import "./Feature.scss";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import {
  DataLoadingStrategy,
  Visualization,
  VisualizationType,
} from "@shared/interfaces";
import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Api from "../../services/Api";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import sort from "fast-sort";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const columns: { name: string }[] = sort([
  { name: "Automation" },
  { name: "Cloud" },
  { name: "Data protection" },
  { name: "Ecosystem" },
  { name: "Performance Bucket" },
]).asc(["name"]);

const columns_flashblade: { name: string }[] = sort([
  { name: "Data protection" },
  { name: "Data Protocols" },
  { name: "Advanced Features" },
]).asc(["name"]);

const columns_pure1: { name: string }[] = sort([
  { name: "Cost Avoidance/Reduction" },
  { name: "Software Benefits" },
]).asc(["name"]);

export default ({
  visualization,
  dashboardId,
}: {
  visualization: Visualization;
  dashboardId: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
  } = useSelector((state: StoreState) => state);
  const classes = useStyles();
  const showColumnTitles = Utils.showColumnTitles(visualization);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (visualization.loadingStrategy === DataLoadingStrategy.LAZY && visible) {
      Api.visualizations()
        .byId(
          dashboardId,
          visualization.id,
          {
            parentAccountId: accountSelection.parentAccountId,
            selectedAccountIds: accountSelection.accounts.map(
              (account) => account.id
            ),
            hierarchyType: accountSelection.hierarchyType,
            arrayType: selectedArraysEnvironment,
          },
          {
            dashboardName: currentDashboardName,
            visualizationName: visualization.name,
          }
        )
        .then((response) => {
          if (mounted) {
            setData(response);
          }
        })
        .finally(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    } else {
      setData(visualization.data || []);
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [
    accountSelection,
    visualization,
    dashboardId,
    selectedArraysEnvironment,
    currentDashboardName,
    visible,
  ]);

  const renderCell = (columnName: string, key: string) => {
    let values: any[] = [];
    let item: any =
      data && data.find((d: any) => d["Feature_Bucket"] === columnName);
    if (item) {
      item.className = "";
      values.push(item);
    }
    values = sort<any>(values).asc(["order"]);
    return values.map((item: any, i: number) => (
      <div key={i} className={item.className}>
        <span className=" feature-adopted">{item[key].split("/")[0]}</span> /
        {item[key].split("/")[1]}
      </div>
    ));
  };

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>
      <TableContainer className="feature-list customer-adoption">
        {loading ? (
          <CircularProgress />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            {showColumnTitles && (
              <TableHead>
                <TableRow>
                  <TableCell>
                    {visualization.representation.type ===
                    VisualizationType.FEATURE_SUMMARY
                      ? "FLASHARRAY FEATURES"
                      : visualization.representation.type ===
                        VisualizationType.FEATURE_SUMMARY_FLASHBLADE
                      ? "FLASHBLADE FEATURES"
                      : "PURE EVERGREEN FEATURES"}
                  </TableCell>
                  {visualization.representation.type ===
                  VisualizationType.FEATURE_SUMMARY
                    ? columns.map((column) => (
                        <TableCell key={column.name}>{column.name}</TableCell>
                      ))
                    : visualization.representation.type ===
                      VisualizationType.FEATURE_SUMMARY_FLASHBLADE
                    ? columns_flashblade
                        .map((column) => (
                          <TableCell key={column.name}>{column.name}</TableCell>
                        ))
                        .concat([
                          <TableCell key={"empty1"} />,
                          <TableCell key={"empty2"} />,
                        ])
                    : columns_pure1
                        .map((column) => (
                          <TableCell key={column.name}>{column.name}</TableCell>
                        ))
                        .concat([
                          <TableCell key={"empty1"} />,
                          <TableCell key={"empty2"} />,
                          <TableCell key={"empty3"} />,
                        ])}
                </TableRow>
              </TableHead>
            )}
            <TableBody>
              <TableRow>
                <TableCell className="main-label">
                  <>
                    <h4>CUSTOMER ADOPTION</h4>
                    <div>Features adopted / Features available</div>
                  </>
                </TableCell>
                {visualization.representation.type ===
                VisualizationType.FEATURE_SUMMARY
                  ? columns.map((column) => (
                      <TableCell key={column.name}>
                        {renderCell(column.name, "installed")}
                      </TableCell>
                    ))
                  : visualization.representation.type ===
                    VisualizationType.FEATURE_SUMMARY_FLASHBLADE
                  ? columns_flashblade
                      .map((column) => (
                        <TableCell key={column.name}>
                          {renderCell(column.name, "installed")}
                        </TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                      ])
                  : columns_pure1
                      .map((column) => (
                        <TableCell key={column.name}>
                          {renderCell(column.name, "installed")}
                        </TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                        <TableCell key={"empty3"} />,
                      ])}
              </TableRow>
              <TableRow>
                <TableCell className="main-label">
                  <>
                    <h4>PURE INDUSTRY BENCHMARK</h4>
                    <div>
                      {data &&
                        data[0] &&
                        data[0]["AccountIndustry"].slice(0, -4)}
                    </div>
                  </>
                </TableCell>
                {visualization.representation.type ===
                VisualizationType.FEATURE_SUMMARY
                  ? columns.map((column) => (
                      <TableCell key={column.name}>
                        {renderCell(column.name, "installed_industry")}
                      </TableCell>
                    ))
                  : visualization.representation.type ===
                    VisualizationType.FEATURE_SUMMARY_FLASHBLADE
                  ? columns_flashblade
                      .map((column) => (
                        <TableCell key={column.name}>
                          {renderCell(column.name, "installed_industry")}
                        </TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                      ])
                  : columns_pure1
                      .map((column) => (
                        <TableCell key={column.name}>
                          {renderCell(column.name, "installed_industry")}
                        </TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                        <TableCell key={"empty3"} />,
                      ])}
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </VisibilitySensor>
  );
};
