import "./LineChart.scss";

import {
  ColumnChartComponent,
  DataLoadingStrategy,
  Visualization,
} from "@shared/interfaces";
import {
  Bar,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  RectangleProps,
  ResponsiveContainer,
  Text,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React, { useEffect, useState } from "react";
import Api from "../../services/Api";
import { CircularProgress } from "@material-ui/core";
import NoData from "../NoData";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";

const colors = ["#FE5000", "#2CD9C5", "#59a14f", "#76b7b2"];

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload } = props;

  return (
    <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
      {payload.value}
    </Text>
  );
};

interface ExtendedRectangleProps extends RectangleProps {
  payload?: any;
}

export default ({
  visualization,
  dashboardId,
  rtcoData,
  dashboardHasDataForAll,
}: {
  visualization: Visualization;
  dashboardId: string;
  rtcoData?: Array<any>;
  dashboardHasDataForAll: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
  } = useSelector((state: StoreState) => state);
  const [visible, setVisible] = useState(false);
  const height: number = visualization.representation.formatting?.height || 300;
  const XAxisColumn = visualization.representation.columns?.find(
    (column) => column.chartComponent === ColumnChartComponent.XAXIS
  );

  if (!XAxisColumn) {
    return (
      <div className="visualization-error">
        No column found with {ColumnChartComponent.XAXIS} 'chartComponent'
        property configured.
      </div>
    );
  }
  const lines = visualization.representation.columns?.filter(
    (column) => column.chartComponent === ColumnChartComponent.LINE
  );
  if (!lines || lines.length === 0) {
    return (
      <div className="visualization-error">
        At least one column must be configured with the{" "}
        {ColumnChartComponent.LINE} 'chartComponent' property.
      </div>
    );
  }
  const bars = visualization.representation.columns?.filter(
    (column) => column.chartComponent === ColumnChartComponent.BAR
  );

  if (!bars || bars.length === 0) {
    return (
      <div className="visualization-error">
        At least one bar must be configured with the {ColumnChartComponent.BAR}{" "}
        'chartComponent' property.
      </div>
    );
  }

  useEffect(() => {
    if(rtcoData?.length){
      setData(rtcoData)
      setLoading(false)
    }
  }, [rtcoData])

  useEffect(() => {
    if(!dashboardHasDataForAll){
      let mounted = true;
      if (visualization.loadingStrategy === DataLoadingStrategy.LAZY && visible) {
        setLoading(true);
        Api.visualizations()
          .byId(
            dashboardId,
            visualization.id,
            {
              parentAccountId: accountSelection.parentAccountId,
              selectedAccountIds: accountSelection.accounts.map(
                (account) => account.id
              ),
              hierarchyType: accountSelection.hierarchyType,
              arrayType: selectedArraysEnvironment,
            },
            {
              dashboardName: currentDashboardName,
              visualizationName: visualization.name,
            }
          )
          .then((response) => {
            if (mounted) {
              setData(Utils.formatData(visualization, response));
            }
          })
          .finally(() => {
            if (mounted) {
              setLoading(false);
            }
          });
      } else {
        setData(Utils.formatData(visualization, visualization.data as any[]));
        setLoading(false);
      }
      return () => {
        mounted = false;
      };
    }
  }, [
    accountSelection,
    visualization,
    dashboardId,
    dashboardHasDataForAll,
    selectedArraysEnvironment,
    currentDashboardName,
    visible,
  ]);

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility active={!visualization.groupBy} >
      <div className="composed-chart">
        {loading && <CircularProgress size={20} />}
        {!loading && (
          <>
            {data && data.length > 0 ? (
              <ResponsiveContainer width="98%" height={height}>
                <ComposedChart
                  data={data}
                  margin={{
                    top: 10,
                    right: 15,
                    left: 10,
                    bottom: visualization.representation.formatting?.XAxisAngle
                      ? 110
                      : 20,
                  }}
                >
                  <XAxis
                    dataKey={XAxisColumn.name}
                    angle={visualization.representation.formatting?.XAxisAngle}
                    textAnchor={
                      visualization.representation.formatting?.XAxisAngle
                        ? "end"
                        : "middle"
                    }
                    interval={0}
                    type={"category"}
                    tick={
                      !visualization.representation.formatting?.XAxisAngle ? (
                        <CustomizedAxisTick />
                      ) : (
                        true
                      )
                    }
                  />
                  <YAxis minTickGap={40} yAxisId="right" orientation="right">
                  </YAxis>
                  <YAxis yAxisId="left" orientation='left'/>
                  <Tooltip />
                  <Legend verticalAlign={"top"} iconType="circle" />
                  {bars.map((bar, i) => (
                    <Bar 
                      yAxisId="right"
                      dataKey={bar.name} 
                      barSize={20} 
                      fill={colors[i % colors.length]}
                      stackId="a"
                      shape={(props: ExtendedRectangleProps) => {
                        return <Rectangle {...props} />;
                      }}
                    />
                  ))}
                  {lines.map((line, i) => (
                    <Line 
                      yAxisId="left"
                      key={line.name}
                      type="monotone" 
                      dataKey={line.name} 
                      name={line.label}
                      stroke={"#413ea0"}
                      strokeWidth={3}
                      activeDot={{ r: 8 }}
                    />
                  ))}
                </ComposedChart>
              </ResponsiveContainer>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </VisibilitySensor>
  );
};
