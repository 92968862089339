import React from "react";
import { Redirect, useParams } from "react-router-dom";
import { Paths } from "../common/Paths";
import System from "../common/System";
import Config from "../config/Config";
import { AccountHierarchyType } from "@shared/interfaces";

/*
This page will be the entry point from SFDC
 */
export default () => {
  const { id } = useParams();
  System.updateAccountSelection({
    parentAccountId: id,
    accounts: [],
    hierarchyType: AccountHierarchyType.SFDC,
  });
  return <Redirect to={Paths.dashboard(Config.app.defaultDashboard)} />;
};
