import React, { useEffect, useState } from "react";
import Main from "../components/Main";
import { CircularProgress } from "@material-ui/core";
import { useParams } from "react-router-dom";
import Api from "../services/Api";
import {
  AccountHierarchyType,
  SubType,
  Dashboard,
  DefaultLoadRequest,
} from "@shared/interfaces";
import Utils from "../common/Utils";
import sort from "fast-sort";
import "./DashboardPage.scss";
import { StoreState } from "../types/StoreState";
import { useSelector } from "react-redux";
import Visualizations from "../components/Visualizations";
import System from "../common/System";
import { DefaultDashboardViewRequest, ExecutiveDashboardViewRequest } from "@shared/interfaces";

const getDashboardId = (dashboards: Dashboard[], name: string) => {
  const dashboardIndex = dashboards.map(dashboard => {
    if(dashboard.name.includes("Install Base Upgrades")){
      return {
        ...dashboard,
        name: "Install Base Upgrades"
      }
    }else {
      return dashboard
    }
  }).findIndex(
    (dashboard) => Utils.formatDashboardName(dashboard.name) === name
  );
  return dashboards[dashboardIndex].id;
};

export default () => {
  const { accountSelection, executiveAccountSelection, accountSelectionContext, loadingDashboard, lastFeedbacksRefresh, headerDashboard } = useSelector((state: StoreState) => state);
  const { name } = useParams();
  const [activeDashboard, setActiveDashboard] = useState<Dashboard>();
  const [trackView, setTrackView] = useState<DefaultDashboardViewRequest | ExecutiveDashboardViewRequest>();

  const isReadOnly = headerDashboard?.applicationInfo?.isReadOnly

  useEffect(() => {
    let mounted = true;
    System.updateLoadingDashboard(true);
    const load = async () => {
      let executiveDashboardsResponse: Dashboard[] = [];
      System.updateCurrentDashboardName(name);
      let dashboardsResponse
      if(executiveAccountSelection && accountSelectionContext === SubType.EXECUTIVE){
        executiveDashboardsResponse = await Api.executiveDashboards().getDashboards();
        const dashboardId = getDashboardId(executiveDashboardsResponse, name)
        const activeDashboard = executiveDashboardsResponse.find(dashboard => dashboard.id === dashboardId)  
        setActiveDashboard(activeDashboard)  
        System.updateLoadingDashboard(false);
        if(activeDashboard?.auditTrackingEnabled){
          setTrackView({
            dashboardName: `${activeDashboard.name} (Executive)`,
            theaters: executiveAccountSelection.theaters,
          })
        }
        return
      }else{
        dashboardsResponse = await Api.dashboards().all();
      }
      dashboardsResponse = dashboardsResponse.filter(
        (dashboard) => dashboard.name.toLocaleLowerCase() !== "header"
      );
      const sortedDashboards = sort<Dashboard>(dashboardsResponse).asc([
        (d: Dashboard) => d.order,
      ]);
      try {
        let request: DefaultLoadRequest = {
          selectedAccountIds: accountSelection.accounts.map(
            (account) => account.id
          ),
          hierarchyType: AccountHierarchyType.SFDC,
        };
        if (accountSelection.parentAccountId) {
          request.parentAccountId = accountSelection.parentAccountId;
        }
        const dashboardResponse = await Api.dashboards().byIds(
          getDashboardId(sortedDashboards, name),
          request
        );
        if (mounted) {
          if(executiveAccountSelection && accountSelectionContext === SubType.EXECUTIVE){
            const fleetDetailDashboard = executiveDashboardsResponse.find(dashboard => dashboard.name === "Fleet Detail")  
            setActiveDashboard(fleetDetailDashboard)          
          }else{
            setActiveDashboard(dashboardResponse);
          }
        }
        if (dashboardResponse.auditTrackingEnabled) {
          setTrackView({
            dashboardName: dashboardResponse.name,
            selectedAccountIds: accountSelection.accounts.map(
              (account) => account.id
            ),
            hierarchyType: accountSelection.hierarchyType,
          });
        }
      } catch (e) {
      } finally {
        System.updateLoadingDashboard(false);
      }
    };
    load();
    return () => {
      mounted = false;
    };
  }, [name, accountSelection, executiveAccountSelection, accountSelectionContext, lastFeedbacksRefresh]);

  useEffect(() => {
    console.log('inside use effect of dashboard page')
    console.log({ env: process.env.NODE_ENV, trackView, isReadOnly })
    if (process.env.NODE_ENV === "production" && trackView && !isReadOnly) {
      Api.audit()
        .dashboardView(trackView)
        .then(() => {});
    }
  }, [trackView]);

  return (
    <Main noLeftNav>
      <div className="main-content">
        {(!activeDashboard || loadingDashboard) ? (
          <div className="loading-wrp">
            <CircularProgress />
          </div>
        ) : (
          <Visualizations dashboard={activeDashboard} />
        )}
      </div>
    </Main>
  );
};
