import { createTheme } from "@material-ui/core/styles";

const proximaNova = {
  fontFamily: "Proxima Nova",
  fontStyle: "normal",
  // fontDisplay: "swap",
  fontWeight: 400,
  src: `
    url(/mf/pvr/fonts/proxima_nova-webfont.woff2) format('woff2'),
    url(/mf/pvr/fonts/proxima_nova-webfont.woff) format('woff')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

export default createTheme({
  palette: {
    primary: { main: "rgb(254, 80, 0)" },
  },
  typography: {
    fontFamily: "Proxima Nova",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [proximaNova],
      },
    },
    MuiButton: {
      root: {},
    },
    MuiInputBase: {
      input: {},
    },
    MuiDivider: {
      root: {
        marginBottom: "15px",
      },
    },
  },
});
