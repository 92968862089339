import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { Paths } from "../common/Paths";
import System from "../common/System";
import Config from "../config/Config";
import { AccountHierarchyType } from "@shared/interfaces";
import Api from "../services/Api";
import { CircularProgress } from "@material-ui/core";

export default () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api.accounts()
      .byOrgIds([id])
      .then((res) => {
        System.updateAccountSelection({
          accounts: res,
          hierarchyType: AccountHierarchyType.PURE,
        });
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="loading-wrp">
        <CircularProgress />
      </div>
    );
  } else {
    return <Redirect to={Paths.dashboard(Config.app.defaultDashboard)} />;
  }
};
