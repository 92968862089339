import React, { useEffect, useState } from "react";
import {
  SubType,
  DataLoadingStrategy,
  Visualization,
  VisualizationType,
} from "@shared/interfaces";
import FormatValue from "./FormatValue";
import Utils from "../../common/Utils";
import "./Value.scss";
import Api from "../../services/Api";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import { Typography, Tooltip } from "@material-ui/core";
import ArrayEnvironmentSelect from "../ArrayEnvironmentSelect";
import System from "../../common/System";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SelectedAccountsPopover from "../AccountSelector/SelectedAccountsPopover";
import {
  Switch,
  withStyles,
  Grid,
} from "@material-ui/core";

const PureSwitch = withStyles((theme) => ({
  switchBase: {
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: "#000",
      opacity: 0.38,
    },
  },
  checked: {},
  track: {},
}))(Switch);

export default ({
  visualization,
  dashboardId,
}: {
  visualization: Visualization;
  dashboardId: string;
}) => {
  const showName = Utils.showName(visualization);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const {
    loadingHierarchy,
    loadingSearchResults,
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
    accountSelectionContext,
    executiveAccountSelection,
  } = useSelector((state: StoreState) => state);

  const handleChangeMode = () => {
    System.updateAccountSelectionContext(accountSelectionContext === SubType.EXECUTIVE ? SubType.DEFAULT : SubType.EXECUTIVE);
  };

  if (!visualization.representation.columns) {
    return <div className="visualization-error">No columns configured.</div>;
  } else if (visualization.representation.columns.length > 1) {
    return (
      <div className="visualization-error">
        Visualization of type '{VisualizationType.VALUE}' supports only one
        column.
      </div>
    );
  } else if (
    visualization.loadingStrategy !== DataLoadingStrategy.LAZY &&
    (!visualization.data || visualization.data.length === 0)
  ) {
    return <div className="visualization-error">Empty data.</div>;
  }

  useEffect(() => {
    let mounted = true;
    if (
      visualization.loadingStrategy === DataLoadingStrategy.LAZY &&
      (accountSelection.parentAccountId || accountSelection.accounts.length > 0)
    ) {
      if(!(executiveAccountSelection && accountSelectionContext === SubType.DEFAULT)){
        setLoading(true);
        Api.visualizations()
          .byId(
            dashboardId,
            visualization.id,
            {
              parentAccountId: accountSelection.parentAccountId,
              selectedAccountIds: accountSelection.accounts.map(
                (account) => account.id
              ),
              hierarchyType: accountSelection.hierarchyType,
              arrayType: selectedArraysEnvironment,
            },
            {
              dashboardName: currentDashboardName,
              visualizationName: visualization.name,
            }
          )
          .then((response) => {
            if (mounted) {
              setData(response);
            }
          })
          .catch((error) => {
            System.showAlertDialog(
              "Error",
              error.response.data.message || error.message
            );
          })
          .finally(() => {
            if (mounted) {
              setLoading(false);
            }
          });  
      }
    } else {
      setData(visualization.data || []);
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [
    executiveAccountSelection,
    accountSelectionContext,
    accountSelection,
    visualization,
    dashboardId,
    selectedArraysEnvironment,
    currentDashboardName,
  ]);

  const column = visualization.representation.columns[0];

  const handleClick = () => {
    if (
      visualization.showControls &&
      !(loadingHierarchy || loadingSearchResults)
    ) {
      System.updateShowAccountSelector(true);
    }
  };

  const showArrayEnvironmentSelector = () =>
    !loading &&
    visualization.showControls &&
    (accountSelection.parentAccountId || accountSelection.accounts.length > 0);
    const maxParentAccountNameLength = visualization.representation.parentAccountDisplayMaxLength ? visualization.representation.parentAccountDisplayMaxLength : 100;
  return (
    <div
      className={`${visualization.representation.formatting?.cssClass} value-type`}
    >
      
      <div className={`value-content ${loading ? "loading" : ""}`}>
        <div
          style={{
            cursor: visualization.showControls ? "pointer" : "default",
          }}
          onClick={handleClick}
        >
          {showName ? <strong>{visualization.name}: </strong> : executiveAccountSelection ? <Typography variant={"h6"}>Executive Summary Report</Typography> : null}

          {visualization.showControls &&
            (accountSelection.parentAccountId ||
              accountSelection.accounts.length > 0) && (
              <Grid container>
                <div className="hierarchy-selection">
                  {Utils.hierarchyName(accountSelection.hierarchyType)}
                </div>
                <Grid /* component="label" */ container alignItems="center" spacing={1}>
                  <Grid item>Account</Grid>
                  <PureSwitch
                    style={{ fontSize: 16 }}
                    onClick={e => e.stopPropagation()}
                    checked={accountSelectionContext === SubType.EXECUTIVE}
                    onChange={handleChangeMode}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Grid item>Executive</Grid>
                </Grid>
              </Grid>
            )}

          {visualization.showControls &&
          !accountSelection.parentAccountId &&
          accountSelection.accounts.length === 0 ? (
            <>
              <Typography align="left" variant={"h6"}>
                User Filters{" "}
                <ArrowDropDownIcon style={{ verticalAlign: "text-top" }} />
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>Account</Grid>
                  <PureSwitch
                    onClick={e => e.stopPropagation()}
                    checked={accountSelectionContext === SubType.EXECUTIVE}
                    onChange={handleChangeMode}
                    inputProps={{ "aria-label": "primary checkbox" }}
                  />
                  <Grid item>Executive</Grid>
                </Grid>
              </Typography>
            </>
          ) : (
            <>
              <Tooltip  title={ 
                 <React.Fragment>
                  <Typography color="inherit">{data && data[0] && data[0][column.name]}</Typography>
                  </React.Fragment>
                 } placement="top-start" arrow={true} >
                  <div style={{ marginTop: 1, display: 'flex' }}>
                  <FormatValue
                      columnFormat={column.format}
                      decimalScale={column.decimalScale}
                      value={data && data[0] ? (data[0][column.name].length > maxParentAccountNameLength ? data[0][column.name].substring(0, maxParentAccountNameLength) + "..." : data[0][column.name] ): ""}
                    />
                    {visualization.showControls && (
                      <ArrowDropDownIcon
                        style={{ verticalAlign: "sub", fontSize: "30px" }}
                      />
                    )}
                  </div>
           </Tooltip>
           </>
          )}
        </div>
        
      </div>
      {!loading && visualization.showControls && (
        <SelectedAccountsPopover
          accounts={accountSelection.accounts
            .filter(
              (account) => account.id !== accountSelection.parentAccountId
            )
          .map((account) => account.name)}
          maxAccountsToShow = {visualization.representation.maxAccountsToShow ? visualization.representation.maxAccountsToShow : 5}
        />
      )}
      {showArrayEnvironmentSelector() && <ArrayEnvironmentSelect />}
    </div>
  );
};
