import "./ArrrayGenealogy.scss";

import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { DataLoadingStrategy, Visualization } from "@shared/interfaces";
import React, { createRef, useCallback, useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import Api from "../../services/Api";
import Config from "../../config/Config";
import HostnameSelect from "./HostnameSelect";
import NoData from "../NoData";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import sort from "fast-sort";
import { useSelector } from "react-redux";

const computeClass = (model: string) => {
  let className = "model";
  if (model.toLocaleLowerCase().startsWith("fa-4")) {
    className += " FA-4";
  } else if (model.toLocaleLowerCase().startsWith("fa-x9")) {
    className += " X-90";
  } else if (model.toLocaleLowerCase().startsWith("fa-x")) {
    className += " X-SERIES";
  } else if (model.toLocaleLowerCase().startsWith("fa-c6")) {
    className += " C6";
  } else if (model.toLocaleLowerCase().startsWith("fa-m")) {
    className += " M-SERIES";
  } else if (model.toLocaleLowerCase().startsWith("not phoning")) {
    className += " FS";
  } else if (model.toLocaleLowerCase().startsWith("out of")) {
    className += " FA";
  }
  return className;
};

export default ({
  visualization,
  dashboardId,
}: {
  visualization: Visualization;
  dashboardId: string;
}) => {
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
  } = useSelector((state: StoreState) => state);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<any[]>([]);
  const [allRows, setAllRows] = useState<any[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [hostnames, setHostnames] = useState<string[]>([]);
  const [hostnamesLinks, setHostnamesLinks] = useState<{
    [key: string]: string;
  }>({});
  const [retries, setRetries] = useState(0);
  const [visible, setVisible] = useState(false);

  const processItems = useCallback(
    (data: any) => {
      const formatted = Utils.formatData(visualization, data);
      const hostnames: any = {};
      const columnsSet = new Set<string>();
      const arrayLinks: any = {};
      formatted.forEach((item: any) => {
        columnsSet.add(item.quarter_end_date);
        arrayLinks[item.hostname] = `${Config.app.arrayLink}/${item.array_id}`;
        hostnames[item.hostname] = !hostnames[item.hostname]
          ? [item]
          : [...hostnames[item.hostname], item];
      });
      const columns: string[] = Array.from(columnsSet);
      setHostnames(Object.keys(hostnames));
      setHostnamesLinks(arrayLinks);
      const finalRows: any[] = [];
      const sortedHostnamesKeys = sort<any>(Object.keys(hostnames)).asc();
      sortedHostnamesKeys.forEach((hostname: any) => {
        const row = [hostname];
        columns.forEach((column) => {
          let cell = <></>;
          hostnames[hostname].forEach((item: any) => {
            if (item["quarter_end_date"] === column) {
              cell = (
                <div className={computeClass(item.final_model)}>
                  <div>{item.final_model}</div>
                  <div className="capacity">{item.final_capacity} TB</div>
                  <div className="image"></div>
                </div>
              );
            }
          });
          row.push(cell);
        });
        finalRows.push(row);
      });
      setRows(finalRows);
      setAllRows(finalRows);
      setColumns(columns);
    },
    [visualization]
  );

  useEffect(() => {
    let mounted = true;
    if (visualization.loadingStrategy === DataLoadingStrategy.LAZY && visible) {
      if (retries < Config.app.maxVisualizationRetries) {
        setLoading(true);
        Api.visualizations()
          .byId(
            dashboardId,
            visualization.id,
            {
              parentAccountId: accountSelection.parentAccountId,
              selectedAccountIds: accountSelection.accounts.map(
                (account) => account.id
              ),
              hierarchyType: accountSelection.hierarchyType,
              arrayType: selectedArraysEnvironment,
            },
            {
              dashboardName: currentDashboardName,
              visualizationName: visualization.name,
            }
          )
          .catch((error) => {
            if (mounted && error.response.status !== 404) {
              setTimeout(() => {
                setRetries(retries + 1);
              }, Config.app.maxVisualizationRetriesInterval);
            }
          })
          .then((response: any) => {
            if (mounted && response) {
              processItems(response);
            }
          })
          .finally(() => {
            if (mounted) {
              setLoading(false);
            }
          });
      }
    } else {
      setLoading(false);
      processItems(visualization.data || []);
    }
    return () => {
      mounted = false;
    };
  }, [
    accountSelection,
    visualization,
    dashboardId,
    processItems,
    selectedArraysEnvironment,
    currentDashboardName,
    retries,
    visible,
  ]);

  const filterArrays = (selectedHostnames: string[]) => {
    if (selectedHostnames.length === 0) {
      setRows(allRows);
      return;
    } else {
      let filteredArrays: any[] = [];
      selectedHostnames.forEach((selectedHostname) => {
        allRows.forEach((row) => {
          if (row[0] === selectedHostname) {
            filteredArrays.push(row);
          }
        });
      });
      setRows(filteredArrays);
    }
  };

  const scrollbarOuter = createRef<HTMLDivElement>();
  const tableRef = createRef<HTMLTableElement>();
  const containerRef = createRef<HTMLDivElement>();
  const [width, setwidth] = useState(0);

  useEffect(() => {
    const tableElement = tableRef.current;
    const onResize = () => {
      if (tableElement) setwidth(tableElement.offsetWidth);
    };
    if (tableElement) {
      setwidth(tableElement.offsetWidth);
      tableElement.addEventListener("resize", onResize);
    }
    return () => {
      tableElement?.removeEventListener("resize", onResize);
    };
  }, [tableRef]);

  useEffect(() => {
    const tableElement = containerRef.current;
    const scrollbarElement = scrollbarOuter.current;
    const onTableScroll = () => {
      if (tableElement && scrollbarElement) {
        scrollbarElement.scrollLeft = tableElement.scrollLeft;
      }
    };
    const onScrollbarScroll = () => {
      if (tableElement && scrollbarElement) {
        tableElement.scrollLeft = scrollbarElement.scrollLeft;
      }
    };

    if (tableElement && scrollbarElement) {
      tableElement.addEventListener("scroll", onTableScroll);
      scrollbarElement.addEventListener("scroll", onScrollbarScroll);
    }
    return () => {
      tableElement?.removeEventListener("scroll", onTableScroll);
      scrollbarElement?.removeEventListener("scroll", onScrollbarScroll);
    };
  }, [containerRef, scrollbarOuter]);

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>
      <div className="array-genealogy">
        {loading && (
          <div className="circular-progress">
            <CircularProgress size={20} />
          </div>
        )}
        {!loading && (
          <>
            <Grid container>
              <Grid item xs={10}></Grid>
              <Grid item xs={2}>
                <HostnameSelect
                  hostnames={hostnames}
                  selectionChanged={filterArrays}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  className="custom-scrollbar"
                  style={{
                    overflowX: "auto",
                    overflowY: "hidden",
                    zIndex: 100,
                  }}
                  ref={scrollbarOuter}
                >
                  <div style={{ width: width, zIndex: 9 }}>&nbsp;</div>
                </div>
                <TableContainer ref={containerRef}>
                  <Table size={"small"} stickyHeader ref={tableRef}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div>Hostname</div>
                        </TableCell>
                        {columns.map((column) => (
                          <TableCell key={column}>{column}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.length > 0 ? (
                        rows.map((row, i) => (
                          <TableRow key={i}>
                            {row.map((item: any, j: number) => (
                              <TableCell key={j}>
                                {j === 0 ? (
                                  <a
                                    className="value-link"
                                    href={hostnamesLinks[item]}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item}
                                  </a>
                                ) : (
                                  item
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      ) : (
                        <div className="empty-data">
                          <NoData />
                        </div>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </VisibilitySensor>
  );
};
