import "./AccountSelector.scss";
import {
  ButtonGroup,
  CircularProgress,
  Grid,
  TextField,
  Switch,
  Typography,
  withStyles,
  Select,
  MenuItem,
  Box,
  Snackbar,
  DialogContentText,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import {
  AccountHierarchyType,
  PureAccount,
  SfdcAccount,
  SubType,
} from "@shared/interfaces";
import React, { ChangeEvent, useEffect, useState } from "react";
import { AccountSelection } from "../../types/AccountSelection";
import Api from "../../services/Api";
import Autocomplete from "./Autocomplete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MaterialTable from "material-table";
import { StoreState } from "../../types/StoreState";
import System from "../../common/System";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import Utils from "../../common/Utils";
import { AccountSelectorTarget } from "../../enums/AccountSelectorTarget";
import { SfdcAccountRow } from "../../interfaces/SfdcAccountRow";
import { GenericAccountRow } from "../../interfaces/GenericAccountRow";
import { PureAccountRow } from "../../interfaces/PureAccountRow";
import { SnackbarSeverity } from "../../enums/SnackbarSeverity";
import Link from "@material-ui/core/Link";
import ExecutiveAggregationsAccountSelector from "./ExecutiveAggregationsAccountSelector";
import { Paths } from "../../common/Paths";
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import ReadOnlyTooltip from "../ReadOnlyTooltip";


export interface Preference {
  id: number;
  name: string;
  isExecutive: number;
  val: unknown;
  /**
   * An UTC time stamp numeric value for when this specific preference was created
   */
  createdAt: number;
  /**
   * An UTC time stamp numeric value for when this specific preference was updated
   */
  updatedAt: number;
}

const Alert = (props: any) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getSelectedRows = (data: GenericAccountRow[]) => {
  return data.filter((row) => row.tableData.checked);
};

export default ({
  selectAccounts,
  sfdcHierarchy,
}: {
  selectAccounts: (newSelection: AccountSelection) => void;
  sfdcHierarchy: SfdcAccount[];
}) => {
  const {
    loadingHierarchy,
    loadingSearchResults,
    accountSelection,
    showAccountSelector,
    accountSelectionContext,
    accountSelectorTarget,
    accountSelectionForFilters,
    accountSelectionSelectedHierarchyForFilters,
    headerDashboard
  } = useSelector((state: StoreState) => state);
  const [loading, setLoading] = useState(true);
  const [selectionChanged, setSelectionChanged] = useState(false);
  const [sfdcData, setSfdcData] = useState<SfdcAccountRow[]>([]);
  const [pureData, setPureData] = useState<PureAccountRow[]>([]);
  const [filterData, setFilterData] = useState<
    SfdcAccountRow[] | PureAccountRow[]
  >([]);
  const [pureSearchText, setPureSearchText] = useState<string>("");
  const [activeHierarchy, setActiveHierarchy] = useState<AccountHierarchyType>(
    accountSelection.hierarchyType
      ? accountSelection.hierarchyType
      : AccountHierarchyType.SFDC
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [validatingPureSelection, setValidatingPureSelection] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [executiveScreenLoading, setExecutiveScreenLoading] = useState(false);
  const [executiveRollupRequest, setExecutiveRollupRequest] = useState();
  const [executiveRollupPreferences, setExecutiveRollupPreferences] = useState();
  const [isExecutiveReset, setIsExecutiveReset] = useState(false)
  const [executivePreferences, setExecutivePreferences] = useState<Preference[]>([])
  const [currentPreference, setCurrentPreference] = useState<Preference | undefined>()
  const [showPreferenceWarning, setShowPreferenceWarning] = useState<boolean>(false)
  const [showCreatePreferenceModal, setShowCreatePreferenceModal] = useState<boolean>(false)
  const [preferenceName, setPreferenceName] = useState<string>('')

  const isReadOnly = headerDashboard?.applicationInfo?.isReadOnly
  const history = useHistory()
  const location = useLocation()
  const handleClose = () => {
    System.updateShowAccountSelector(false);
  };

  const onAddPreference = () => {
    if(executiveRollupPreferences === undefined){
      setShowPreferenceWarning(true)
    }else{
      setShowCreatePreferenceModal(true)
    }
  }

  const onDeletePreference = async () => {
    try {
      if(currentPreference?.id){
        setLoading(true)
        await Api.executiveAccounts().deletePreference(currentPreference?.id)
        const response: Preference[] = await Api.executiveAccounts().getPreferences()
        if(response.length){
          const sortedPreferences = response.sort((a,b) => b.updatedAt - a.updatedAt)
          setCurrentPreference({ ...sortedPreferences[0], val: JSON.parse(sortedPreferences[0].val as string)})
          setExecutivePreferences(sortedPreferences.map((preference) => {
            if(preference.name === currentPreference?.name){
              return {
                ...preference,
                val: JSON.parse(preference.val as string),
              }
            }else{
              return {
                ...preference,
                val: JSON.parse(preference.val as string)
              }
            }
          }))
          setExecutiveRollupPreferences(JSON.parse(sortedPreferences[0].val as string))
          setLoading(false)
        }else{
          setCurrentPreference(undefined)
          setExecutivePreferences([])
          setExecutiveRollupPreferences(undefined)
          setLoading(false)
        }
      }
    }catch(err){
      setLoading(false)
      console.error(err)
    }
  }

  const onUpdatePreference = async () => {
    try{
      setLoading(true)
      const response: Preference = await Api.executiveAccounts().updatePreference({
        name: currentPreference?.name,
        val: executiveRollupPreferences,
      }, currentPreference !== undefined ? currentPreference?.id?.toString() : '')
      setCurrentPreference({ ...response, val: JSON.parse(response.val as string)})
      const updatedPreferences = await Api.executiveAccounts().getPreferences()
      const newExecutivePreferences = updatedPreferences
        .map((preference) => {
          if(preference.name === currentPreference?.name){
            return {
              ...preference,
              val: JSON.parse(preference.val),
              updatedAt: response.updatedAt,
            }
          }else{
            return {
              ...preference,
              val: JSON.parse(preference.val)
            }
          }
        })
        .sort((a,b) => b.updatedAt - a.updatedAt)
      setExecutivePreferences(newExecutivePreferences)
      setExecutiveRollupPreferences(JSON.parse(response.val as string))
      setLoading(false)
    }catch(err){
      setLoading(false)
      console.error(err)
    }
  }

  useEffect(() => {
    if(executivePreferences.length){
      setCurrentPreference(executivePreferences.sort((a, b) => b.updatedAt - a.updatedAt)[0])
    }
  }, [executivePreferences])

  const handleCreatePreferenceModalClose = () => {
    setShowCreatePreferenceModal(false);
  };

  const handleCreatePreference = async () => {
    try {
      setLoading(true)
      const response: Preference = await Api.executiveAccounts().createPreference({
        name: preferenceName,
        val: executiveRollupPreferences,
      })
      const updatedPreferences = await Api.executiveAccounts().getPreferences()
      setCurrentPreference({ ...response, val: JSON.parse(response.val as string)})
      setExecutivePreferences(updatedPreferences.map((preference) => ({ ...preference, val: JSON.parse(preference.val)})).sort((a,b) => b.updatedAt - a.updatedAt))
      setExecutiveRollupPreferences(JSON.parse(response.val as string))
      setShowCreatePreferenceModal(false);
      setLoading(false)
    }
    catch(err){
      console.error(err)
    }
  }

  const handleConfirm = async () => {
    if(accountSelectionContext === SubType.EXECUTIVE){
      if(!executiveRollupRequest){
        System.openSnackbar(
          "Please select at least one theater",
          SnackbarSeverity.INFO
        );
      }else{
        selectAccounts({ parentAccountId: undefined, accounts: [], hierarchyType: AccountHierarchyType.SFDC})
        System.updateExecutiveAccountSelection({theaters: executiveRollupRequest})
        System.updateLastFeedbacksRefresh();
        handleClose();
        history.push(Paths.dashboard('fleet-detail'));
      }
    }else{
      if (accountSelectorTarget === AccountSelectorTarget.APP) {
        if (activeHierarchy === AccountHierarchyType.SFDC) {
          const selectedRows = getSelectedRows(sfdcData) as SfdcAccountRow[];
          if (selectedRows.length > 0) {
            // Before selecting the parentId, check if the has access to it
            let parentId: string;
            if (selectedRows[0].parentId) {
              parentId = selectedRows[0].parentId;
              const parentAccount = sfdcData.find(
                (sfdcAccount) => sfdcAccount.id === parentId
              );
              if (parentAccount && parentAccount.hasAccess) {
                parentId = parentAccount.id;
              }
            } else {
              parentId = selectedRows[0].id;
            }
    
            selectAccounts({
              parentAccountId: parentId,
              accounts: selectedRows,
              hierarchyType: AccountHierarchyType.SFDC,
            });
            handleClose();
          } else {
            System.openSnackbar(
              "Please select at least one account",
              SnackbarSeverity.INFO
            );
          }
        } else if (activeHierarchy === AccountHierarchyType.PURE) {
          const selectedRows = getSelectedRows(pureData) as PureAccountRow[];
          if (selectedRows.length > 0) {
            System.updateLoadingHierarchy(true);
            let sfdcAccountIds: string[] = [];
            selectedRows.forEach((pureAccount) => {
              if (pureAccount.accounts) {
                sfdcAccountIds = [...sfdcAccountIds, ...pureAccount.accounts];
              }
            });
            if (selectedRows.length > 1) {
              // Check that selected pure orgs belong to the same parent
              setValidatingPureSelection(true);
              Api.accounts()
                .bySfdcIds({ accountIds: sfdcAccountIds })
                .then(
                  (response: {
                    status: string;
                    message?: string;
                    data: SfdcAccount[];
                  }) => {
                    if (response.status === "success") {
                      selectAccounts({
                        accounts: selectedRows,
                        hierarchyType: AccountHierarchyType.PURE,
                      });
                      handleClose();
                    } else {
                      System.showAlertDialog(
                        "Error",
                        response.message ||
                          "An error occurred while trying to retrieve the SFDC hierarchy"
                      );
                    }
                  }
                )
                .finally(() => {
                  System.updateLoadingHierarchy(false);
                  setValidatingPureSelection(false);
                });
            } else {
              selectAccounts({
                accounts: selectedRows,
                hierarchyType: AccountHierarchyType.PURE,
              });
              handleClose();
              System.updateLoadingHierarchy(false);
            }
          } else {
            System.openSnackbar(
              "Please select at least one account",
              SnackbarSeverity.INFO
            );
          }
        }
      } else if (accountSelectorTarget === AccountSelectorTarget.FEEDBACK) {
        if (activeHierarchy === AccountHierarchyType.SFDC) {
          const selectedRows = getSelectedRows(sfdcData) as SfdcAccountRow[];
          setFilterData(sfdcData);
          System.updateAccountSelectionForFilters(
            selectedRows.map((account: any) => {
              let copy: any = { ...account };
              delete account.tableData;
              return copy;
            }),
            activeHierarchy
          );
        } else if (activeHierarchy === AccountHierarchyType.PURE) {
          const selectedRows = getSelectedRows(pureData) as PureAccountRow[];
          setFilterData(pureData);
          System.updateAccountSelectionForFilters(
            selectedRows.map((account: any) => {
              let copy: any = { ...account };
              delete account.tableData;
              return copy;
            }),
            activeHierarchy
          );
        }
        System.updateLastFeedbacksRefresh();
        handleClose();
      }
      System.updateExecutiveAccountSelection(null)
      history.push(Paths.dashboard('customer-facing'));
    }
  };

  useEffect(() => {
    if (activeHierarchy === AccountHierarchyType.SFDC) {
      setSelectedCount(getSelectedRows(sfdcData).length);
    } else {
      setSelectedCount(getSelectedRows(pureData).length);
    }
  }, [sfdcData, pureData, activeHierarchy]);

  useEffect(() => {
    if (showAccountSelector) {
      setAllSelected(false);
      setLoading(true);
      setPureSearchText("");
      const flat = Utils.flattenHierarchy(sfdcHierarchy, accountSelection);
      if (accountSelectorTarget === AccountSelectorTarget.APP) {
        setActiveHierarchy(
          accountSelection.hierarchyType
            ? accountSelection.hierarchyType
            : AccountHierarchyType.SFDC
        );
        setSfdcData(flat);
        const selectedRows = getSelectedRows(flat) as SfdcAccountRow[];
        if (accountSelection.hierarchyType === AccountHierarchyType.PURE) {
          if (selectedRows.length > 0) {
            const pureAccounts: PureAccountRow[] = Utils.pureSumOrgsAndSelect(
              flat,
              selectedRows,
              accountSelection.accounts as PureAccount[]
            );
            setPureData(pureAccounts);
          } else if (accountSelection.accounts.length > 0) {
            let accounts = [...accountSelection.accounts] as PureAccountRow[];
            // For some weird reason, tableData is read only and material table complains about it. So we make a copy.
            setPureData(
              accounts.map((a: any) => {
                let { tableData, ...copy } = a;
                copy.tableData = { checked: true };
                return copy;
              })
            );
          }
        } else {
          setSelectionChanged(true);
        }
      } else if (accountSelectorTarget === AccountSelectorTarget.FEEDBACK) {
        if (!accountSelectionSelectedHierarchyForFilters) {
          setActiveHierarchy(AccountHierarchyType.SFDC);
          setSfdcData([]);
        } else if (
          accountSelectionSelectedHierarchyForFilters ===
          AccountHierarchyType.SFDC
        ) {
          setActiveHierarchy(AccountHierarchyType.SFDC);
          if (accountSelectionForFilters.length > 0) {
            filterData.forEach((account: any) => {
              if (accountSelectionForFilters.find((a) => a.id === account.id)) {
                account.tableData = { checked: true };
              }
            });
            setSfdcData(filterData as SfdcAccountRow[]);
          } else {
            setSfdcData([]);
          }
        } else if (
          accountSelectionSelectedHierarchyForFilters ===
          AccountHierarchyType.PURE
        ) {
          setActiveHierarchy(AccountHierarchyType.PURE);
          if (accountSelectionForFilters.length > 0) {
            filterData.forEach((account: any) => {
              if (accountSelectionForFilters.find((a) => a.id === account.id)) {
                account.tableData = { checked: true };
              }
            });
            setPureData(filterData as PureAccountRow[]);
          } else {
            setPureData([]);
          }
        }
      }
      setSelectionChanged(true);
      setLoading(false);
    }
  }, [
    showAccountSelector,
    accountSelection,
    sfdcHierarchy,
    accountSelectorTarget,
    filterData,
    accountSelectionForFilters,
  ]);

  const selectAccountFromAutocomplete = (
    account: SfdcAccount,
    callback: Function
  ) => {
    System.updateLoadingHierarchy(true);
    Api.accounts()
      .bySfdcId(account.id)
      .then((accountsResponse) => {
        let flat;
        if (accountSelectorTarget === AccountSelectorTarget.APP) {
          flat = Utils.flattenHierarchy(accountsResponse, accountSelection);
          setPureData([]);
        } else {
          flat = Utils.flattenHierarchy(accountsResponse);
        }
        setSfdcData(flat);
        System.updateLoadingHierarchy(false);
      })
      .catch((error) => {
        System.updateLoadingHierarchy(false);
      })
      .finally(() => {
        callback();
      });
  };

  const handleSfdcHierarchyClick = () => {
    if (accountSelectorTarget === AccountSelectorTarget.FEEDBACK) {
      return;
    }
    if (selectionChanged) {
      System.updateLoadingHierarchy(true);
      const selectedRows = getSelectedRows(pureData) as PureAccountRow[];
      let pureAccountIds: string[] = [];
      selectedRows.forEach((pureAccount) => {
        if (pureAccount.accounts && pureAccount.accounts.length > 0) {
          pureAccountIds = [...pureAccountIds, ...pureAccount.accounts];
        }
      });
      Api.accounts()
        .bySfdcIds({ accountIds: pureAccountIds })
        .then(
          (response: {
            status: string;
            message?: string;
            data: SfdcAccount[];
          }) => {
            if (response.status === "success") {
              const flat = Utils.flattenHierarchy(
                response.data,
                accountSelection
              );
              for (let i = 0; i < flat.length; i++) {
                if (pureAccountIds.find((acc) => acc === flat[i].id)) {
                  flat[i].tableData = { checked: true };
                }
              }
              setSfdcData(flat);
            } else {
              System.updateLoadingHierarchy(false);
              System.showAlertDialog(
                "Error",
                response.message ||
                  "An error occurred while trying to retrieve the SFDC hierarchy"
              );
              setSfdcData([]);
            }
          }
        )
        .catch((error: any) => {
          System.updateLoadingHierarchy(false);
          setSfdcData([]);
        })
        .finally(() => {
          setActiveHierarchy(AccountHierarchyType.SFDC);
          setSelectionChanged(false);
          System.updateLoadingHierarchy(false);
        });
    } else {
      setActiveHierarchy(AccountHierarchyType.SFDC);
    }
  };

  const handlePureHierarchyClick = () => {
    setActiveHierarchy(AccountHierarchyType.PURE);
    setPureSearchText("");
    if (selectionChanged) {
      setSelectionChanged(false);
      const selectedRows = getSelectedRows(sfdcData) as SfdcAccountRow[];
      if (selectedRows.length > 0) {
        System.updateLoadingHierarchy(true);
        const pureAccounts: PureAccountRow[] = Utils.pureSumOrgsAndSelect(
          sfdcData,
          selectedRows
        );
        // Get right array count from the API. That's because some orgs belong to multiple SFDC accounts which
        // may not be present in selected rows
        Api.accounts()
          .byOrgIds(pureAccounts.map((org) => org.id))
          .then((res) => {
            const selectedPureAccounts = (res as PureAccountRow[]).map(
              (org) => {
                return { ...org, tableData: { checked: true } };
              }
            );
            setPureData(selectedPureAccounts);
          })
          .finally(() => {
            System.updateLoadingHierarchy(false);
          });
      } else {
        setPureData([]);
      }
    }
  };

  const handlePureSearchChange = (event: any) => {
    setPureSearchText(event.target.value);
  };

  const searchPureHierarchy = () => {
    System.updateLoadingHierarchy(true);
    setPureSearchText(pureSearchText.trim());
    Api.accounts()
      .pureSearch({ name: pureSearchText.trim() })
      .then((response) => {
        setPureData(
          response.map((account) => {
            const pureAccount: PureAccountRow = {
              ...account,
              tableData: { checked: false },
            };
            return pureAccount;
          })
        );
        System.updateLoadingHierarchy(true);
      })
      .finally(() => {
        System.updateLoadingHierarchy(false);
      });
  };

  const handleChangePureSelection = (rows: PureAccountRow[]) => {
    setSelectedCount(getSelectedRows(pureData).length);
    setSelectionChanged(true);
  };

  const handlePreferenceWarningClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowPreferenceWarning(false);
  };

  const onChangePreference = (event: any) => {
    const preferenceObj = executivePreferences.find((pref) => pref.id === event.target.value)
    if(preferenceObj && preferenceObj.val){
      setCurrentPreference(preferenceObj)
      // setExecutiveRollupPreferences(preferenceObj.val as any)
    }
  } 

  const handleChangeSfdcSelection = (rows: SfdcAccount[]) => {
    const selected = sfdcData.map((account) => {
      if (!account.hasAccess) {
        account.tableData.checked = false;
      }
      return account;
    });
    setSfdcData(selected);
    setSelectedCount(getSelectedRows(selected).length);
    setSelectionChanged(true);
    setAllSelected(false);
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      searchPureHierarchy();
    }
  };

  const handleSelectParentOnly = (rowData: SfdcAccountRow) => {
    const selected = sfdcData.map((account) => {
      if (account.id === rowData.id) {
        account.tableData.checked = true;
      }
      return account;
    });
    setSfdcData(selected);
    setSelectedCount(getSelectedRows(selected).length);
    setSelectionChanged(true);
    setAllSelected(false);
  };

  const handleSelectAllClick = () => {
    let selected: SfdcAccountRow[];
    if (allSelected) {
      selected = sfdcData.map((account) => {
        account.tableData.checked = false;
        return account;
      });
      setAllSelected(false);
    } else {
      selected = sfdcData.map((account) => {
        if (account.hasAccess) {
          account.tableData.checked = true;
        }
        return account;
      });
      setAllSelected(true);
    }
    setSfdcData(selected);
    setSelectedCount(getSelectedRows(selected).length);
    setSelectionChanged(true);
  };

  const PureSwitch = withStyles((theme) => ({
    switchBase: {
      color: theme.palette.primary.main,
      "&$checked": {
        color: theme.palette.primary.main,
      },
      "&$checked + $track": {
        backgroundColor: "#000",
        opacity: 0.38,
      },
    },
    checked: {},
    track: {},
  }))(Switch);

  return showAccountSelector ? (
    <Dialog
      open={showAccountSelector}
      onClose={handleClose}
      scroll="paper"
      maxWidth={!(accountSelectionContext === SubType.EXECUTIVE) ? "sm" : "lg"}
      fullWidth
      disableBackdropClick
      className="account-selector"
    >
      <DialogTitle
        id="search-accounts"
        disableTypography
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">User Filters</Typography>
        {accountSelectionContext === SubType.EXECUTIVE && (
          <Box display="flex">
            {!!executivePreferences.length && <Select
              id="preference-dropdown"
              placeholder="preference"
              value={currentPreference ? currentPreference.id : executivePreferences[0].id}
              onChange={(e) => onChangePreference(e)}
            >
              {executivePreferences.map((preference: Preference) => (
                <MenuItem value={preference.id}>{preference.name}</MenuItem>
              ))}
            </Select>}
            <Box display="flex" alignItems="center" justifyContent="center">
              {
                loading ? <Box display="flex" alignItems="center" justifyContent="center" width="134px"><CircularProgress size={20} /></Box> :
                <>
                  <Box onClick={isReadOnly ? void 0 : onAddPreference} display="flex" alignItems="center" marginLeft="20px" marginRight="10px">
                    <ReadOnlyTooltip activate={isReadOnly} message={isReadOnly ? void 0 : "Create a new preference"}>
                      <AddIcon />
                    </ReadOnlyTooltip>
                  </Box>
                  {
                    !!executivePreferences.length && <>
                      <Box onClick={isReadOnly ? void 0 : onUpdatePreference} display="flex" alignItems="center" marginLeft="10px" marginRight="10px">
                        <ReadOnlyTooltip activate={isReadOnly} message={isReadOnly ? void 0 : "Update selected preference"}>
                          <SaveIcon fontSize="small" />
                        </ReadOnlyTooltip>
                      </Box>
                      <Box onClick={isReadOnly ? void 0 : onDeletePreference} display="flex" alignItems="center" marginLeft="10px" marginRight="10px">
                        <ReadOnlyTooltip activate={isReadOnly} message={isReadOnly ? void 0 : "Delete selected preference"}>
                          <DeleteIcon fontSize="small" />
                        </ReadOnlyTooltip>
                      </Box>
                    </>
                  }
                  
                </>
              }
            </Box>
            <Button
              color="primary"
              variant="text"
              disableElevation
              onClick={() => setIsExecutiveReset(true)}
              disabled={false}
            >
              RESET
            </Button>
            <Snackbar open={showPreferenceWarning} autoHideDuration={6000} onClose={handlePreferenceWarningClose}>
              <Alert onClose={handlePreferenceWarningClose} severity="warning">
                Please make a selection first
              </Alert>
            </Snackbar>
            <Dialog open={showCreatePreferenceModal} onClose={handleCreatePreferenceModalClose}>
              {!loading && <DialogTitle id="create-preference">Create Preference</DialogTitle>}
              <DialogContent>
                {
                loading ? <Grid container direction="column" alignItems="center">
                  <CircularProgress />
                  <div>Creating Preference...</div>
                </Grid> : 
                <>
                  <DialogContentText>
                    Please provide name of the preference
                  </DialogContentText>
                  <TextField
                    value={preferenceName}
                    onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setPreferenceName(event.target.value)}
                    autoFocus
                    margin="dense"
                    id="preferenceName"
                    label="Preference name"
                    fullWidth
                  />
                </>
                }
              </DialogContent>
              {
                !loading && 
                <DialogActions>
                  <Button onClick={handleCreatePreferenceModalClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleCreatePreference} color="primary">
                    Create
                  </Button>
                </DialogActions>
              }
            </Dialog>
          </Box>
          )
        }
      </DialogTitle>
      <DialogContent dividers style={{ minHeight: "400px" }}>
        {!(accountSelectionContext === SubType.EXECUTIVE) ? (
          loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={2}>
              <Grid item md={12}>
                <ButtonGroup
                  color="primary"
                  disableElevation
                  className="account-hierarchy"
                >
                  <Button
                    color="primary"
                    variant={
                      activeHierarchy === AccountHierarchyType.SFDC
                        ? "contained"
                        : "text"
                    }
                    disableElevation
                    onClick={handleSfdcHierarchyClick}
                    disabled={loadingSearchResults || loadingHierarchy}
                  >
                    Salesforce Hierarchy
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      activeHierarchy === AccountHierarchyType.PURE
                        ? "contained"
                        : "text"
                    }
                    disableElevation
                    onClick={() => handlePureHierarchyClick()}
                    disabled={loadingSearchResults || loadingHierarchy}
                  >
                    Pure1 Org
                  </Button>
                </ButtonGroup>
              </Grid>
              {activeHierarchy === AccountHierarchyType.SFDC ? (
                <>
                  <Grid item md={12}>
                    <Autocomplete
                      changeAccount={selectAccountFromAutocomplete}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item md={10}>
                    <TextField
                      label="Search Organizations"
                      variant="outlined"
                      onChange={handlePureSearchChange}
                      value={pureSearchText}
                      fullWidth
                      size={"small"}
                      onKeyPress={handleKeyPress}
                      disabled={loadingHierarchy}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={searchPureHierarchy}
                      disableElevation
                      className="search-btn"
                      fullWidth
                      disabled={loadingHierarchy || pureSearchText === ""}
                    >
                      {loadingHierarchy && <CircularProgress size={24} />}
                      {!loadingHierarchy && "Search"}
                    </Button>
                  </Grid>
                </>
              )}
              <Grid item md={12} className="table-wrp">
                {(loadingHierarchy || loadingSearchResults) && (
                  <div className="overlay"></div>
                )}

                {activeHierarchy === AccountHierarchyType.SFDC && (
                  <>
                    <div className="select-all">
                      {sfdcData.length > 0 && (
                        <Link onClick={handleSelectAllClick}>
                          {allSelected ? "Unselect All" : "Select All"}
                        </Link>
                      )}
                    </div>
                    <MaterialTable
                      components={{
                        Container: (props) => <div {...props}></div>,
                      }}
                      data={sfdcData}
                      columns={[
                        {
                          title: "Account",
                          field: "name",
                          render: (rowData: SfdcAccountRow) => (
                            <>
                              {rowData.childrenCount > 0 && (
                                <>
                                  {rowData.name}
                                  {rowData.hasAccess && (
                                    <>
                                      {"  "}
                                      <Link
                                        onClick={() =>
                                          handleSelectParentOnly(rowData)
                                        }
                                        className="parent-selection"
                                      >
                                        parent only
                                      </Link>
                                    </>
                                  )}
                                </>
                              )}
                              {rowData.childrenCount === 0 && rowData.name}
                            </>
                          ),
                        },
                        {
                          title: "Arrays",
                          field: "arrayCount",
                          defaultSort: "desc",
                          align: "right",
                          width: "100px",
                        },
                      ]}
                      parentChildData={(row, rows) =>
                        rows.find((a) => a.id === row.parentId)
                      }
                      options={{
                        selection: true,
                        defaultExpanded: true,
                        showSelectAllCheckbox: false,
                        showTitle: false,
                        search: false,
                        showTextRowsSelected: false,
                        toolbar: false,
                        paging: false,
                        selectionProps: (rowData: any) => ({
                          disabled: !rowData.hasAccess,
                        }),
                      }}
                      onSelectionChange={(rows) =>
                        handleChangeSfdcSelection(rows)
                      }
                    />
                  </>
                )}
                {activeHierarchy === AccountHierarchyType.PURE && (
                  <MaterialTable
                    components={{
                      Container: (props) => <div {...props}></div>,
                    }}
                    data={pureData}
                    columns={[
                      { title: "Organization", field: "name" },
                      {
                        title: "Arrays",
                        field: "arrayCount",
                        defaultSort: "desc",
                        align: "right",
                        width: "100px",
                      },
                    ]}
                    options={{
                      selection: true,
                      defaultExpanded: true,
                      showSelectAllCheckbox: false,
                      showTitle: false,
                      search: false,
                      showTextRowsSelected: false,
                      toolbar: false,
                      paging: false,
                    }}
                    onSelectionChange={(rows) =>
                      handleChangePureSelection(rows)
                    }
                  />
                )}
              </Grid>
            </Grid>
          )
        ) : (
          <Grid 
            container
            direction="row"
            justifyContent={executiveScreenLoading ? "center" : "flex-start"}
            alignItems={executiveScreenLoading ? "center" : "flex-start"}
            style={{ height: "100%"}}
          >
            <ExecutiveAggregationsAccountSelector
              setSelectedCount={setSelectedCount}
              executiveScreenLoading={executiveScreenLoading}
              setExecutiveScreenLoading={setExecutiveScreenLoading}
              setExecutiveRollupRequest={setExecutiveRollupRequest}
              setExecutiveRollupPreferences={setExecutiveRollupPreferences}
              executiveRollupPreferences ={executiveRollupPreferences}
              isExecutiveReset={isExecutiveReset}
              setIsExecutiveReset={setIsExecutiveReset}
              executivePreferences={executivePreferences}
              setExecutivePreferences={setExecutivePreferences}
              currentPreference={currentPreference}
            />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="default"
          disabled={loadingSearchResults || loadingHierarchy}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={
            accountSelectionContext === SubType.EXECUTIVE ? false :
            (loadingSearchResults || loadingHierarchy || selectedCount === 0)
          }
          onClick={handleConfirm}
          color="primary"
          variant="outlined"
        >
          {validatingPureSelection && <CircularProgress size={24} />}
          {!validatingPureSelection && "Select"}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};
