// db.ts
import Dexie, { Table } from 'dexie';

export interface ExecutiveAccount {
    AccountOwner: string;
    EMAIL: string;
    accountId: string;
    accountName: string;
    division: string;
    partnerAccountId: string;
    partnerAccountName: string;
    subDivision: string;
    theater: string;
}

export interface LastUpdated {
    table: string;
    date: Date;
}

export class PVRDatabase extends Dexie {
    executiveAccounts!: Table<ExecutiveAccount>;
    lastUpdated!: Table<LastUpdated>;

    constructor() {
        super('PVR');
        this.version(1).stores({
            executiveAccounts: '&key, AccountOwner, EMAIL, accountId, accountName, division, partnerAccountId, partnerAccountName, subDivision, theater',
            lastUpdated: '&table, date'
        });
        if (!this.isOpen())
            this.open().then(() => {
                console.log('Database opened');
            });
    }
}

export const db = new PVRDatabase();

export default class IndexedDB {

    static async getExecutiveAccounts(EMAIL: string): Promise<ExecutiveAccount[]> {
        try {

            if (!db.isOpen()) return []

            const lastUpdated = await db.lastUpdated.get("executiveAccounts")
            if (lastUpdated) {
                const executiveAccounts = await db.executiveAccounts.where({ EMAIL }).toArray();
                return executiveAccounts || []
            }
            return []
        } catch (err) {
            return []
        }

    }

    static async setExecutiveAccounts(executiveAccounts: ExecutiveAccount[]): Promise<void> {
        if (!db.isOpen()) return

        await db.executiveAccounts.bulkPut(executiveAccounts.map((executiveAccount) => {
            return {
                key: executiveAccount.EMAIL + "_" + executiveAccount.accountId + "_" + executiveAccount.partnerAccountId,
                ...executiveAccount
            }
        }))
        await db.lastUpdated.put({
            table: "executiveAccounts",
            date: new Date()
        })
    }

}