import React from "react";
import { Visualization } from "@shared/interfaces";
import Utils from "../../common/Utils";
import "./Html.scss";

export default ({ visualization }: { visualization: Visualization }) => {
  const showName = Utils.showName(visualization);
  if (!visualization.content) {
    return <div className="visualization-error">No content found.</div>;
  }
  return (
    <div
      className={`${visualization.representation.formatting?.cssClass} value-type`}
    >
      {showName && <strong>{visualization.name}: </strong>}
      <div dangerouslySetInnerHTML={{ __html: visualization.content }}></div>
    </div>
  );
};
