import { Box, Grid, Typography } from "@material-ui/core";
import { CheckCircleOutlined } from "@material-ui/icons";
import React, { FC } from "react";

export interface CarouselItem {
  id: string;
  name: string;
  description?: string;
  /** Pass URL of the image to display as Carousel item */
  imageUrl: string;
  isSelected?: boolean;
  onSelect?: (isSelected: boolean) => void;
}

interface CarouselCardProps extends CarouselItem {}

const CarouselCard: FC<CarouselCardProps> = ({ id, name, description, imageUrl, isSelected, onSelect }) => {
  return (
    <Grid
      item
      style={{
        minWidth: "23%",
        width: "23%",
      }}
      key={id}
    >
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        textAlign="center"
        p={1}
        position="relative"
        className={`slide ${isSelected ? "slide--selected" : ""}`}
        onClick={() => onSelect && onSelect(!!isSelected)}
      >
        <img src={imageUrl} style={{ width: "100%", minHeight: 140 }} className="border" alt={name} />
        <Typography variant="body1">{name}</Typography>
        <Typography variant="caption">{description}</Typography>
        {isSelected ? (
          <CheckCircleOutlined
            fontSize="small"
            color="primary"
            style={{
              position: "absolute",
              right: -6,
              top: -6,
              zIndex: 2,
              backgroundColor: "#fafafa",
            }}
          />
        ) : null}
      </Box>
    </Grid>
  );
};

export default CarouselCard;
