import { ArrayType } from "@shared/interfaces";
import moment from "moment";
import System from "../../common/System";
import Config from "../../config/Config";
import { SnackbarSeverity } from "../../enums/SnackbarSeverity";
import Api, { DecksPayload, PptConfigDeck, SlidePayload } from "../../services/Api";
import { AccountSelection } from "../../types/AccountSelection";
import { RTCOParameters } from "../../types/StoreState";
import { Fleet, Preference } from "./config";

export enum DefaultPreferences {
  ALL = "All Arrays",
  DEFAULT = "Default",
}

export const getDefaultSlideSelection = (decks: PptConfigDeck[]) =>
  decks.reduce(
    (acc: string[], deck) => [
      ...acc,
      ...(deck.slides
        ? deck.slides.filter(({ checked }) => checked).map(({ identifier }) => identifier)
        : deck.checked
        ? [deck.identifier]
        : []),
    ],
    []
  );

export const createSaveConfigPayload = (decks: PptConfigDeck[], selectedSlides: string[]): DecksPayload => {
  const payload = decks.reduce((acc: DecksPayload["pptConfig"], { identifier, slides = [] }) => {
    if (slides.length) {
      acc[identifier] = slides.reduce((checked: SlidePayload, { identifier: slideId }) => {
        checked[slideId] = selectedSlides.includes(slideId);
        return checked;
      }, {});
    } else {
      acc[identifier] = selectedSlides.includes(identifier);
    }

    return acc;
  }, {});

  return {
    pptConfig: payload,
  };
};

const b64toBlob = (data: string) => {
  let byteString = atob(data);
  let ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "application/vnd.ms-powerpoint" });
};

export interface PPTConfig {
  "bcs": {[key: string]: boolean | SlidePayload} | null,
  "pvr": {[key: string]: boolean | SlidePayload} | null
}

interface ExportPptRequestParams {
  includeAll?: boolean;
  customPreferenceName?: string;
  accountSelection: AccountSelection;
  selectedArraysEnvironment: ArrayType;
  rtcoParameters: RTCOParameters;
  config: PPTConfig;
  operation: string;
  onComplete?: () => void;
}

export const exportPptRequest = ({
  customPreferenceName,
  includeAll,
  accountSelection,
  rtcoParameters,
  selectedArraysEnvironment,
  config,
  operation,
  onComplete,
}: ExportPptRequestParams) => {
  if (accountSelection.parentAccountId || accountSelection.accounts.length > 0) {
    const sendDownloadLink = Config.app.downloadPPTByMail;
    if (!sendDownloadLink) {
      System.openSnackbar("Please wait while the presentation is being generated", SnackbarSeverity.INFO);
    }

    Api.export()
      .ppt(
        accountSelection.parentAccountId,
        accountSelection.accounts.length > 0
          ? accountSelection.accounts.map((account) => account.id)
          : accountSelection.parentAccountId
          ? [accountSelection.parentAccountId]
          : [],
        accountSelection.hierarchyType,
        selectedArraysEnvironment,
        includeAll,
        rtcoParameters,
        config,
        operation,
        customPreferenceName,
      )
      .then((response) => {
        if (sendDownloadLink) {
          System.openSnackbar("You will receive an email with the download link shortly", SnackbarSeverity.INFO);
        } else {
          const element = document.createElement("a");
          const file: any = b64toBlob(response);
          element.href = URL.createObjectURL(file);
          element.download = moment(new Date()).format("YYYY-MM-DD") + ".pptx";
          document.body.appendChild(element);
          element.click();
        }
      })
      .finally(onComplete);
  } else {
    System.openSnackbar("No accounts selected", SnackbarSeverity.ERROR);
    onComplete && onComplete();
  }
};

export const formatPreferenceData = (data: any[]): Preference[] =>
  data.map(({ arrayIds: selection, isDefault, tag: name }) => ({
    name,
    isDefault,
    selection,
  }));

export const getDefaultPreference = (preferences: Preference[]): Preference | undefined =>
  preferences.find(({ isDefault }) => isDefault);

export const syncCheckedRows = (data: Fleet[], selection: Preference["selection"]) =>
  data.map((row) => ({
    ...row,
    tableData: {
      checked: selection.includes(row.id),
    },
  }));
