import React from "react";
import { useStyles } from "../common/Styles";

export default (props: { noLeftNav?: boolean; children: any }) => {
  const classes = useStyles();

  return (
    <main className={props.noLeftNav ? classes.contentFull : classes.content}>
      {props.children}
    </main>
  );
};
