import React, { useState, useEffect } from "react";
import Visualization from "./Visualization";
import { Grid } from "@material-ui/core";
import { AccountHierarchyType, Dashboard, Visualization as  VisualizationObj } from "@shared/interfaces";
import RTCOInputComponent from "./RTCOInputComponent";
import Api from "../services/Api";
import { StoreState } from "../types/StoreState";
import { useSelector } from "react-redux";

export default ({ dashboard }: { dashboard: Dashboard }) => {
  const state = useSelector((state: StoreState) => state)
  const [rtcoParams, setRtcoParams] = useState<any>()
  const [rtcoData, setRtcoData] = useState<any[]>([])
  const [rtcoLoading, setRtcoLoading] = useState<boolean>(false)
  const {
    accountSelection,
    selectedArraysEnvironment,
  } = useSelector((state: StoreState) => state);

  useEffect(() => {
    if(rtcoParams !== undefined){
      setRtcoLoading(true)    
      const fetchData = async () => {
        if(typeof rtcoParams === "object" && rtcoParams !== null){
          
          const sfdcAccounts = accountSelection.accounts.length ? accountSelection.accounts.map(
            (account) => account.id
          ) : (accountSelection.parentAccountId ? [ accountSelection.parentAccountId] : [])

          const pureAccounts = accountSelection.hierarchyType === AccountHierarchyType.PURE ? accountSelection.accounts.reduce((acc: any, curr: any) => {
            const orgAccounts = curr?.accounts
            acc.push(...orgAccounts)
            return acc
          }, []) : []

          const data = await Api.dashboards().getDashboardData(
            { 
              ...rtcoParams, 
              // pageInfo: { pageNum: 1, pageSize: 5}, 
              arrayType: selectedArraysEnvironment, 
              hierarchyType: AccountHierarchyType.SFDC,
              selectedAccountIds: accountSelection.hierarchyType === AccountHierarchyType.SFDC ? sfdcAccounts : pureAccounts, 
              parentAccountId: pureAccounts.length ? pureAccounts[0] : [],
            }, dashboard.id)
          setRtcoData(data)
          setRtcoLoading(false)
        }
      }
      fetchData()
    }
  }, [rtcoParams])

  return (
    <Grid container spacing={1}>
      {dashboard.name === "RTCO" && <RTCOInputComponent onClick={(params: any) => setRtcoParams(params)} />}  
      {dashboard.visualizations.filter((visualization: VisualizationObj) => (!('isDashboardEnabled' in visualization) || ('isDashboardEnabled' in visualization && visualization.isDashboardEnabled))).map((visualization, i) => (
        <Visualization
          key={i}
          visualization={visualization}
          dashboard={dashboard}
          rtcoData={dashboard.name === "RTCO" ? rtcoData : undefined}
          rtcoLoading={rtcoLoading}
        />
      ))}
    </Grid>
  );
};
