import React, { useEffect, useMemo, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import throttle from "lodash/throttle";
import { SfdcAccount } from "@shared/interfaces";
import Api from "../../services/Api";
import { CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StoreState } from "../../types/StoreState";
import System from "../../common/System";

export default ({
  changeAccount,
}: {
  changeAccount: (account: SfdcAccount, callback: Function) => void;
}) => {
  const loadingHierarchy = useSelector(
    (state: StoreState) => state.loadingHierarchy
  );
  const [value] = useState<SfdcAccount | null>(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState<SfdcAccount[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [skipSearch, setSkipSearch] = useState(false);

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (results?: SfdcAccount[]) => void
        ) => {
          if (request.input.length >= 3) {
            System.updateLoadingSearchResults(true);
            setLoading(true);
            Api.accounts()
              .sfdcSearch({ name: request.input })
              .then((res) => {
                callback(res);
                setLoading(false);
              })
              .finally(() => {
                System.updateLoadingSearchResults(false);
              });
          }
        },
        1000
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (!skipSearch) {
      fetch({ input: inputValue }, (results?: SfdcAccount[]) => {
        if (active) {
          let newOptions = [] as SfdcAccount[];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
          setOpen(true);
        }
      });
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, skipSearch]);

  return (
    <Autocomplete
      open={open}
      getOptionLabel={(option) => option.name}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      disabled={loadingHierarchy}
      includeInputInList
      filterSelectedOptions
      value={value}
      onBlur={() => {
        setOpen(false);
      }}
      noOptionsText={"No accounts found for the given search string"}
      loadingText={"Loading..."}
      onChange={(event: any, newValue: SfdcAccount | null) => {
        setOpen(false);
        setOptions(newValue ? [newValue, ...options] : options);
        if (newValue !== null) {
          setLoading(true);
          changeAccount(newValue, () => setLoading(false));
          setSkipSearch(true);
        } else {
          setSkipSearch(false);
        }
        // setValue(newValue);
      }}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        setSkipSearch(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Accounts"
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(option) => {
        return (
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="body1" color="textSecondary">
                {option.name} -{" "}
                <Typography variant="caption">{option.id}</Typography>
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};
