import "./Feature.scss";

import {
  CircularProgress,
  Link,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import Api from "../../services/Api";
import CheckIcon from "@material-ui/icons/Check";
import {
  DataLoadingStrategy,
  Visualization,
  VisualizationType,
} from "@shared/interfaces";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import RemoveIcon from "@material-ui/icons/Remove";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import sort from "fast-sort";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const columns: { name: string }[] = sort([
  { name: "Automation" },
  { name: "Cloud" },
  { name: "Data protection" },
  { name: "Ecosystem" },
  { name: "Performance Bucket" },
]).asc(["name"]);

const columns_flashblade: { name: string }[] = sort([
  { name: "Data protection" },
  { name: "Data Protocols" },
  { name: "Advanced Features" },
]).asc(["name"]);

const columns_pure1: { name: string }[] = sort([
  { name: "Cost Avoidance/Reduction" },
  { name: "Software Benefits" },
]).asc(["name"]);

export default ({
  visualization,
  dashboardId,
}: {
  visualization: Visualization;
  dashboardId: string;
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
  } = useSelector((state: StoreState) => state);
  const classes = useStyles();
  const showColumnTitles = Utils.showColumnTitles(visualization);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    if (visualization.loadingStrategy === DataLoadingStrategy.LAZY && visible) {
      Api.visualizations()
        .byId(
          dashboardId,
          visualization.id,
          {
            parentAccountId: accountSelection.parentAccountId,
            selectedAccountIds: accountSelection.accounts.map(
              (account) => account.id
            ),
            hierarchyType: accountSelection.hierarchyType,
            arrayType: selectedArraysEnvironment,
          },
          {
            dashboardName: currentDashboardName,
            visualizationName: visualization.name,
          }
        )
        .then((response) => {
          if (mounted) {
            setData(response);
          }
        })
        .finally(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    } else {
      setData(visualization.data || []);
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [
    accountSelection,
    dashboardId,
    visualization,
    selectedArraysEnvironment,
    currentDashboardName,
    visible,
  ]);

  const renderCell = (columnName: string) => {
    let values: any[] = [];
    data &&
      data.forEach((item: any) => {
        if (item["Feature_Bucket"] === columnName) {
          item.className = "feature ";
          if (
            item["Installed"].toUpperCase() === "Y" &&
            item["IndustryInstalledFinal"].toUpperCase() === "Y"
          ) {
            item.order = 1;
            item.kb_link ? item.className += "green_hyperlink" : item.className += "green";
            item.icon = <DoneAllIcon />;
          } else if (item["Installed"].toUpperCase() === "Y") {
            item.kb_link ? item.className += "green_hyperlink" : item.className += "green";
            item.order = 2;
            item.icon = <DoneAllIcon />;
          } else if (item["IndustryInstalledFinal"].toUpperCase() === "Y") {
            item.kb_link ? item.className += "orange_hyperlink" : item.className += "orange";
            item.order = 2;
            item.icon = <CheckIcon />;
          } else {
            item.kb_link ? item.className += "black_hyperlink" : item.className += "black";
            item.order = 3;
            item.icon = <RemoveIcon />;
          }
          values.push(item);
        }
      });
    values = sort<any>(values).asc(["order"]);
    const key = "AllFeature";
    return values.map((item: any, i: number) =>{
      return (
      <div key={i} className={item.className}>
        {item.icon}  {item.kb_link ?  <Link href={item.kb_link} target="_blank" className={item.className}>{item[key]}</Link> : item[key]}
      </div>
    )
  });
  };

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>
      <TableContainer className="feature-list">
        {loading ? (
          <CircularProgress />
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={!showColumnTitles ? "hidden" : ""}>
              <TableRow>
                <TableCell></TableCell>
                {visualization.representation.type ===
                VisualizationType.FEATURE_LIST
                  ? columns.map((column) => (
                      <TableCell key={column.name}>{column.name}</TableCell>
                    ))
                  : visualization.representation.type ===
                    VisualizationType.FEATURE_LIST_FLASHBLADE
                  ? columns_flashblade
                      .map((column) => (
                        <TableCell key={column.name}>{column.name}</TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                      ])
                  : columns_pure1
                      .map((column) => (
                        <TableCell key={column.name}>{column.name}</TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                        <TableCell key={"empty3"} />,
                      ])}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="main-label">
                  <h4>FEATURE LIST</h4>
                  <div className="green">
                    <DoneAllIcon /> Features adopted by Customer and industry
                  </div>
                  <div className="orange">
                    <CheckIcon /> Features adopted by Industry
                  </div>
                  <div>
                    <RemoveIcon /> Features not adopted
                  </div>
                </TableCell>
                {visualization.representation.type ===
                VisualizationType.FEATURE_LIST
                  ? columns.map((column) => (
                      <TableCell key={column.name}>
                        {renderCell(column.name)}
                      </TableCell>
                    ))
                  : visualization.representation.type ===
                    VisualizationType.FEATURE_LIST_FLASHBLADE
                  ? columns_flashblade
                      .map((column) => (
                        <TableCell key={column.name}>
                          {renderCell(column.name)}
                        </TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                      ])
                  : columns_pure1
                      .map((column) => (
                        <TableCell key={column.name}>
                          {renderCell(column.name)}
                        </TableCell>
                      ))
                      .concat([
                        <TableCell key={"empty1"} />,
                        <TableCell key={"empty2"} />,
                        <TableCell key={"empty3"} />,
                      ])}
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </VisibilitySensor>
  );
};
