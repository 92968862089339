import React from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";
import { Redirect } from "react-router-dom";
import { Paths } from "../common/Paths";
import Config from "../config/Config";
import { AccountHierarchyType } from "@shared/interfaces";

export default () => {
  const { accountSelection } = useSelector((state: StoreState) => state);
  if (
    accountSelection.parentAccountId ||
    accountSelection.hierarchyType === AccountHierarchyType.PURE
  ) {
    return <Redirect to={Paths.dashboard(Config.app.defaultDashboard)} />;
  } else {
    return <Redirect to={Paths.app} />;
  }
};
