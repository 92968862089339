import { Box, BoxProps } from "@material-ui/core";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  containerProps?: BoxProps;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, value, index, containerProps, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 1,
            minHeight: 300,
            position: "relative",
            overflow: "auto",
            ...containerProps?.sx,
          }}
          {...containerProps}
        >
          {children}
        </Box>
      )}
    </div>
  );
};

export default TabPanel;
