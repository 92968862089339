import "./App.scss";
import "./fonts/fonts.scss";

import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Route, BrowserRouter as Router } from "react-router-dom";

import AccountPage from "./pages/AccountPage";
import AlertDialog from "./components/AlertDialog";
import AppLoader from "./components/AppLoader";
import AppPage from "./pages/AppPage";
import Feedback from "./components/feedback/Feedback";
import HomePage from "./pages/HomePage";
import LoginTracker from "./components/LoginTracker";
import LogoutPage from "./pages/LogoutPage";
import OrganizationPage from "./pages/OrganizationPage";
import { Paths } from "./common/Paths";
import { Provider } from "react-redux";
import React from "react";
import Snackbar from "./components/Snackbar";
import { Store } from "./common/Store";
import Theme from "./common/Theme";

export default () => {
  return (
    <div className="App">
      <CssBaseline />
      <ThemeProvider theme={Theme}>
        <Provider store={Store}>
          <AlertDialog />
          <Snackbar />
          <Feedback />
          <Router>
            {/* <MainNav /> */}
            <Route path={Paths.loginCallback} component={LoginTracker} />
            <Route path={Paths.logout} component={LogoutPage} />
            <AppLoader>
              <Route exact path={Paths.root} component={HomePage} />
              <Route path={Paths.app} component={AppPage} />
              <Route
                exact
                path={[Paths.account(), Paths.sfdc()]}
                component={AccountPage}
              />
              <Route
                exact
                path={Paths.organization()}
                component={OrganizationPage}
              />
            </AppLoader>
          </Router>
        </Provider>
      </ThemeProvider>
    </div>
  );
};
