import {
  Dashboard,
  DataLoadingStrategy,
  Visualization,
  VisualizationType,
} from "@shared/interfaces";
import React from "react";
import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import "./Visualization.scss";
import LineChart from "./visualizations/LineChart";
import Value from "./visualizations/Value";
import BarChart from "./visualizations/BarChart";
import Table from "./visualizations/Table";
import Html from "./visualizations/Html";
import FeatureList from "./visualizations/FeatureList";
import CustomerAdoption from "./visualizations/CustomerAdoption";
import LazyTable from "./visualizations/LazyTable";
import Utils from "../common/Utils";
import ArrayGenealogy from "./visualizations/ArrayGenealogy";
import PromoCarousel from "./PromoCarousel";
import ComposedChart from "./visualizations/ComposedChart";
import Assumptions from "./visualizations/Assumptions";
import AppBanner from "./visualizations/AppBanner";
import Honeycomb from "./visualizations/Honeycomb";

export default ({
  visualization,
  dashboard,
  rtcoData,
  rtcoLoading,
}: {
  visualization: Visualization;
  dashboard: Dashboard;
  rtcoData?: Array<any>;
  rtcoLoading: boolean;
}) => {
  const showName =
    visualization.representation?.formatting?.showName !== undefined
      ? visualization.representation.formatting.showName
      : true;

  return (
    <Grid
      item
      md={visualization.representation.formatting?.width || 12}
      className="visualization"
    >
      <Card
        elevation={1}
        className={
          visualization.representation.type === VisualizationType.TEXT
            ? "divider"
            : ""
        }
      >
        <CardContent style={{ height: "100%" }}>
          <div className="visualization-container">
            {visualization.representation.type === VisualizationType.TEXT ? (
              <Typography className="name text" variant={"h6"}>
                {visualization.name}
              </Typography>
            ) : (
              showName &&
              visualization.representation.type !== VisualizationType.VALUE && (
                <Typography className="name" variant={"h6"}>
                  {visualization.name}
                </Typography>
              )
            )}
            {visualization.sampleDataSet === true && (
              <Typography style={{ color: "#93b9d9", fontWeight: 700 }} className="text" variant={"subtitle1"}>
                Sample dataset displayed. Please download for complete list
              </Typography>
            ) }
           
            {(() => {
              switch (visualization.representation.type) {
                case VisualizationType.PROMO_CAROUSEL:
                  return (<PromoCarousel/>);
                case VisualizationType.APP_BANNER:
                  return (<AppBanner visualization={visualization} />)
  
                case VisualizationType.TABLE:
                  if (
                    visualization.loadingStrategy ===
                      DataLoadingStrategy.LAZY &&
                    (Utils.showPagination(visualization) || visualization.hasColumnFilters)
                  ) {
                    return (
                      <LazyTable
                        visualization={visualization}
                        dashboardId={dashboard.id}
                        dashboardType={dashboard.subType}
                        rtcoData={rtcoData}
                        rtcoLoading={rtcoLoading}
                      />
                    );
                  } else {
                    return (
                      <Table
                        visualization={visualization}
                        dashboardId={dashboard.id}
                      />
                    );
                  }
                case VisualizationType.LINE_CHART:
                  return (
                    <LineChart
                      visualization={visualization}
                      dashboardId={dashboard.id}
                      rtcoData={rtcoData}
                      dashboardHasDataForAll={dashboard.hasDataForAll}
                    />
                  );
                case VisualizationType.BAR_CHART:
                  return (
                    <BarChart
                      visualization={visualization}
                      dashboardId={dashboard.id}
                      rtcoData={rtcoData}
                      dashboardHasDataForAll={dashboard.hasDataForAll}
                    />
                  );
                case VisualizationType.COMPOSED_CHART:
                  return (
                    <ComposedChart 
                      visualization={visualization}
                      dashboardId={dashboard.id}
                      rtcoData={rtcoData}
                      dashboardHasDataForAll={dashboard.hasDataForAll}
                    />
                  )
                case VisualizationType.VALUE:
                  return (
                    <Value
                      visualization={visualization}
                      dashboardId={dashboard.id}
                    />
                  );
                case VisualizationType.HTML:
                  return <Html visualization={visualization} />;
                case VisualizationType.TEXT:
                  return null;
                case VisualizationType.ASSUMPTIONS:
                  return <Assumptions visualization={visualization} />;
                case VisualizationType.FEATURE_LIST:
                case VisualizationType.FEATURE_LIST_FLASHBLADE:
                case VisualizationType.FEATURE_LIST_PURE1:
                  return (
                    <FeatureList
                      visualization={visualization}
                      dashboardId={dashboard.id}
                    />
                  );
                case VisualizationType.FEATURE_SUMMARY:
                case VisualizationType.FEATURE_SUMMARY_FLASHBLADE:
                case VisualizationType.FEATURE_SUMMARY_PURE1:
                  return (
                    <CustomerAdoption
                      visualization={visualization}
                      dashboardId={dashboard.id}
                    />
                  );
                case VisualizationType.ARRAY_GENEALOGY:
                  return (
                    <ArrayGenealogy
                      visualization={visualization}
                      dashboardId={dashboard.id}
                    />
                  );
                case VisualizationType.HONEYCOMB:
                  return (
                    <Honeycomb 
                      visualization={visualization}
                      dashboardId={dashboard.id}
                    />
                  )
                default:
                  return (
                    <div className="visualization-error">
                      Visualization type not found: '
                      {visualization.representation.type}'.
                    </div>
                  );
              }
            })()}
            {visualization.representation.legend && (
              <div className="legend">
                {visualization.representation.legend.text}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};
