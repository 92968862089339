import { Tooltip, Typography } from "@material-ui/core"
import React from "react"
import { mapAssessmentToColor, mapLevelToColor } from "../../util/util"


export default ({level, assessment, arrayName}: {
  level?: number,
  assessment?: string,
  arrayName?: string
}) => {

  const getColor = ({ level, assessment }: { level?: number, assessment?: string }) => {
    if (assessment) {
      return mapAssessmentToColor(assessment);
    } else {
      return mapLevelToColor(level as number);
    }
  }

  return (
    <Tooltip arrow={true} title={arrayName ?
      <Typography color="inherit">        
          <b>{arrayName}</b>
          <br />
          Overall Assessment &nbsp;
          <span className="honeycomb-icon" style={{ color: getColor({level, assessment}), paddingLeft: "20px", verticalAlign: "sub" }}>&#x2B22;&nbsp;</span>
          <span style={{ textTransform: "capitalize" }}>{assessment || ""}</span>
      </Typography> : assessment as string}
      >
      <span className="honeycomb-icon" style={{ color: getColor({level, assessment}) }}>&#x2B22;</span>
    </Tooltip>
  )
}
