export interface Fleet {
  id: string
  hostname: string
  organisation: string
  segment: string
  domain: string
}

export interface Preference {
  name: string
  selection: string[]
  isDefault?: boolean
}

export const columns: { title: string; field: keyof Fleet }[] = [
  {
    title: 'Hostnames',
    field: 'hostname',
  },
  {
    title: 'Organisation',
    field: 'organisation',
  },
  {
    title: 'Segment',
    field: 'segment',
  },
  {
    title: 'Domain',
    field: 'domain',
  },
]
