import React, { useEffect, useState } from "react";
import { CircularProgress, Typography, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";
import Api from "../services/Api";
import System from "../common/System";
import AppStorage from "../common/AppStorage";
import { Dashboard } from "@shared/interfaces";

export default ({ children }: { children: any }) => {
  const { authLoaded } = useSelector((state: StoreState) => state);
  const [appAvailable, setAppAvailable] = useState(true);
  const [errorMessage, setErrorMessage] = useState();
  const [unauthorized, setUnauthorized] = useState(false);
  const [appOutage, setAppOutage] = useState<boolean>(false)
  const [appOutageMessage, setAppOutageMessage] = useState<string>('')


  useEffect(() => {
    const load = async () => {
      if (!authLoaded) {
        try {
          const user = await Api.users().me(true);
          if (AppStorage.getAccountSelection()) {
            System.updateAccountSelection(AppStorage.getAccountSelection());
          }
          System.updateUser(user);
          // await Api.dashboards().all();
          const dashboards = await Api.dashboards().all();
          const headerDashboard: Dashboard | undefined = dashboards.find(item => item.name === "Header")
          if(headerDashboard && headerDashboard.applicationInfo && headerDashboard.applicationInfo.forceApplicationOutage){
            setAppOutage(true)
            setAppOutageMessage(headerDashboard.applicationInfo.forceApplicationOutageMessage as string)
          }
          System.updateAuthLoaded(true);
          // Get quarters to store them in cache for later use
          Api.feedback()
            .getQuarters()
            .then((response) => {});
        } catch (e) {
          if (e.response?.data?.message === "Unauthorized") {
            setUnauthorized(true);
          } else {
            setErrorMessage(e.response.data.message || e.message);
          }
          setAppAvailable(false);
          System.updateAuthLoaded(true);
        }
      }
    };
    load();
  }, [authLoaded]);

  return (
    <>
      {authLoaded && appAvailable && !appOutage && children}
      {!authLoaded && appAvailable && !appOutage && (
        <div className="loading-wrp">
          <CircularProgress />
        </div>
      )}
      {appOutage ? <div className="app-errors">
        <>
           <Typography variant="h4">Application under maintenance</Typography>
           <Typography variant={"body1"}>
             {appOutageMessage}
           </Typography>
        </>
      </div> : 
        !appAvailable && (
          <div className="app-errors">
            {unauthorized && (
              <>
                <Typography variant="h4">Unauthorized Access</Typography>
                <Typography variant={"body1"}>
                  You do not have access to PVR. Please reach out to{" "}
                  <Link href="mailto:Biz-Insights@purestorage.com">
                    Biz-Insights@purestorage.com
                  </Link>
                  .
                </Typography>
              </>
            )}
            {!unauthorized && (
              <>
                <Typography variant="h4">
                  PVR Application not available
                </Typography>
                <Typography variant={"body1"}>
                  Error: {errorMessage}.<br />. This may be due to the data
                  warehouse being refreshed. Please wait a few minutes and try
                  again.
                </Typography>
              </>
            )}
          </div>
        )
      
      }

    </>
  );
};
