import { configureStore, createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StoreState } from "../types/StoreState";
import { AccountHierarchyType, SubType, ArrayType } from "@shared/interfaces";
import { AccountSelectorTarget } from "../enums/AccountSelectorTarget";
import Api from "../services/Api";
import { Preference } from "../components/ppt-configurator/config";
import { formatPreferenceData } from "../components/ppt-configurator/utils";

export const fetchBcsPreferences = createAsyncThunk("bcs/preferences", (accountIds: string[]) =>
  Api.pptConfig()
    .getBcsConfigPreferences(accountIds)
    .then((response) => formatPreferenceData(response))
);

const initialState: StoreState = {
  snackbar: { open: false },
  loadingHierarchy: false,
  loadingSearchResults: false,
  loadingDashboard: false,
  showAccountSelector: false,
  accountSelectorTarget: AccountSelectorTarget.APP,
  accountSelectionForFilters: [],
  accountSelectionHierarchyForFilters: [],
  accountSelection: {
    accounts: [],
    hierarchyType: AccountHierarchyType.SFDC,
  },
  authLoaded: false,
  currentDashboardName: "",
  showFeedbackForm: false,
  showFeedbackData: null,
  alertDialog: { title: "", message: "", open: false },
  selectedArraysEnvironment: ArrayType.ALL,
  lastFeedbacksRefresh: 0,
  accountSelectionContext: SubType.DEFAULT,
  rtcoParameters: {},
  volumeSearchTerms: [],
  bcs: {
    loadingPreferences: false,
    preferences: [],
  },
  executiveAccountFullDatasetLoading: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    updateUser: (state: StoreState, action: any) => {
      state.user = action.payload;
    },
    openSnackbar: (state: StoreState, action) => {
      state.snackbar = { ...action.payload, open: true };
    },
    closeSnackbar: (state: StoreState) => {
      state.snackbar = { ...state.snackbar, open: false };
    },
    updateLoadingHierarchy: (state: StoreState, action) => {
      state.loadingHierarchy = action.payload;
    },
    updateLoadingSearchResults: (state: StoreState, action) => {
      state.loadingSearchResults = action.payload;
    },
    updateShowAccountSelector: (state: StoreState, action) => {
      state.showAccountSelector = action.payload;
    },
    updateAccountSelectorTarget: (state: StoreState, action) => {
      state.accountSelectorTarget = action.payload;
    },
    updateAccountSelection: (state: StoreState, action) => {
      state.accountSelection = action.payload;
    },
    updateAccountSelectionForFilters: (state: StoreState, action) => {
      state.accountSelectionForFilters = action.payload.accounts;
      state.accountSelectionSelectedHierarchyForFilters = action.payload.hierarchy;
    },
    updateAccountSelectionHierarchyForFilters: (state: StoreState, action) => {
      state.accountSelectionHierarchyForFilters = action.payload;
    },
    updateAuthLoaded: (state: StoreState, action: any) => {
      state.authLoaded = action.payload;
    },
    updateCurrentDashboardName: (state: StoreState, action: any) => {
      state.currentDashboardName = action.payload;
    },
    updateLoadingDashboard: (state: StoreState, action: any) => {
      state.loadingDashboard = action.payload;
    },
    updateShowFeedbackForm: (state: StoreState, action: any) => {
      state.showFeedbackForm = action.payload.open;
      state.showFeedbackData = action.payload.feedback;
    },
    updateAlertDialog: (state: StoreState, action: any) => {
      state.alertDialog = { ...action.payload };
    },
    updateSelectedArraysEnvironment: (state: StoreState, action: any) => {
      state.selectedArraysEnvironment = action.payload;
    },
    updateHeaderDashboard: (state: StoreState, action: any) => {
      state.headerDashboard = action.payload;
    },
    updateLastFeedbacksRefresh: (state: StoreState, action: any) => {
      state.lastFeedbacksRefresh = action.payload;
    },
    updateExecutiveAccountSelection: (state: StoreState, action: any) => {
      state.executiveAccountSelection = action.payload;
    },
    updateAccountSelectionContext: (state: StoreState, action: any) => {
      state.accountSelectionContext = action.payload;
    },
    updateRtcoParameters: (state: StoreState, action: any) => {
      state.rtcoParameters = action.payload;
    },
    updateVolumeSearchTerms: (state: StoreState, action: any) => {
      state.volumeSearchTerms = action.payload;
    },
    updateExecutiveAccounts: (state: StoreState, action: any) => {
      state.executiveAccounts = action.payload;
    },
    updateExecutiveAccountFullDataLoading: (state: StoreState, action: PayloadAction<boolean>) => {
      state.executiveAccountFullDatasetLoading = action.payload;
    },
    createBcsPreference: (state: StoreState, { payload }: PayloadAction<Preference>) => {
      state.bcs.preferences.push(payload);
    },
    updateBcsPreference: (state: StoreState, { payload: { name, selection } }: PayloadAction<Preference>) => {
      const index = state.bcs.preferences.findIndex((preference) => preference.name === name);
      state.bcs.preferences[index].selection = selection;
    },
    deleteBcsPreference: (state: StoreState, { payload }: PayloadAction<string>) => {
      state.bcs.preferences = [...state.bcs.preferences].filter(({ name }) => name !== payload);
    },
  },
  extraReducers: {
    [fetchBcsPreferences.pending.type]: (state) => {
      state.bcs.loadingPreferences = true;
    },
    [fetchBcsPreferences.fulfilled.type]: (state, action) => {
      state.bcs.loadingPreferences = false;
      state.bcs.preferences = action.payload;
    },
    [fetchBcsPreferences.rejected.type]: (state) => {
      state.bcs.loadingPreferences = false;
      state.bcs.preferences = [];
    },
  },
});

const Store = configureStore({
  reducer: appSlice.reducer,
  devTools: {
    stateSanitizer: (state: any) =>
      state.executiveAccounts ? { ...state, executiveAccounts: "<<LONG_BLOB>>" } : state,
  },
});

export { Store };
export const {
  updateUser,
  openSnackbar,
  closeSnackbar,
  updateLoadingHierarchy,
  updateLoadingSearchResults,
  updateShowAccountSelector,
  updateAccountSelectorTarget,
  updateAccountSelectionForFilters,
  updateAccountSelectionHierarchyForFilters,
  updateAccountSelection,
  updateAuthLoaded,
  updateLoadingDashboard,
  updateShowFeedbackForm,
  updateAlertDialog,
  updateSelectedArraysEnvironment,
  updateHeaderDashboard,
  updateCurrentDashboardName,
  updateLastFeedbacksRefresh,
  updateExecutiveAccountSelection,
  updateAccountSelectionContext,
  updateRtcoParameters,
  updateVolumeSearchTerms,
  updateExecutiveAccounts,
  updateExecutiveAccountFullDataLoading,
  createBcsPreference,
  updateBcsPreference,
  deleteBcsPreference,
} = appSlice.actions;
