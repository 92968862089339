import "./AppPage.scss";

import { AccountHierarchyType, PureAccount, SfdcAccount } from "@shared/interfaces";
import { Box, Link, Tooltip, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";

import { AccountSelection } from "../types/AccountSelection";
import AccountSelector from "../components/AccountSelector/AccountSelector";
import Api from "../services/Api";
import Cache from "../common/Cache";
import Config from "../config/Config";
import DashboardPage from "./DashboardPage";
import FeedbackPage from "./FeedbackPage";
import MainMenu from "../components/MainMenu";
import { Paths } from "../common/Paths";
import { StoreState } from "../types/StoreState";
import System from "../common/System";
import Utils from "../common/Utils";
import Visualizations from "../components/Visualizations";
import { useSelector } from "react-redux";
import Notifications from "../components/Notifications";
import PptConfigurator from "./PptConfigPage";

export default () => {
  const location = useLocation();
  const history = useHistory<{
    accounts: SfdcAccount[];
    parentAccountId: string;
  }>();
  const { headerDashboard, accountSelection, currentDashboardName, executiveAccountSelection } = useSelector(
    (state: StoreState) => state
  );
  const [hierarchy, setHierarchy] = useState<SfdcAccount[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const selectAccounts = (newSelection: AccountSelection) => {
    setErrors([]);
    Cache.clearHierarchy();
    Cache.clearVisualizationsCache();
    System.updateAccountSelection(newSelection);
    history.push(Paths.dashboard(currentDashboardName === "" ? Config.app.defaultDashboard : currentDashboardName));
  };

  /**
   * This will run one auth is loaded to pre-fetch the current account hierarchy
   */
  useEffect(() => {
    const loadHierarchy = async () => {
      let errors: string[] = [];
      let sfdcHierarchy = [];
      let dashboardsResponse = await Api.dashboards().all();
      System.updateHeaderDashboard(
        dashboardsResponse.find((dashboard) => dashboard.name.toLocaleLowerCase() === "header")
      );

      // preload hierarchy
      if (accountSelection.hierarchyType === AccountHierarchyType.SFDC) {
        if (accountSelection.parentAccountId) {
          if (Cache.getHierarchy().length !== 0) {
            setHierarchy(Cache.getHierarchy());
          } else {
            System.updateLoadingHierarchy(true);
            try {
              sfdcHierarchy = await Api.accounts().bySfdcId(accountSelection.parentAccountId);
              setHierarchy(sfdcHierarchy);
              Cache.setHierarchy(sfdcHierarchy);
            } catch (error) {
              const err: any = error;
              errors.push(`${err?.response?.data?.message || err.message}`);
            } finally {
              System.updateLoadingHierarchy(false);
            }
          }
        }
      } else if (accountSelection.hierarchyType === AccountHierarchyType.PURE) {
        System.updateLoadingHierarchy(true);
        let pureAccountIds: string[] = [];
        accountSelection.accounts.forEach((account) => {
          const pureAccount = account as PureAccount;
          if (pureAccount.accounts && pureAccount.accounts.length > 0) {
            pureAccountIds = [...pureAccountIds, ...pureAccount.accounts];
          }
        });
        // Do now show a popup when this fails
        Api.accounts()
          .bySfdcIds({ accountIds: pureAccountIds }, true)
          .then((response: { status: string; message?: string; data: SfdcAccount[] }) => {
            if (response.status === "success") {
              const flat = Utils.flattenHierarchy(response.data, accountSelection);
              for (let i = 0; i < flat.length; i++) {
                if (pureAccountIds.find((acc) => acc === flat[i].id)) {
                  flat[i].tableData = { checked: true };
                }
              }
              setHierarchy(flat);
            } else {
              setHierarchy([]);
            }
          })
          .catch((error: any) => {
            setHierarchy([]);
          })
          .finally(() => {
            System.updateLoadingHierarchy(false);
          });
      }
      if (errors.length > 0) {
        setErrors(errors);
      }
    };
    loadHierarchy();
  }, [accountSelection]);

  const renderTitle = () => {
    if (location.pathname.endsWith("/feedback")) {
      return <div className={`non-dashboard`}>PVR History</div>;
    } else if (location.pathname.endsWith("/read-me")) {
      return <div className={`non-dashboard`}>Read Me</div>;
    } else {
      return headerDashboard && <Visualizations dashboard={headerDashboard} />;
    }
  };

  return (
    <>
      <AccountSelector selectAccounts={selectAccounts} sfdcHierarchy={hierarchy} />
      <div className="header-panel">
        {renderTitle()}
        <Box
          style={{ display: "flex", verticalAlign: "bottom", marginTop: -40 }}
          justifyContent="flex-end"
          alignContent="flex-end"
        >
          {!(!accountSelection.parentAccountId && accountSelection.accounts.length === 0) && <Notifications />}
          <Tooltip title="For issues please reach out to Biz Insights team" arrow={true}>
            <div style={{ marginTop: 15 }}>
              <Link color="textSecondary" className="contact-btn" href="mailto:biz-insights@purestorage.com">
                Contact: biz-insights@purestorage.com
              </Link>
            </div>
          </Tooltip>
        </Box>
      </div>
      <MainMenu />
      {!accountSelection.parentAccountId && accountSelection.accounts.length === 0 && !executiveAccountSelection && (
        <div className="app-errors">
          <Typography variant="h4">No account selected</Typography>
          <Typography variant={"body1"}>Please select an account from the top navigation menu.</Typography>
        </div>
      )}
      {errors.length > 0 ? (
        <div className="app-errors">
          <Typography variant="h4">Sorry, there was an error:</Typography>
          {errors.map((error, i) => (
            <Typography key={i} variant={"body1"}>
              {error}
            </Typography>
          ))}
        </div>
      ) : (
        <>
          <Route exact path={Paths.dashboard()} component={DashboardPage} />
          <Route path={Paths.feedback} component={FeedbackPage} />
          <Route path={Paths.pptConfigurator} component={PptConfigurator} />
        </>
      )}
    </>
  );
};
