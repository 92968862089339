import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import { createBcsPreference, Store } from "../../../common/Store";
import Api from "../../../services/Api";
import { Fleet, Preference } from "../config";

interface CreatePreferenceModalProps {
  open: boolean;
  preferences: Preference[];
  selectedRows: Fleet[];
  onClose: () => void;
  onPreferenceCreated: (preference: Preference) => void;
}

const CreatePreferenceModal: React.FC<CreatePreferenceModalProps> = ({
  open,
  preferences,
  selectedRows,
  onClose,
  onPreferenceCreated,
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onCreatePreference = () => {
    if (preferences.some(({ name: pName }) => name === pName)) {
      setError("Name already exists!");
    } else {
      const preference = {
        name,
        selection: selectedRows?.map(({ id }) => id),
      };
      setLoading(true);

      Api.pptConfig()
        .createBcsConfigPreference(preference.name, preference.selection)
        .then((response) => {
          if (response?.status === "SUCCESS") {
            setName("");
            setError("");
            Store.dispatch(createBcsPreference(preference));
            onPreferenceCreated(preference);
          } else {
            setError(JSON.stringify(response));
          }
        })
        .catch(() => {
          setError("Some error occured");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onCancelHandler = () => {
    setError("");
    setName("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle id="create-preference">Create Preference</DialogTitle>
      <DialogContent>
        <TextField
          value={name}
          onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setName(event.target.value)}
          autoFocus
          margin="dense"
          id="fleetName"
          label="Preference name"
          fullWidth
          error={!!error}
        />
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelHandler} color="primary">
          Cancel
        </Button>
        <Button
          disabled={loading}
          onClick={onCreatePreference}
          endIcon={loading ? <CircularProgress placeholder="Creating..." size={18} /> : void 0}
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreatePreferenceModal;
