import FilterListIcon from "@material-ui/icons/FilterList";
import DeleteIcon from "@material-ui/icons/Delete";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  ListItemSecondaryAction,
  IconButton,
  Box,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import FilterComponent from "./FilterComponent";
import { columns, Fleet, Preference } from "../config";
import CreatePreferenceModal from "./CreatePreferenceModal";
import { StoreState } from "../../../types/StoreState";
import { Store, deleteBcsPreference, updateBcsPreference } from "../../../common/Store";
import Api from "../../../services/Api";
import { getDefaultPreference, syncCheckedRows } from "../utils";
import System from "../../../common/System";
import { SnackbarSeverity } from "../../../enums/SnackbarSeverity";

export interface ColumnFilter {
  [key: string]: string[];
}

interface Props {
  onClose: () => void;
}

const FleetConfig: React.FC<Props> = ({ onClose }) => {
  const [selectedRows, setSelectedRows] = useState<Fleet[]>([]);
  const [activeFilter, setActiveFilter] = useState<keyof Fleet | null>(null);
  const [columnFilters, setColumnFilters] = useState<ColumnFilter>();
  const [tableData, setTableData] = useState<Fleet[]>([]);
  const [createPreferenceModalOpen, setCreatePreferenceModalOpen] = useState(false);
  const [arrayList, setArrayList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const { accountSelection } = useSelector((state: StoreState) => state);
  const {
    bcs: { preferences },
  } = useSelector((state: StoreState) => state);
  const [selectedPreference, setSelectedPreference] = useState<Preference | undefined>(
    getDefaultPreference(preferences) || preferences[0]
  );
  const [preferenceToBeDeleted, setPreferenceToBeDeleted] = useState("");
  const [deletingPreference, setDeletingPreference] = useState(false);
  const [updatingPreference, setUpdatingPreference] = useState(false);

  useEffect(() => {
    const accountIds =
      accountSelection.accounts.length > 0
        ? accountSelection.accounts.map(({ id }) => id)
        : accountSelection.parentAccountId
        ? [accountSelection.parentAccountId]
        : [];

    setLoading(true);
    Api.pptConfig()
      .arrayConfig(accountIds)
      .then((response) => {
        const arrayList: Fleet[] = response.map(({ array_id, hostname, organization_name, segment__c, domain }) => ({
          id: array_id,
          hostname,
          organisation: organization_name,
          segment: segment__c,
          domain,
        }));
        setArrayList(arrayList);
        setTableData(selectedPreference ? syncCheckedRows(arrayList, selectedPreference.selection) : arrayList);
        selectedPreference && setSelectedRows(arrayList.filter(({ id }) => selectedPreference.selection.includes(id)));
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelection.accounts, accountSelection.parentAccountId]);

  useEffect(() => {
    if (selectedPreference) {
      setTableData((tabData) => syncCheckedRows(tabData, selectedPreference.selection));
    }
  }, [selectedPreference]);

  const onFilterSubmit = (filters: ColumnFilter = {}) => {
    const tabData = isEmpty(filters)
      ? tableData
      : syncCheckedRows(
          arrayList.filter(
            (row) =>
              !Object.entries(filters).some(([key, filterValues]) => {
                return filterValues.length && !filterValues.some((val) => row[key as keyof Fleet].includes(val));
              })
          ),
          selectedRows.map(({ id }) => id)
        );

    setColumnFilters(filters);
    setTableData(tabData);
    setActiveFilter(null);
  };

  const onSelectionChange = (rows: any) => {
    setSelectedRows(rows);
  };

  const handleChangePreference = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const preferenceName = event.target.value;
    const preference = preferences.find(({ name }) => name === preferenceName);

    if (preference) {
      setSelectedPreference(preference);
      setSelectedRows(tableData.filter(({ id }) => preference.selection.includes(id)));
    }
  };

  const handleCreatePreferenceClick = () => {
    setCreatePreferenceModalOpen(true);
  };

  const onPreferenceCreated = (preference: Preference) => {
    setSelectedPreference(preference);
    setCreatePreferenceModalOpen(false);
    onClose();
  };

  const handleDeletePreferenceClick = (preference: Preference) => {
    setPreferenceToBeDeleted(preference.name);
  };

  const onDeletePreferenceModalClose = () => {
    setPreferenceToBeDeleted("");
  };

  const deletePreferenceHandler = () => {
    setDeletingPreference(true);
    Api.pptConfig()
      .deleteBcsConfigPreference(preferenceToBeDeleted)
      .then((resp) => {
        if (preferenceToBeDeleted === selectedPreference?.name) {
          setSelectedPreference(getDefaultPreference(preferences));
        }

        Store.dispatch(deleteBcsPreference(preferenceToBeDeleted));
        setPreferenceToBeDeleted("");
      })
      .finally(() => {
        setDeletingPreference(false);
      });
  };

  const handleUpdatePreferenceClick = () => {
    if (selectedPreference) {
      const preference = {
        name: selectedPreference.name,
        selection: selectedRows?.map(({ id }) => id),
      };

      setUpdatingPreference(true);
      Api.pptConfig()
        .updateBcsConfigPreference(preference.name, preference.selection)
        .then(() => {
          Store.dispatch(updateBcsPreference(preference));
          onClose();
        })
        .catch((err) => {
          System.openSnackbar(
            "Some error occured while updating preference. Please try again later.",
            SnackbarSeverity.ERROR
          );
          console.error("Error occured while updating preference", err);
        })
        .finally(() => {
          setUpdatingPreference(false);
        });
    }
  };

  const makeColumns = columns.map(({ title, field }) => {
    return {
      title: (
        <div style={{ display: "flex" }}>
          <div>{title}</div> (
          <FilterListIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              setActiveFilter(field);
            }}
          />
          )
        </div>
      ),
      field,
      filtering: true,
      FilterComponent: () => <div style={{ display: "none" }} />,
    };
  });

  return (
    <>
      <Dialog
        open={!!preferenceToBeDeleted}
        onClose={onDeletePreferenceModalClose}
        aria-labelledby="alert-delete-preference-dialog-title"
        aria-describedby="alert-delete-preference-dialog-description"
      >
        <DialogTitle id="alert-delete-preference-dialog-title">Delete Preference</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-delete-preference-dialog-description">
            Preference "{preferenceToBeDeleted}" will be deleted permanently. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDeletePreferenceModalClose}>No</Button>
          <Button onClick={deletePreferenceHandler} autoFocus>
            {deletingPreference && <CircularProgress placeholder="Creating..." size={18} />}
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {activeFilter && (
        <FilterComponent
          filters={columnFilters}
          data={arrayList}
          activeFilter={activeFilter}
          onClose={() => {
            setActiveFilter(null);
          }}
          onSubmit={onFilterSubmit}
        />
      )}
      <CreatePreferenceModal
        open={createPreferenceModalOpen}
        preferences={preferences}
        selectedRows={selectedRows}
        onClose={() => {
          setCreatePreferenceModalOpen(false);
        }}
        onPreferenceCreated={onPreferenceCreated}
      />
      <MaterialTable
        isLoading={loading}
        components={{
          Container: (props) => <div {...props} />,
          Toolbar: () => (
            <Grid
              style={{
                display: "flex",
                paddingBlock: 5,
                alignItems: "center",
              }}
            >
              <Select
                labelId="preference-select"
                id="preference-select"
                value={selectedPreference?.name ?? ""}
                onChange={handleChangePreference}
                style={{ minWidth: 100 }}
                renderValue={(value) => <Typography>{value as string}</Typography>}
              >
                {preferences.map((preference) => (
                  <MenuItem key={preference.name} value={preference.name}>
                    <ListItemText primary={preference.name} />
                    <ListItemSecondaryAction>
                      <IconButton
                        size="small"
                        disabled={preference.isDefault}
                        onClick={() => handleDeletePreferenceClick(preference)}
                      >
                        <DeleteIcon fontSize="inherit" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          ),
        }}
        data={tableData}
        columns={makeColumns}
        options={{
          filtering: false,
          selection: true,
          showSelectAllCheckbox: true,
          showTextRowsSelected: false,
          defaultExpanded: true,
          showTitle: false,
          paging: false,
          padding: "dense",
          maxBodyHeight: "350px",
          search: false,
        }}
        onSelectionChange={onSelectionChange}
      />
      <DialogActions style={{ gap: 2 }}>
        <Button variant="outlined" onClick={onClose} color="primary" className="cancel-button">
          Close
        </Button>
        <Box component="span" flexGrow={1} />
        <Button
          variant="outlined"
          onClick={handleUpdatePreferenceClick}
          color="primary"
          disabled={loading || selectedPreference?.isDefault || updatingPreference}
          endIcon={updatingPreference ? <CircularProgress placeholder="Updating..." size={18} /> : void 0}
        >
          Update Current Preference
        </Button>
        <Button variant="outlined" onClick={handleCreatePreferenceClick} color="primary" disabled={loading}>
          Create New Preference
        </Button>
      </DialogActions>
    </>
  );
};

export default FleetConfig;
