import React, { useEffect } from "react";
import AppStorage from "../common/AppStorage";
import HomePage from "../pages/HomePage";

export default () => {
  useEffect(() => {
    AppStorage.trackLoginEvent();
  }, []);
  return <HomePage />;
};
