import { SfdcAccount, Quarter, DefaultLoadRequest } from "@shared/interfaces";
import { Md5 } from "ts-md5/dist/md5";

export default class Cache {
  private static hierarchy: SfdcAccount[] = [];
  private static visualizations: { [key: string]: any[] } = {};
  private static accounts: { [key: string]: SfdcAccount[] } = {};
  private static quarters: Quarter[] | null = null;
  private static executiveAccounts: Array<any> = [];

  static getExecutiveAccounts(){
    return Cache.executiveAccounts
  }

  static setExecutiveAccounts(executiveAccounts: any[]){
    Cache.executiveAccounts = executiveAccounts
  }

  static getHierarchy() {
    return Cache.hierarchy;
  }

  static setHierarchy(newHierarchy: SfdcAccount[]) {
    Cache.hierarchy = newHierarchy;
  }

  static clearHierarchy() {
    Cache.hierarchy = [];
  }

  static setAccounts(accountIds: string[], data: SfdcAccount[]) {
    const key = Cache.getAccountsKey(accountIds);
    Cache.accounts[key] = data;
  }

  static getAccounts(accountIds: string[]): SfdcAccount[] | null {
    const key = Cache.getAccountsKey(accountIds);
    const accounts = Cache.accounts[key];
    return accounts || null;
  }

  static getAccountsKey(accountIds: string[]) {
    return Md5.hashStr(`${accountIds.sort()}`).toString();
  }

  static setVisualization(
    dashboardId: string,
    visualizationId: number,
    loadRequest: DefaultLoadRequest,
    visualizationData: any[]
  ) {
    const key = Cache.getVisualizationKey(
      dashboardId,
      visualizationId,
      loadRequest
    );
    Cache.visualizations[key] = visualizationData;
  }

  static getVisualization(
    dashboardId: string,
    visualizationId: number,
    loadRequest: DefaultLoadRequest
  ): any[] | null {
    const key = Cache.getVisualizationKey(
      dashboardId,
      visualizationId,
      loadRequest
    );
    const visualization = Cache.visualizations[key];
    return visualization || null;
  }

  static getVisualizationKey(
    dashboardId: string,
    visualizationId: number,
    loadRequest: DefaultLoadRequest
  ) {
    loadRequest.selectedAccountIds = loadRequest.selectedAccountIds.sort();
    return Md5.hashStr(
      `${dashboardId}-${visualizationId}-${JSON.stringify(loadRequest)}`
    ).toString();
  }

  static getQuarters() {
    return Cache.quarters;
  }

  static setQuarters(quarters: Quarter[]) {
    Cache.quarters = quarters;
  }

  static clearVisualizationsCache() {
    Cache.visualizations = {};
  }
}
