import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { StoreState } from '../types/StoreState'
import { useEffect } from 'react'
import System from '../common/System'

const RTCOInputComponent = ({ onClick }: {onClick: any}) => {

  const { rtcoParameters } = useSelector((state: StoreState) => state)
  const { depreciation , partnerMargin, partnerSupportMargin } = rtcoParameters

  useEffect(() => {
    System.updateRtcoParameters({
      depreciation: depreciation ? depreciation : 6,
      partnerMargin: partnerMargin ? partnerMargin : 20,
      partnerSupportMargin: partnerSupportMargin ? partnerSupportMargin : 20
    })
    onClick({
      dep: depreciation ? depreciation : 6,
      partner_margin_hw: partnerMargin ? partnerMargin : 20, 
      partner_margin_support_list: partnerSupportMargin ? partnerSupportMargin : 20
    })
  }, [])

  return (
    <Grid container>
      <Grid item className="rtco-input-component" xs={6}>
        <Grid container>
          <Grid item xs={3}>
            <TextField required id="hw-depreciation" onChange={(event) => System.updateRtcoParameters({...rtcoParameters, depreciation: Number(event.target.value)})} label="HW Depreciation Cycle (Years)" value={depreciation} />
          </Grid>
          <Grid item xs={3}>
            <TextField required id="partner-hw-margin" label="Partner HW Margin (%)" onChange={(event) => System.updateRtcoParameters({...rtcoParameters, partnerMargin: Number(event.target.value) })} value={partnerMargin} />
          </Grid>
          <Grid item xs={3}>
            <TextField required id="partner-support-margin" label="Partner Support Margin (%)" onChange={(event) => System.updateRtcoParameters({...rtcoParameters, partnerSupportMargin: Number(event.target.value)} )} value={partnerSupportMargin} />
          </Grid>
          <Grid item xs={3}>
            <Button onClick={() => onClick({ dep: depreciation, partner_margin_hw: partnerMargin, partner_margin_support_list: partnerSupportMargin})} variant="outlined" color="primary" href="#outlined-buttons">
              Generate Data
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
} 

export default RTCOInputComponent
