import "./Table.scss";

import { SubType, Column, Visualization, ColumnFilterType } from "@shared/interfaces";
import MaterialTable, {
  Column as MaterialColumn,
  QueryResult,
} from "material-table";
import React, { createContext, useEffect, useRef, useState } from "react";

import Api from "../../services/Api";
import CustomFormatter from "./CustomFormatter";
import DownloadButton from "../../common/DownloadButton";
import FleetDetailLabel from "./FleetDetailLabel";
import FormatValue from "./FormatValue";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import { useSelector } from "react-redux";
import VisibilitySensor from "react-visibility-sensor";
import CustomFilterComponent from "./CustomFilterComponent";
import { volumeData } from "../../services/volumeData";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import FilterListIcon from '@material-ui/icons/FilterList';
import PatchedPagination from "./PatchedPagination";
import { Store, updateVolumeSearchTerms } from "../../common/Store";
import DownloadMenu from "../../common/DownloadMenu";

const { Provider, Consumer }: {Provider: any, Consumer: any} = createContext([])

const Cell = ({
  rowData,
  column,
  visualization,
}: {
  rowData: any;
  column: Column;
  visualization: Visualization;
}) => (
  <div
    className={`custom-cell ${column.detailPanel ? "compact" : ""}`}
    style={
      column.detailPanel && visualization.representation.columns
        ? {
            maxWidth: `${
              55 /
              visualization.representation.columns.reduce(
                (acum: number, column) => acum + (column.detailPanel ? 1 : 0),
                0
              )
            }vw`,
          }
        : {}
    }
  >
    <CustomFormatter
      visualization={visualization}
      row={rowData}
      column={column.name}
    >
      <FormatValue
        columnFormat={column.format}
        decimalScale={column.decimalScale}
        value={rowData[column.name]}
        row={rowData}
      />
    </CustomFormatter>
  </div>
);

const getDefaultNumberOfRecords = (visualizationName: string, isExecutive: boolean) => {
  if(isExecutive){
    if(visualizationName === "FLEET DETAIL" || visualizationName === "Array Upgrade Detail" || visualizationName === "Open Pipeline"){
      return 100
    }
    return 5
  }else{
    return 5
  }
}

const DialogComponent = (props: any) => {
  const [filters, setFilters] = useState(props.columnFilters)
  const [activeFilter, setActiveFilter] = useState(props.activeFilter)

  return (
    <Dialog PaperProps={{ style: { width: "65%" }}} open={props.dialogOpen && activeFilter === props.columnDef.field} onClose={() => props.setDialogOpen(false)} aria-labelledby="column-filters">
        <DialogTitle id={`${props.columnDef.field}-column-filters`}>Column Filters</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The filters below change the table data by {props.columnDef.field}.
          </DialogContentText>
          <Provider value={{ filters, updateFilters: (value: any) => setFilters(value)}}>
            {props.children}
          </Provider>
        </DialogContent>
        <DialogContent>
          <DialogContentText>
            Hit enter key after entering the search term and then click apply.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => {
            if(filters){
              Object.entries(filters).forEach(([key, values]: any) => {
                props.onFilterChanged(key, values)
              })
              }
            }
          } color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
  )
}

export default ({
  visualization,
  dashboardId,
  dashboardType,
  rtcoData,
  rtcoLoading,
}: {
  visualization: Visualization;
  dashboardId: string;
  dashboardType: string;
  rtcoData: any;
  rtcoLoading: boolean;
}) => {
  const isExecutiveTable = dashboardType === SubType.EXECUTIVE
  const tableRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
    executiveAccountSelection,
  } = useSelector((state: StoreState) => state);
  const [mounted, setMounted] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(getDefaultNumberOfRecords(visualization.name, isExecutiveTable));
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState(false);
  const [groupByColumns, setGroupByColumns] = useState([]);
  const [groupByData, setGroupByData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [columns, setColumns] = useState<any>()
  const [columnFilters, setColumnFilters] = useState<any>()
  const [showFilter, setShowFilter] = useState<boolean>(true)
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const [activeFilter, setActiveFilter] = useState<string | undefined>()

  useEffect(() => {
    setColumns(makeColumns())
  }, [tableData, columnFilters, dialogOpen])

  useEffect(() => {
    setMounted(true);
    if (selectedArraysEnvironment && null !== tableRef.current) {
      tableRef.current.onQueryChange();
    }
    return () => {
      setMounted(false);
    };
  }, [selectedArraysEnvironment, accountSelection, executiveAccountSelection]);

  const makeColumns = () => {
    const columns: MaterialColumn<Column>[] =
      visualization.representation.columns?.map((column) => {
        return {
          title: <div style={{ display: 'flex'}}><div>{column.label}</div>{!!column.hasFilters && <FilterListIcon style={{ cursor: 'pointer' }} onClick={() => {setDialogOpen(true); setActiveFilter(column.name)}} />}</div>,
          field: column.name,
          filterComponent: (props) => <DialogComponent activeFilter={activeFilter} columnFilters={columnFilters} setColumnFilters={setColumnFilters} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} {...props}><Consumer>{(consumerProps: any) => <CustomFilterComponent columnFormat={column.format} minColumnName={column.minColumnName} maxColumnName={column.maxColumnName} {...props} {...consumerProps} defaultMin={column.defaultMin} defaultMax={column.defaultMax} defaultScale={column.defaultScale} tableData={tableData} filterType={column.filterType} />}</Consumer></DialogComponent>,
          filtering: !!column.hasFilters,
          render: (rowData: any) => (
            <Cell
              column={column}
              rowData={rowData}
              visualization={visualization}
            />
          ),
        };
      }) || [];
    return columns
  }

  const detailPanel = visualization.representation.columns?.find(
    (column) => column.detailPanel
  )
    ? [
        {
          render: (rowData: any) => {
            return visualization.representation.columns?.map((column) => {
              const show = column.detailPanel;
              return show ? (
                <div className={"detailPanel"}>
                  <h5>{column.label}:</h5>
                  <p>{rowData[column.name]}</p>
                </div>
              ) : (
                <></>
              );
            });
          },
        },
      ]
    : [];

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(isVisible);
  };

  const getRequestFilters = (filters?: any) => {
    const filtersRequest: Array<any> = []
    const columns = visualization?.representation?.columns
    let filterValues = filters !== undefined ? filters : columnFilters
    if(filterValues){
      Object.entries(filterValues).forEach((entry: any) => {
        if(columns){
          const [key, value] = entry
          const fieldName = columns[key].name
          const fieldAlias = columns[key].aliasPrefix
          const fieldColumnName = columns[key].filterColumnName

          const columnName = fieldColumnName || fieldName
          const prefix = fieldAlias ? fieldAlias + "." : ""
          const fullColumnName = prefix + columnName 

          const filterType = columns[key].filterType
          if(filterType=== ColumnFilterType.STRING_SEARCH){
            filtersRequest.push({
              columnName: fullColumnName,
              values: value,
            })
          }else if(filterType === ColumnFilterType.RANGE){
            filtersRequest.push({
              columnName: fullColumnName, 
              value: {
                start: columns[key].percentageInDecimal ? (value[0] /100) : value[0],
                end: columns[key].percentageInDecimal ? (value[1] /100) : value[1],
              }
            })
  
          }else if(filterType === ColumnFilterType.DATE){
            filtersRequest.push({
              columnName: fullColumnName, 
              value: {
                start: value[0],
                end: value[1],
              }
            })
          }
        }
      })
    }
    return filtersRequest;
  }

  useEffect(() => {
    if(rtcoData?.length){
      const outputData: any = Utils.getGroupedByColumns(rtcoData, visualization)
      if(outputData && outputData.data){
        setGroupByData(outputData.data)
        setTotalRecords(outputData.data.length)
        setGroupByColumns(outputData?.columns)
      }
    }
  }, [rtcoData])

  const loadData = (query: any): Promise<QueryResult<Column>> =>
    new Promise((resolve) => {
      console.log({ query })
      let columnFilters: any = {}

      if(query.filters.length){
        query.filters.forEach((filter: any) => {
          const id = filter.column.tableData.id
          const field = filter.column.field
          if(field === "name" && visualization.name === "Volume Search"){
            Store.dispatch(updateVolumeSearchTerms(filter.value))
          }
          columnFilters[id] = filter.value
        })
        setColumnFilters(columnFilters)
        setDialogOpen(false)
      }
      setLoading(true);
        if(executiveAccountSelection){
          const filters: Array<any> = getRequestFilters(columnFilters)
          Api.executiveDashboards().loadVisualization(dashboardId, visualization.id, {...executiveAccountSelection, filters, pageInfo: {
            pageNum: query.page + 1,
            pageSize: isExecutiveTable && visualization.name !== "Volume Search" ? 100 : query.pageSize,
          }}).then((response) => {
            if (mounted) {
              setTableData((response) || [])
              setTotalRecords((response && response[0]?.TotalRowCount) || 0);
              resolve({
                data: (response) || [],
                page: query.page,
                totalCount: (response && response[0]?.TotalRowCount) || 0,
              });
            }
          })
          .finally(() => {
            if (mounted) {
              setLoading(false);
            }
          });
        }else{
          Api.visualizations()
            .byId(
              dashboardId,
              visualization.id,
              {
                parentAccountId: accountSelection.parentAccountId,
                selectedAccountIds: accountSelection.accounts.map(
                  (account) => account.id
                ),
                hierarchyType: accountSelection.hierarchyType,
                arrayType: selectedArraysEnvironment,
                pageInfo: {
                  pageNum: query.page + 1,
                  pageSize: query.pageSize,
                },
              },
              {
                dashboardName: currentDashboardName,
                visualizationName: visualization.name,
              }
            )
            .then((response) => {
              if (mounted) {
                setTotalRecords((response && response[0]?.TotalRowCount) || 0);
               
                  resolve({
                    data: response || [],
                    page: query.page,
                    totalCount: (response && response[0]?.TotalRowCount) || 0,
                  });
              }
            })
            .finally(() => {
              if (mounted) {
                setLoading(false);
              }
            });
        }
    });

  return (
    <VisibilitySensor
      onChange={handleVisibilityChange}
      partialVisibility
      offset={{ top: -200, bottom: -200 }}
      active={!visualization.groupBy}
    >
      <div className="paged-table lazy">
        {visualization.showMenuForDownload && (
          <DownloadMenu
            enabled={!loading}
            visualization={visualization}
            recordsCount={totalRecords}
            dashboardId={dashboardId}
            requestFilters={getRequestFilters()}
          />
        )}
        {!visualization.showMenuForDownload && visualization.csvExport && (
          <DownloadButton
          enabled={!loading}
          visualization={visualization}
          recordsCount={totalRecords}
          dashboardId={dashboardId}
          requestFilters={getRequestFilters()}
          />
        )}
        <FleetDetailLabel visualization={visualization} />
        <div className={visualization.representation.formatting?.cssClass}>
          {(visible || isExecutiveTable || visualization.groupBy) && mounted && (
            <MaterialTable
              isLoading={!!visualization.groupBy && rtcoLoading}
              tableRef={tableRef}
              components={{
                Container: (props) => <div {...props}></div>,
                Pagination: PatchedPagination,
              }}
              columns={visualization.groupBy ? groupByColumns : columns}
              detailPanel={detailPanel}
              data={visualization.groupBy ? groupByData : loadData}
              onChangeRowsPerPage={setRowsPerPage}
              options={{
                filtering: visualization.hasColumnFilters && showFilter,
                headerStyle: Utils.showColumnTitles(visualization)
                  ? isExecutiveTable ? { position: 'sticky', top: 0 }
                  : {} : { display: "none" },
                showTitle: false,
                maxBodyHeight: isExecutiveTable ? '700px': '',
                search: false,
                showTextRowsSelected: false,
                toolbar: false,
                sorting: false,
                draggable: false,
                pageSize: rowsPerPage,
                paging: visualization.groupBy ? false : Utils.showPagination(visualization),
                pageSizeOptions: isExecutiveTable ? [20, 50, 100] : [5, 10, 20]
              }}
            />
          )}
        </div>
      </div>
    </VisibilitySensor>
  );
};
