import "./PptConfigPage.scss";

import React, { ChangeEvent, FC, useState } from "react";
import { Divider, Paper, Tab, Tabs } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TabPanel from "../components/ppt-configurator/TabPanel";
import BcsPptConfig from "../components/ppt-configurator/BcsPptConfig";
import PvrPptConfig from "../components/ppt-configurator/PvrPptConfig";
import { StoreState } from "../types/StoreState";
import { DefaultPreferences, exportPptRequest, PPTConfig } from "../components/ppt-configurator/utils";
import { SlidePayload } from "../services/Api";

const PptConfigurator: FC = () => {
  const {
    accountSelection,
    selectedArraysEnvironment,
    rtcoParameters,
    bcs: { preferences },
    user: { type: userType },
  } = useSelector((state: StoreState) => state);
  const [currentTab, setCurrentTab] = useState(0);
  const [downloadingPpt, setDownloadingPpt] = useState(false);
  const [selectedPreference, setSelectedPreference] = useState<string>(DefaultPreferences.ALL);
  const [pptConfig, setPptConfig] = useState<PPTConfig>({"bcs": null, "pvr": null})
  const history = useHistory();
  const tabPanels = [];

  const goToBcs = () => {
    setPptConfig({
      ...pptConfig,
      "pvr": null
    });
    setCurrentTab(0);
  };

  const goToPvr = (bcs: {[key: string]: boolean | SlidePayload}) => {
    setPptConfig({
      ...pptConfig,
      "bcs": bcs
    });
    setCurrentTab(1);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handlePreferenceChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSelectedPreference(event.target.value as string);
  };

  const handleBCSExport = (operation: string, bcs: {[key: string]: boolean | SlidePayload}) => {
    setPptConfig({
      ...pptConfig,
      "bcs": bcs
    });

    handleExport(operation, bcs , pptConfig.pvr);
  }

  const handlePVRExport = (operation: string, pvr: {[key: string]: boolean | SlidePayload}) => {
    setPptConfig({
      ...pptConfig,
      "pvr": pvr
    });

    handleExport(operation, pptConfig.bcs, pvr);
  }

  const handleExport = (operation: string, bcs: {[key: string]: boolean | SlidePayload} | null, pvr: {[key: string]: boolean | SlidePayload} | null) => {
    const includeAll = selectedPreference === DefaultPreferences.ALL;
    const customPreferenceName =
      selectedPreference !== DefaultPreferences.ALL && selectedPreference !== DefaultPreferences.DEFAULT
        ? selectedPreference
        : void 0;

    setDownloadingPpt(true);

    console.log(`Operation: ${operation}`);
    const config = {
      "bcs": bcs,
      "pvr": pvr
    }

    exportPptRequest({
      accountSelection,
      rtcoParameters,
      selectedArraysEnvironment,
      customPreferenceName,
      includeAll,
      config,
      operation,
      onComplete: () => {
        setDownloadingPpt(false);
      },
    });
  };

  if (userType === "ALL" || userType === "BCS") {
    tabPanels.push(
      <TabPanel key="bcs-tab" value={currentTab} index={tabPanels.length}>
        <BcsPptConfig
          downloadingPpt={downloadingPpt}
          selectedPreference={selectedPreference}
          preferences={preferences}
          pptConfig={pptConfig}
          setPptConfig = {setPptConfig}
          onPreferenceChange={handlePreferenceChange}
          onCancel={handleCancel}
          onNext={(bcs) => goToPvr(bcs)}
          onExport={(operation, bcs) => handleBCSExport(operation, bcs)}
        />
      </TabPanel>
    );
  }

  if (userType === "ALL" || userType === "PVR") {
    tabPanels.push(
      <TabPanel key="pvr-tab" value={currentTab} index={tabPanels.length}>
        <PvrPptConfig
          downloadingPpt={downloadingPpt}
          selectedPreference={selectedPreference}
          preferences={preferences}
          pptConfig={pptConfig}
          setPptConfig = {setPptConfig}
          onPreferenceChange={handlePreferenceChange}
          onCancel={handleCancel}
          onPrev={goToBcs}
          onExport={(operation, pvr) => handlePVRExport(operation, pvr)}
        />
      </TabPanel>
    );
  }

  // Todo: Add transition effect on tab change
  return (
    <>
      <Divider style={{ marginBottom: 0 }} />
      <Paper square>
        <Tabs
          value={currentTab}
          // onChange={(_, val) => {
          //   setCurrentTab(val);
          // }}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          {(userType === "ALL" || userType === "BCS") && (
            <Tab
              style={{
                cursor: "auto",
              }}
              disableRipple
              disableFocusRipple
              label="BCS PPT Configurator"
            />
          )}
          {(userType === "ALL" || userType === "PVR") && (
            <Tab
              style={{
                cursor: "auto",
              }}
              disableRipple
              disableFocusRipple
              label="PVR PPT Configurator"
            />
          )}
        </Tabs>
      </Paper>
      {tabPanels}
    </>
  );
};

export default PptConfigurator;
