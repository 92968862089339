import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { ReactElement } from "react";
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { DownloadTriggerSource, PromoData } from "@shared/interfaces";
import Api from "../services/Api";
import Link from "@material-ui/core/Link";
import { useSelector } from "react-redux";
import { StoreState } from "../types/StoreState";

/**
 * A carousel to display promotional slides.
 *
 * @param props
 */
export default () => {
  const [carouselData, setCarouselData] = useState<PromoData[]>();
  const useStyles = makeStyles(() => ({
    custom: {
      padding: "2px",
      margin: "0px auto",
      width: "500px",
      height: "143px",
      boxShadow: "1px 1px 8px black !important",
      flexDirection: "row",
      display: "flex",
      justifyContent: "center",
    },
  }));
  const classes = useStyles();
  const { accountSelection } = useSelector((state: StoreState) => state);

  // Download promo content here.
  useEffect(() => {
    async function fetchPromoData() {
      const promoData = await Api.promotionsApi().getPromoData(accountSelection.parentAccountId);
      setCarouselData(promoData);
    }
    fetchPromoData();
  }, [accountSelection]);

  const handleClick = async (folderName: string) => {
    const accountIds =
      accountSelection.accounts.length > 0
        ? accountSelection.accounts.map(({ id }) => id)
        : accountSelection.parentAccountId
        ? [accountSelection.parentAccountId]
        : [];
    document.body.style.cursor = "wait";
    Api.promotionsApi()
      .getPromoPptx(
        accountSelection.parentAccountId,
        accountIds,
        accountSelection.hierarchyType,
        folderName + "/",
        DownloadTriggerSource.CAROUSEL
      )
      .then((value) => {
        const link = document.createElement("a");
        link.setAttribute("download", "");
        link.setAttribute("hidden", "");
        link.setAttribute("target", "_blank");
        link.href = value.pptxDownloadUrl;
        document.body.appendChild(link);
        link.click();
        link.remove();
        document.body.style.cursor = "default";
      })
      .finally(() => (document.body.style.cursor = "default"));
  };
  let promoImages: ReactElement[] = [];
  if (carouselData && carouselData.length) {
    let uniqueKey = 1;
    promoImages = carouselData.map((cd) => (
      <Link href="#" onClick={() => handleClick(cd.folderName)}>
        {" "}
        <div key={uniqueKey++} className={"carouselDiv"}>
          <img src={cd.imageSignedUrl} className={"carouselImg"} />
        </div>
      </Link>
    ));
  }
  return (
    <Tooltip  title={<React.Fragment><Typography color="inherit">
    {"Please enable pop up for Skyline when prompted."} 
    </Typography></React.Fragment>} arrow={true} placement={"top"}>
    <div className="carouselContainer">
      <Carousel
        showThumbs={false}
        showArrows={true}
        autoPlay={true}
        infiniteLoop={true}
        showIndicators={false}
        className={classes.custom}
      >
        {promoImages}
      </Carousel>
    </div>
    </Tooltip>
  );
};
