import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import React, { ChangeEvent, FC, useMemo } from "react";
import { PptConfigDeck, PptConfigSlide } from "../../services/Api";
import Carousel from "../carousel";

interface SlideSelectorProps {
  decks: PptConfigDeck[];
  selectedSlides: string[];
  onSlideSelect: (selectedSlides: string[]) => void;
}

const SlideSelector: FC<SlideSelectorProps> = ({ decks, selectedSlides, onSlideSelect }) => {
  const totalSlidesCount = useMemo(
    () => decks.reduce((count, deck) => count + (deck.slides ? deck.slides.length : 1), 0),
    [decks]
  );

  const handleSelectAll = (_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (checked) {
      const allSlides = decks.reduce((acc, deck) => {
        if (deck.slides) {
          acc.push(...deck.slides.map(({ identifier }) => identifier));
        } else {
          acc.push(deck.identifier);
        }
        return acc;
      }, new Array<string>());

      onSlideSelect(allSlides);
    } else {
      onSlideSelect([]);
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <FormControlLabel
          control={
            <Checkbox
              checked={!!selectedSlides.length}
              onChange={handleSelectAll}
              name="selectAll"
              color="primary"
              indeterminate={!!selectedSlides.length && totalSlidesCount !== selectedSlides.length}
            />
          }
          label="Select All Slides"
        />
      </Box>
      {decks.map((deck) => (
        <Carousel
          key={deck.name}
          title={deck.name}
          items={(deck.slides ?? [deck as PptConfigSlide]).map(({ thumbnail, identifier, ...slide }) => ({
            ...slide,
            id: identifier,
            imageUrl: thumbnail,
          }))}
          selectedItems={selectedSlides}
          onSelect={onSlideSelect}
        />
      ))}
    </>
  );
};

export default SlideSelector;
