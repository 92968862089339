import React, { useState } from "react";
import { createStyles, makeStyles, Popover, Theme } from "@material-ui/core";
import "./SelectedAccountsPopover.scss";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    account: {
      padding: "5px",
    },
  })
);

export default ({
  accounts,
  showCount = false,
  maxAccountsToShow = 5,
  text,
}: {
  accounts: string[];
  showCount?: boolean;
  maxAccountsToShow?: number;
  text?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();
  const openPopover = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return accounts.length > 0 ? (
    <div
      className="selected-accounts"
      style={{ textAlign: !text && showCount ? "center" : "left" }}
    >
      {!showCount &&
        accounts.length <= maxAccountsToShow &&
        accounts.map((account, index) => (
          <span key={index} className="accountItem">
            {account}
            {index + 1 !== accounts.length && (
              <FiberManualRecordIcon className="bullet" />
            )}
          </span>
        ))}
      {(accounts.length > maxAccountsToShow || showCount) && (
        <>
          <span
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onClick={handlePopoverOpen}
          >
            {showCount
              ? text
                ? `${text} (${accounts.length})`
                : accounts.length
              : accounts
                  .slice(0, maxAccountsToShow + 1)
                  .map((account, index) => (
                    <span key={index} className="accountItem">
                      {account}
                      {index !== maxAccountsToShow ? (
                        <FiberManualRecordIcon className="bullet" />
                      ) : (
                        " ..."
                      )}
                    </span>
                  ))}
          </span>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            disableRestoreFocus
            onMouseLeave={handlePopoverClose}
          >
            {accounts.map((account) => (
              <div className={classes.account} key={account}>
                {account}
              </div>
            ))}
          </Popover>
        </>
      )}
    </div>
  ) : null;
};
