import {
  Bar,
  BarChart,
  LabelList,
  Legend,
  Rectangle,
  RectangleProps,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  ColumnChartComponent,
  DataLoadingStrategy,
  Visualization,
} from "@shared/interfaces";
import React, { useEffect, useState } from "react";

import Api from "../../services/Api";
import { CircularProgress } from "@material-ui/core";
import NoData from "../NoData";
import { StoreState } from "../../types/StoreState";
import Utils from "../../common/Utils";
import { useSelector } from "react-redux";
import Config from "../../config/Config";
import VisibilitySensor from "react-visibility-sensor";

const colors = [
  "#F49234",
  "#FFC685",
  "#E13B42",
  "#F77B66",
  "#A4A9AB",
  "#6E7A81",
];

interface ExtendedRectangleProps extends RectangleProps {
  payload?: any;
}
export default ({
  visualization,
  dashboardId,
  rtcoData,
  dashboardHasDataForAll,
}: {
  visualization: Visualization;
  dashboardId: string;
  rtcoData?: any[];
  dashboardHasDataForAll: boolean,
}) => {
  const height: number = visualization.representation.formatting?.height || 300;
  const hideLabelList: boolean = visualization.representation.formatting?.hideLabelList || false;
  const {
    accountSelection,
    selectedArraysEnvironment,
    currentDashboardName,
  } = useSelector((state: StoreState) => state);
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const XAxisColumn = visualization.representation.columns?.find(
    (column) => column.chartComponent === ColumnChartComponent.XAXIS
  );
  const [retries, setRetries] = useState(0);
  const [visible, setVisible] = useState(false);

  if (!XAxisColumn) {
    return (
      <div className="visualization-error">
        No column found with {ColumnChartComponent.XAXIS} 'chartComponent'
        property configured.
      </div>
    );
  }
  const bars = visualization.representation.columns?.filter(
    (column) => column.chartComponent === ColumnChartComponent.BAR
  );
  if (!bars || bars.length === 0) {
    return (
      <div className="visualization-error">
        At least one bar must be configured with the {ColumnChartComponent.BAR}{" "}
        'chartComponent' property.
      </div>
    );
  }

  useEffect(() => {
    if(rtcoData?.length){
      setData(rtcoData)
      setLoading(false)
    }
  }, [rtcoData])

  useEffect(() => {
    if(!dashboardHasDataForAll){
      let mounted = true;
      if (visualization.loadingStrategy === DataLoadingStrategy.LAZY && visible) {
        if (retries < Config.app.maxVisualizationRetries) {
          setLoading(true);
          Api.visualizations()
            .byId(
              dashboardId,
              visualization.id,
              {
                parentAccountId: accountSelection.parentAccountId,
                selectedAccountIds: accountSelection.accounts.map(
                  (account) => account.id
                ),
                hierarchyType: accountSelection.hierarchyType,
                arrayType: selectedArraysEnvironment,
              },
              {
                dashboardName: currentDashboardName,
                visualizationName: visualization.name,
              }
            )
            .catch((error) => {
              if (mounted && error.response.status !== 404) {
                setTimeout(() => {
                  setRetries(retries + 1);
                }, Config.app.maxVisualizationRetriesInterval);
              }
            })
            .then((response) => {
              if (mounted && response) {
                setData(Utils.formatData(visualization, response));
              }
            })
            .finally(() => {
              if (mounted) {
                setLoading(false);
              }
            });
        }
      } else {
        setData(Utils.formatData(visualization, visualization.data as any[]));
        setLoading(false);
      }
      return () => {
        mounted = false;
      };
    }
  }, [
    accountSelection,
    visualization,
    dashboardId,
    selectedArraysEnvironment,
    currentDashboardName,
    dashboardHasDataForAll,
    retries,
    visible,
  ]);

  const handleVisibilityChange = (isVisible: boolean) => {
    setVisible(true);
  };

  return (
    <VisibilitySensor onChange={handleVisibilityChange} partialVisibility>
      <div className="line-chart">
        {loading && <CircularProgress size={20} />}
        {!loading && (
          <>
            {data && data.length > 0 ? (
              <ResponsiveContainer width="100%" height={height}>
                <BarChart
                  data={data}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 110,
                  }}
                >
                  {/* <CartesianGrid horizontal={false} vertical={false} /> */}
                  {/* <CartesianGrid /> */}

                  <XAxis
                    dataKey={XAxisColumn.name}
                    angle={visualization.representation.formatting?.XAxisAngle}
                    textAnchor={
                      visualization.representation.formatting?.XAxisAngle
                        ? "end"
                        : "middle"
                    }
                    interval={0}
                    type={"category"}
                  />
                  <YAxis minTickGap={40} />
                  <Tooltip />
                  <Legend verticalAlign={"top"} iconType="circle" />
                  {bars.map((bar, i) => (
                    <Bar
                      key={bar.name}
                      name={bar.label}
                      dataKey={bar.name}
                      fill={colors[i % colors.length]}
                      stackId="a"
                      maxBarSize={15}
                      barSize={40}
                      shape={(props: ExtendedRectangleProps) => {
                        return <Rectangle {...props} />;
                      }}
                    >
                      {!hideLabelList && <LabelList
                        dataKey={bar.name}
                        position="inside"
                        content={(props) =>
                          props.value && props.value > 0 ? props.value : ""
                        }
                        angle={90}
                      />}
                    </Bar>
                  ))}
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <NoData />
            )}
          </>
        )}
      </div>
    </VisibilitySensor>
  );
};
