import "./CustomFormatter.scss";

import React, { ReactElement } from "react";

import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlatIcon from "@material-ui/icons/TrendingFlat";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Visualization } from "@shared/interfaces";

export default ({
  visualization,
  children,
  row,
  column,
}: {
  visualization: Visualization;
  children: ReactElement;
  row: any;
  column: string;
}) => {
  let className: string = "";
  let preElement: ReactElement = <></>;

  if (visualization.name === "FLEET DETAIL") {
    try {
      switch (column) {
        case "max_busyness":
        case "Utilization":
          if (row[column] >= 70) {
            className = "error";
          } else {
            className = "success";
          }
          break;
        case "UtilTrend":
        case "BusynessMaxTrend":
          if (row[column] < -10) {
            preElement = <TrendingDownIcon />;
          } else if (row[column] >= -10 && row[column] < 10) {
            preElement = <TrendingFlatIcon />;
          } else if (row[column] >= 10) {
            preElement = <TrendingUpIcon />;
          }
          break;
      }
    } catch (e) {
      console.error("Error applying formula", visualization.name, row, e);
    }
  }

  if(visualization.name === "Array Upgrade Detail"){
    try{
      switch(column){
        case "supportmonthsleft":
          if(row[column] <= 6){
            className="error font-weight-bold"
          }else if(row[column] <= 12){
            className="info font-weight-bold"
          }
          break;
      }
    }catch (e) {
      console.error("Error applying formula", visualization.name, row, e);
    }
  }

  if(visualization.name === "SafeMode"){
    try {
      switch(column) {
        case "FA_C_Array_count":
          if(row[column] === 0){
            className = "info font-weight-bold";
          }
          break;
        case "FA_SafeMode_enabled":
          if(row[column] === 0){
            className = "error font-weight-bold";
          }
          break;
        case "FA_SafemodePercent":
          if(row[column] === 0){
            className = "error font-weight-bold";
          }
          break;
        case "FB_Array_count":
          if(row[column] === 0){
            className="info font-weight-bold";
          }
          break;
        case "FB_SafeMode_enabled":
          if(row[column] === 0){
            className = "warning font-weight-bold";
          }
          break;
        case "FB_SafemodePercent":
          if(row[column] === 0){
            className = "warning font-weight-bold";
          }
          break;
      }
    }catch (e) {
      console.error("Error applying formula", visualization.name, row, e);
    }
  }
  
  return (
    <div className="custom-formatter">
      {preElement}
      <span className={className}>{children}</span>
    </div>
  );
};
