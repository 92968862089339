import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";

export default function ConfirmDialog({
  confirmDialogOpen,
  handleOk,
  handleClose,
  dialogTitle,
  dialogContentText,
  cancelText = "Cancel",
  okText = "OK",
  loading,
  showCancel = true,
}: {
  confirmDialogOpen: boolean;
  handleOk: () => void;
  handleClose: () => void;
  dialogTitle: string;
  dialogContentText: string;
  cancelText?: string;
  okText?: string;
  loading?: boolean;
  showCancel?: boolean;
}) {
  const ok = async () => {
    await handleOk();
    handleClose();
  };

  return (
    <Dialog open={confirmDialogOpen} onClose={handleClose}>
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText
          dangerouslySetInnerHTML={{ __html: dialogContentText }}
        ></DialogContentText>
      </DialogContent>
      <DialogActions>
        {showCancel && (
          <Button autoFocus onClick={handleClose} color="primary">
            {cancelText}
          </Button>
        )}
        <Button onClick={ok} color="primary" disabled={loading}>
          {loading && (
            <div style={{ display: "flex", marginRight: "5px" }}>
              <CircularProgress size="16px" />
            </div>
          )}
          {okText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
