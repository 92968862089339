import { Dashboard, SubType } from "@shared/interfaces";
import { Button, Grid, ListItemText } from "@material-ui/core";
import { Link, useHistory, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Api from "../services/Api";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import { Paths } from "../common/Paths";
import { StoreState } from "../types/StoreState";
import System from "../common/System";
import Utils from "../common/Utils";
import sort from "fast-sort";
import { useSelector } from "react-redux";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { fetchBcsPreferences, Store } from "../common/Store";
import ReadOnlyTooltip from "./ReadOnlyTooltip";
import ExportPptMenu from "./ppt-configurator/ExportPptMenu";

export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

export const StyledMenuItem = withStyles((theme) => ({}))(MenuItem);

export default () => {
  const {
    accountSelection,
    accountSelectionContext,
    loadingDashboard,
    executiveAccountSelection,
    headerDashboard,
  } = useSelector((state: StoreState) => state);
  const history = useHistory();
  const [dashboards, setDashboards] = useState<Dashboard[]>([]);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const isReadOnly = headerDashboard?.applicationInfo?.isReadOnly || false;

  useEffect(() => {
    const accountIds =
      accountSelection.accounts.length > 0
        ? accountSelection.accounts.map(({ id }) => id)
        : accountSelection.parentAccountId
        ? [accountSelection.parentAccountId]
        : [];

    if (accountIds.length) {
      Store.dispatch(fetchBcsPreferences(accountIds));
    }
  }, [accountSelection.accounts, accountSelection.parentAccountId]);

  useEffect(() => {
    setLoading(true);
    if (executiveAccountSelection && accountSelectionContext === SubType.EXECUTIVE) {
      Api.executiveDashboards()
        .getDashboards()
        .then((dashboardsResponse) => {
          const sortedDashboards = sort<Dashboard>(dashboardsResponse)
            .asc([(d: Dashboard) => d.order])
            .filter((dashboard) => dashboard.name.toLocaleLowerCase() !== "header")
            .map((dashboard) => {
              if (dashboard.name.includes("Install Base") && dashboard.subType === SubType.EXECUTIVE) {
                return {
                  ...dashboard,
                  name: "Install Base Upgrades",
                };
              }
              return dashboard;
            });
          setDashboards(sortedDashboards);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Api.dashboards()
        .all()
        .then((dashboardsResponse) => {
          const sortedDashboards = sort<Dashboard>(dashboardsResponse)
            .asc([(d: Dashboard) => d.order])
            .filter((dashboard) => dashboard.name.toLocaleLowerCase() !== "header");
          setDashboards(sortedDashboards);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelection, executiveAccountSelection]);

  const variant = (path: string) => (path === location.pathname ? "contained" : "text");

  const handleSubmitFeedbackClick = () => {
    System.openFeedbackForm();
  };

  const handleValueMenuClick = (event: any) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleValueMenuClose = () => {
    setMenuAnchorEl(null);
  };

  if (accountSelection.parentAccountId || accountSelection.accounts.length > 0 || executiveAccountSelection) {
    return (
      <>
        {!loading && (
          <div className="dashboards-menu">
            <Grid container alignItems="flex-end">
              <Grid item xs={9}>
                {dashboards.map((dashboard) => {
                  const path = Paths.dashboard(Utils.formatDashboardName(dashboard.name));
                  return !dashboard.parentMenu && !dashboard.menuItems ? (
                    <Button
                      disableRipple
                      disableFocusRipple
                      key={dashboard.name}
                      onClick={() => {
                        history.push(path);
                      }}
                      className="nav-button"
                      color="primary"
                      disabled={loading || loadingDashboard}
                      variant={variant(path)}
                      disableElevation
                    >
                      {dashboard.name}
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        aria-controls="value-menu-item"
                        aria-haspopup="true"
                        onClick={handleValueMenuClick}
                        className="value-menu-button"
                        disableElevation
                        disableRipple
                        size="large"
                      >
                        {dashboard.parentMenu}
                      </Button>
                      <StyledMenu
                        id="value-menu-item"
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={handleValueMenuClose}
                      >
                        {dashboard &&
                          dashboard.menuItems &&
                          dashboard.menuItems.map((item: string) => (
                            <StyledMenuItem
                              key={`menu-item-${item}`}
                              onClick={() => {
                                history.push(path);
                                setMenuAnchorEl(null);
                              }}
                            >
                              <ListItemText primary={item} />
                            </StyledMenuItem>
                          ))}
                      </StyledMenu>
                    </>
                  );
                })}
                {!executiveAccountSelection && (
                  <Button
                    component={Link}
                    to={Paths.feedback}
                    color="primary"
                    disabled={loading || loadingDashboard}
                    variant={variant(Paths.feedback)}
                    disableElevation
                  >
                    PVR History
                  </Button>
                )}
              </Grid>
              {!executiveAccountSelection && (
                <Grid xs={3} item className="right-buttons">
                  <ExportPptMenu />
                  <ReadOnlyTooltip activate={isReadOnly}>
                    <Button
                      color="primary"
                      disabled={loading || loadingDashboard}
                      onClick={isReadOnly ? void 0 : handleSubmitFeedbackClick}
                      variant="outlined"
                      className="feedback-button"
                      disableElevation
                      disableRipple
                      size="large"
                    >
                      Add PVR History
                      <ChatBubbleOutlineIcon className="button-icon" />
                    </Button>
                  </ReadOnlyTooltip>
                </Grid>
              )}
            </Grid>
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
};
