import "./HostnameSelect.scss";

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Popover,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SearchIcon from "@material-ui/icons/Delete";

export default ({
  hostnames,
  selectionChanged,
}: {
  hostnames: string[];
  selectionChanged: Function;
}) => {
  const [selectedHostnames, setSelectedHostnames] = useState<string[]>([]);
  const [filteredHostnames, setfilteredHostnames] = useState<string[]>([]);
  const [searchHostname, setSearchHostname] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setSelectedHostnames(filteredHostnames);
    setAnchorEl(event.currentTarget);
  };
  const openPopover = Boolean(anchorEl);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (event: any) => {
    if (event.target.checked) {
      setSelectedHostnames([...selectedHostnames, event.target.value]);
    } else {
      setSelectedHostnames(
        selectedHostnames.filter((e: string) => e !== event.target.value)
      );
    }
  };

  const handleClearClick = () => {
    setfilteredHostnames([]);
    setSelectedHostnames([]);
    selectionChanged([]);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className={"hostname-select"}>
      {filteredHostnames.length > 0 && (
        <IconButton
          className={"clear-button"}
          onClick={handleClearClick}
          size="small"
        >
          Clear All
        </IconButton>
      )}
      {hostnames.length > 0 && (
        <span
          aria-owns={openPopover ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onClick={handlePopoverOpen}
          className="popover-button"
        >
          Filter: Array Host{" "}
          {filteredHostnames.length > 0 && (
            <Chip
              color="primary"
              size="small"
              label={filteredHostnames.length}
            />
          )}
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </span>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box className="hostname-select-popover">
          <FormControl>
            <TextField
              size="small"
              variant="outlined"
              name="searchHostname"
              placeholder={"Search"}
              onChange={(e: any) => setSearchHostname(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <List>
              {searchHostname
                ? hostnames
                    .filter((e) => e.includes(searchHostname))
                    .map((hostname) => (
                      <ListItem key={hostname}>
                        <FormControlLabel
                          color="primary"
                          control={
                            <Checkbox
                              color="primary"
                              checked={selectedHostnames.includes(hostname)}
                              onChange={handleChange}
                              name={hostname}
                              value={hostname}
                            />
                          }
                          label={hostname}
                        />
                      </ListItem>
                    ))
                : hostnames.map((hostname) => (
                    <ListItem key={hostname}>
                      <FormControlLabel
                        color="primary"
                        control={
                          <Checkbox
                            color="primary"
                            checked={selectedHostnames.includes(hostname)}
                            onChange={handleChange}
                            name={hostname}
                            value={hostname}
                          />
                        }
                        label={hostname}
                      />
                    </ListItem>
                  ))}
            </List>
          </FormControl>
          <Grid container>
            <Grid xs={12} item>
              <ButtonGroup
                fullWidth={true}
                disableElevation
                variant="contained"
              >
                {selectedHostnames.length > 0 && (
                  <Button
                    className="reset-selection"
                    onClick={() => setSelectedHostnames([])}
                    variant={"text"}
                  >
                    Reset
                  </Button>
                )}
                <Button
                  fullWidth={true}
                  className="filter-button"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setfilteredHostnames(selectedHostnames);
                    selectionChanged(selectedHostnames);
                    handlePopoverClose();
                  }}
                >
                  Apply
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Box>
        {/* <Select
          MenuProps={{ variant: "menu" }}
          multiple
          value={selectedHostnames}
          onChange={handleChange}
          variant="outlined"
          onClose={handleClose}
          placeholder="Hostname"
          input={<Input placeholder={"hostname"} className={"select-input"} />}
        >
          {hostnames.map((hostname) => (
            <MenuItem key={hostname} value={hostname}>
              {hostname}
            </MenuItem>
          ))}
        </Select> */}
      </Popover>
    </div>
  );
};
