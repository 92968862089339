import { AppBar, Box, Toolbar } from "@material-ui/core";
import React, { FC } from "react";

const ActionBar: FC = ({ children }) => {
  return (
    <>
      <Box pb={8} visibility="hidden" />
      <AppBar
        position="fixed"
        color="default"
        component="footer"
        style={{
          top: "auto",
          bottom: 0,
        }}
      >
        <Toolbar
          style={{
            gap: "1rem",
          }}
        >
          {children}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ActionBar;
