const replaceId = (path: string, id?: string) => {
  return id ? path.replace(":id", id) : path;
};

export const Paths = {
  loginCallback: process.env.PUBLIC_URL + "/implicit/callback",
  logout: process.env.PUBLIC_URL + "/logout",
  app: process.env.PUBLIC_URL + "/app",
  feedback: process.env.PUBLIC_URL + "/app/feedback",
  root: process.env.PUBLIC_URL + "/",
  pptConfigurator: process.env.PUBLIC_URL + "/app/ppt-configurator",
  account: (accountId?: string) => {
    return replaceId(process.env.PUBLIC_URL + "/account/:id", accountId);
  },
  sfdc: (accountId?: string) => {
    return replaceId(process.env.PUBLIC_URL + "/sfdc/:id", accountId);
  },
  organization: (organizationId?: string) => {
    return replaceId(process.env.PUBLIC_URL + "/pure1/:id", organizationId);
  },
  dashboard: (name?: string) => {
    const path = process.env.PUBLIC_URL + "/app/dashboard/:name";
    return name ? path.replace(":name", name) : path;
  },
};
