import React from 'react';
import {
  ColumnChartComponent,
  ColumnFormat,
  SfdcAccount,
  Visualization,
  PureAccount,
  Column,
} from "@shared/interfaces";
import {
  CHARTS_INPUT_DATE_FORMAT,
  CHARTS_OUTPUT_DATE_FORMAT,
} from "./Constants";
import moment from "moment";
import { AccountSelection } from "../types/AccountSelection";
import { Organization } from "@shared/interfaces";
import { PureAccountRow } from "../interfaces/PureAccountRow";
import { SfdcAccountRow } from "../interfaces/SfdcAccountRow";
import { AccountHierarchyType } from "@shared/interfaces";

export default abstract class Utils {
  static formatDashboardName(name: string): string {
    return name.toLowerCase().replace(/\s/g, "-");
  }

  static showName(visualization: Visualization) {
    return visualization.representation?.formatting?.showName !== undefined
      ? visualization.representation.formatting.showName
      : true;
  }

  static getGroupedByColumns(data: any[], visualization: Visualization){
    const { groupBy, representation } = visualization
    const { columns } = representation

    const getFormattedValue = (value: any, format?: string, decimalScale?: number) => {
      switch(format) {
        case ColumnFormat.DATE:
          return value
        case ColumnFormat.NUMBER:
          return value.toFixed(decimalScale)
        case ColumnFormat.CURRENCY:
          const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: decimalScale,
            minimumFractionDigits: 0,
          })
          return currencyFormatter.format(value)
        }
      return value
    }

    if(groupBy){

      const dataByGroupProp: { [key in string]: any} = {}
      data.forEach((item) => {
        dataByGroupProp[item[groupBy]] = item
      })

      const groupedByColumns = data.map(item => item[groupBy])
      const groupedByColumnsWithType = ["type", ...groupedByColumns]
      const columnFields = columns?.map((column: Column) => ({ name: column.name, label: column.label, format: column?.format, decimalScale: column?.decimalScale}))
      const columnData = columnFields?.filter(field => field.name !== groupBy).map((field) => {
        const obj: any = {}
        groupedByColumnsWithType.forEach((group: any) => {
          if(group === "type"){
            obj.type = field.label
          }else{
            obj[group] = getFormattedValue(dataByGroupProp[group][field.name], field.format, field.decimalScale)
          }
        })
        return obj
      })

      const newColumns: any = groupedByColumnsWithType.map((column) => ({ title: column, field: column }))
      return {
        columns: newColumns,
        data: columnData,
      }
  }
}

  static formatData(visualization: Visualization, data: any[]) {
    return (
      data &&
      data.map((row: any) => {
        visualization.representation.columns?.forEach((column) => {
          if (
            column.chartComponent === ColumnChartComponent.LINE ||
            column.chartComponent === ColumnChartComponent.BAR
          ) {
            row[column.name] = Math.trunc(parseFloat(row[column.name])) || 0;
          } else if (column.format === ColumnFormat.DATE) {
            row[column.name] = moment(
              row[column.name],
              CHARTS_INPUT_DATE_FORMAT
            ).format(CHARTS_OUTPUT_DATE_FORMAT);
          }
        });
        return row;
      })
    );
  }

  static showColumnTitles(visualization: Visualization) {
    return visualization.representation.formatting?.showColumnTitles !==
      undefined
      ? visualization.representation.formatting.showColumnTitles
      : true;
  }

  static showPagination(visualization: Visualization) {
    return visualization.representation.formatting?.showPagination !== undefined
      ? visualization.representation.formatting.showPagination
      : false;
  }

  static flattenHierarchy(
    accountsHierarchy: SfdcAccount[],
    accountSelection: AccountSelection = {
      accounts: [],
      hierarchyType: AccountHierarchyType.SFDC,
    }
  ) {
    const flat: SfdcAccountRow[] = [];
    const accounts: SfdcAccount[] = accountSelection.accounts as SfdcAccount[];
    const flatten = (account: SfdcAccount) => {
      const children = account.children;
      if (children && children.length > 0) {
        children.forEach(flatten);
      }
      const acc: SfdcAccountRow = {
        ...account,
        tableData: { checked: false },
        childrenCount: account.children?.length || 0,
      };
      delete acc.children;
      if (
        accounts.length === 0 &&
        acc.id === accountSelection.parentAccountId
      ) {
        acc.tableData = { checked: true };
      } else if (
        accounts.find((a) => {
          return a.id === acc.id;
        })
      ) {
        acc.tableData = { checked: true };
      }
      flat.push(acc);
    };
    accountsHierarchy.forEach(flatten);
    return flat;
  }

  /**
   * Sums all the array count fetching the orgs from all the sfdc accounts in the hierarchy.
   * It also marks as checked on the selector the pure orgs
   *
   * pureOrgsAlareadySelected is used in the scenario where the pure orgs were previously saved in storage and
   * a page refresh occurs.
   *
   * If it is not present, it means pure hirearchy menu was clicked with some sfdc accounts selected
   * and we need to mark all of the orgs of those sfdc accounts as checked
   *
   * @param sfdcAccountsHierarchy
   * @param sfdcSelectedAccounts
   * @param pureOrgsAlareadySelected
   */

  static pureSumOrgsAndSelect(
    sfdcAccountsHierarchy: SfdcAccount[],
    sfdcSelectedAccounts: SfdcAccount[],
    pureOrgsAlareadySelected?: PureAccount[]
  ) {
    const pureAccounts: PureAccountRow[] = [];
    sfdcSelectedAccounts.forEach((account: SfdcAccount) => {
      account.organization.forEach((org) => {
        const pureAccount: PureAccountRow = {
          id: org.id,
          name: org.name,
          arrayCount: org.arrayCount,
          accounts: [account.id],
          tableData: { checked: true },
        };
        if (
          pureOrgsAlareadySelected &&
          !pureOrgsAlareadySelected.find((pure) => pure.id === pureAccount.id)
        ) {
          pureAccount.tableData = { checked: false };
        }

        // If pure accounts (orgs) are repeated, sum the total array count
        const existsAccount = pureAccounts.find(
          (account) => account.id === pureAccount.id
        );
        if (existsAccount) {
          existsAccount.arrayCount += pureAccount.arrayCount;
        } else {
          pureAccounts.push(pureAccount);
        }
      });
    });
    // search orgs that are repeated in other sfdc accounts to get the right array count
    // this is because the same org is related to multiple sfdc accounts
    const filtered = sfdcAccountsHierarchy.filter((sfdcAccount) => {
      const existing = sfdcSelectedAccounts.find(
        (selectedAccount) => selectedAccount.id === sfdcAccount.id
      );
      return !existing;
    });
    // Get all the organizations without the already selected one
    let organizations: Organization[] = [];
    filtered.forEach((sfdcAccount) => {
      organizations = organizations.concat(sfdcAccount.organization);
    });
    // Sum the array count of the organizations that are in other sfdcs accounts and match the selection
    pureAccounts.forEach((pureAccount) => {
      organizations
        .filter((org) => pureAccount.id === org.id)
        .forEach((org) => {
          pureAccount.arrayCount += org.arrayCount;
        });
    });
    return pureAccounts;
  }

  static hierarchyName(hierarchy: AccountHierarchyType): string {
    switch (hierarchy) {
      case AccountHierarchyType.PURE:
        return "Pure1 Org";
      case AccountHierarchyType.SFDC:
        return "Salesforce";
      default:
        return "";
    }
  }

  static isSFDC(hierarchy: AccountHierarchyType) {
    return hierarchy === AccountHierarchyType.SFDC;
  }

  static isPure(hierarchy: AccountHierarchyType) {
    return hierarchy === AccountHierarchyType.PURE;
  }
}
